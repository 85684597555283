import { computed, override }         from 'mobx';
import moment                         from 'moment';
import { getIdFromUrn }               from 'tools/UrnTools';
import SalesPrivateApiModel           from '../../abstracts/SalesPrivateApiModel';

export default class QuotationModel extends SalesPrivateApiModel {
	public filters: ModelFiltersExtended<{
		archived: boolean;
		'items.itemActivities.locationUrn': string;
		'owner.clientUrn': string;
		'owner.partitionUrn': string;
		'quotationStatus.reference': string;
	}> = {};

	static get path(): string {
		return '/quotations/{?id}';
	}

	@computed
	public get archived(): boolean {
		return this.get('archived');
	}

	@computed
	public get additionalDesignation(): string {
		return this.get('additionalDesignation');
	}

	@computed
	public get billingGroupId(): number {
		return parseInt(getIdFromUrn(this.get('billingGroupUrn')));
	}

	@computed
	public get clientReference(): string {
		return this.get('clientReference');
	}

	@computed
	public get clientValidationId(): number {
		return this.get('clientValidation.id');
	}

	@computed
	public get closedAt(): string {
		return this.get('closedAt');
	}

	@computed
	public get comment(): string {
		return this.get('comment');
	}

	@override
	public get createdAt(): string {
		return this.get('createdAt');
	}

	@computed
	public get currencyUrn(): string {
		return this.get('currencyUrn');
	}

	@computed
	public get dc4Mode(): boolean {
		return this.get('dc4Mode');
	}

	@computed
	public get denomination(): string {
		return this.get('denomination');
	}

	@computed
	public get denominationBis(): string {
		return this.get('denominationBis');
	}

	@computed
	public get discount(): number {
		return this.get('discount');
	}

	@computed
	public get duration(): number {
		return this.get('duration');
	}

	@computed
	public get endDate(): string {
		return this.get('endDate');
	}

	@computed
	public get endDateIsPassed(): boolean {
		return moment(this.endDate).isSameOrBefore(moment());
	}

	@computed
	public get expiresAt(): string {
		return this.get('expiresAt');
	}

	@computed
	public get fullNumber(): string {
		let fullNumber = this.number;

		if (this.oldNumber) {
			fullNumber = fullNumber + ' (' + this.oldNumber + ')';
		}

		return fullNumber;
	}

	@computed
	public get onPurchaseOrder(): boolean {
		return this.get('onPurchaseOrder', false);
	}

	@computed
	public get note(): string {
		return this.get('note');
	}

	@computed
	public get number(): string {
		return this.get('number');
	}

	@computed
	public get oldNumber(): string {
		return this.get('oldNumber');
	}

	@computed
	public get ownerId(): number {
		return this.get('owner.id');
	}

	@computed
	public get ownerIri(): string {
		return this.get('owner.@id');
	}

	@computed
	public get activityCategoryId(): number {
		return this.get('activityCategory.id');
	}

	@computed
	public get activityCategoryIri(): string {
		return this.get('activityCategory.@id');
	}

	@computed
	public get quotationNeedsAdjustment(): boolean {
		return this.get('quotationNeedsAdjustment');
	}

	@computed
	public get quotationStatusId(): number {
		return this.get('quotationStatus.id');
	}

	@computed
	public get quotationStatusIri(): string {
		return this.get('quotationStatus.@id');
	}

	@computed
	public get startDate(): string {
		return this.get('startDate');
	}

	@computed
	public get totalOverchargedWhenClosed(): number {
		return this.get('totalOverchargedWhenClosed');
	}

	@computed
	public get totalPriceExclTax(): number {
		return this.get('totalPriceExclTax', 0);
	}

	@computed
	public get totalPriceInclTax(): number {
		return this.get('totalPriceInclTax', 0);
	}

	@computed
	public get address(): string {
		return this.get('address');
	}

	@computed
	public get addressBis(): string {
		return this.get('addressBis');
	}

	@computed
	public get city(): string {
		return this.get('city');
	}

	@computed
	public get country(): string {
		return this.get('country');
	}

	@computed
	public get zipCode(): string {
		return this.get('zipCode');
	}

	@computed
	public get title(): string {
		return this.get('title');
	}
}
