import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export type ContactTypeReference = 'pre_intervention_validator' | 'pre_intervention_reader' | 'responsible' | 'buying';

export default class ContactTypeModel extends InterventionPrivateApiModel {
	static get path(): string {
		return '/contact_types/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	@computed
	public get description(): string {
		return this.get('description', '');
	}

	@computed
	public get reference(): ContactTypeReference {
		return this.get('reference', '');
	}
}
