import ConfigProxy            from '../../../tools/ConfigProxy';
import { LoggedApiConnector } from '../connectors/LoggedApiConnector';
import AbstractApiModel       from './AbstractApiModel';

export const interventionLoggedApiConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('intervention', 'api_endpoint'),
});

export default class InterventionPrivateApiModel extends AbstractApiModel {
	public static get connector() {
		return interventionLoggedApiConnector;
	}
}
