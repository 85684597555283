import Spin            from 'antd/lib/spin';
import { Page }        from 'components/Page';
import { observer }    from 'mobx-react';
import { useLocation } from 'react-router-dom';
import React           from 'react';
import { appStore }    from '../../stores';

const TaskZoneContent = ({ children }) => {
	const location = useLocation();

	if (appStore.isLoading) {
		return (
			<Spin
				size="large"
				style={{
					alignItems: 'center',
					display: 'flex',
					height: '100%',
					justifyContent: 'center',
				}}
			/>
		);
	}

	if (location.pathname === '/my-account') {
		return children;
	}

	if (!appStore.selectedTaskZone) {
		return (
			<Page title="Choisissez un site">
				<div
					style={{
						color: '#606060',
						fontSize: '45px',
						fontWeight: 'bold',
						height: '100%',
					}}
				>
					{`Veuillez choisir un site dans la liste déroulante en haut de l'écran.`}
				</div>
			</Page>
		);
	}

	return (
		<div
			key={appStore.selectedTaskZone.urn}
			style={{ height: '100%' }}
		>
			{children}
		</div>
	);
};

export default observer(TaskZoneContent);
