import Card                       from 'antd/lib/card';
import Descriptions               from 'antd/lib/descriptions';
import Empty                      from 'antd/lib/empty';
import List                       from 'antd/lib/list';
import parse                      from 'html-react-parser';
import { observer }               from 'mobx-react';
import React                      from 'react';
import { contractDashboardStore } from 'stores/index';

const ContractIterationItemBox = () => {
	const {
		activityCollection,
		contractIterationItemActivityCollection,
		contractIterationItemCollection,
	} = contractDashboardStore;

	const isLoading = (
		activityCollection.isLoading
		|| contractIterationItemCollection.isLoading
		|| contractIterationItemActivityCollection.isLoading
	);

	return (
		<Card
			className="benefits-box"
			loading={isLoading}
			title="Prestations"
		>
			{!contractIterationItemCollection.length
				? <Empty description="Aucune prestation" /> :
				contractIterationItemCollection.map((contractIterationItem) => (
					<div key={contractIterationItem.id}>
						<b className="sub-title" style={{ fontSize: 14 }}>{contractIterationItem.title}</b>
						<div dangerouslySetInnerHTML={{ __html: contractIterationItem.description }} />
						<div>
							{contractIterationItem.publicComment}
						</div>

						<List>
							{contractIterationItemActivityCollection.map(contractIterationItemActivity => {
								if (contractIterationItemActivity.contractIterationItemId !== contractIterationItem.id) {
									return null;
								}

								const activity = activityCollection.find(activity => activity.id === contractIterationItemActivity.activityId);

								if (!activity) {
									return null;
								}

								return (
									<List.Item key={contractIterationItemActivity.id}>
										<div>
											<b>
												{activity.label?.toUpperCase()}
											</b>
											<div>
												{activity.description && parse(activity.description)}
											</div>
											<div>
												<Descriptions column={2} size="small">
													<Descriptions.Item label="Fréquence">
														{contractIterationItem.quantity} fois
													</Descriptions.Item>

													{/*<Descriptions.Item label="Qté matériel">*/}
													{/*	{contractIterationItem.quantity}*/}
													{/*</Descriptions.Item>*/}
												</Descriptions>
											</div>
										</div>
									</List.Item>
								);
							})}
						</List>
					</div>
				))}
		</Card>
	);
};

export default observer(ContractIterationItemBox);
