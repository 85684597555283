import { Page }                   from 'components/Page';
import React                      from 'react';
import { contractDashboardStore } from 'stores/index';
import ContractDashboard          from './ContractDashboard';

export default function ContractDashboardPage() {
	return (
		<Page title="Tableau de bord du contract">
			<ContractDashboard
				backPath="/contracts"
				onReady={id => contractDashboardStore.fetch(id)}
				title="Tableau de bord du contrat"
			/>
		</Page>
	);
}
