import { FormInstance } from 'antd/lib/form/Form';

export default (e: any, form: FormInstance) => {
	const violations = e?.connectorError?.response?.data?.violations || [];

	form.setFields(violations.map(v => ({
		errors: [v.message],
		name: v.propertyPath,
	})));
};
