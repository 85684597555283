import {
	DownloadOutlined,
	EllipsisOutlined,
	EyeOutlined,
}                        from '@ant-design/icons';
import { ApiCollection } from 'Collections/ApiCollection';
import FileModel         from 'Models/file/FileModel';
import Button            from 'antd/lib/button';
import Dropdown          from 'antd/lib/dropdown';
import useTo             from 'hooks/useTo';
import { observer }      from 'mobx-react';
import {
	useNavigate,
	useParams,
}                        from 'react-router-dom';
import React             from 'react';
import { getUrnFromId }  from 'tools/UrnTools';

const goToPage = async (quotationId: number, path: string, navigate) => {
	navigate(path + '?filterUrn=' + getUrnFromId('quotation', quotationId));
};

const downloadQuotationPdf = (quotationDocumentCollection: ApiCollection<FileModel>) => {
	const firstQuotationDocument = quotationDocumentCollection.first();
	firstQuotationDocument?.download();
};

const DropdownMenu = ({
	quotationFileCollection,
}: {
	quotationFileCollection: ApiCollection<FileModel>;
}) => {
	const { id } = useParams<{ id?: string | undefined; }>();
	const navigate = useNavigate();
	const to = useTo();

	const quotationId = id ? parseInt(id) : 0;

	return (
		<Dropdown
			menu={{
				items: [
					{
						key: 'factures',
						label: <><EyeOutlined /> Voir les factures</>,
						onClick: () => goToPage(quotationId, to('/my-invoices'), navigate),
					},
					{
						key: 'interventions',
						label: <><EyeOutlined /> Voir les prochaines interventions</>,
						onClick: () => goToPage(quotationId, to('/future-interventions'), navigate),
					},
					{
						key: 'completed',
						label: <><EyeOutlined /> Voir les interventions effectuées</>,
						onClick: () => goToPage(quotationId, to('/completed-interventions'), navigate),
					},
					{
						disabled: !quotationFileCollection.length,
						key: 'devis',
						label: <><DownloadOutlined /> Télécharger le devis</>,
						onClick: () => {
							downloadQuotationPdf(quotationFileCollection);
						},
					},
				]
			}}
			trigger={['click']}
		>
			<Button style={{ padding: '0 8px' }} type="primary">
				<EllipsisOutlined style={{ fontSize: 20, verticalAlign: 'top' }} />
			</Button>
		</Dropdown>
	);
};

export default observer(DropdownMenu);
