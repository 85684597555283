import FiltersModel from '../modelx/models/FiltersModel';

export default class GlobalFilterStore implements Store {

	public filters = new FiltersModel();

	public clear() {
		this.filters.clear();
	}

	public async fetch(): Promise<void> {
		await Promise.all([
			this.filters.fetch(),
		]);
	}
}