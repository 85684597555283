import { computed }      from 'mobx';
import DirectoryApiModel from '../../abstracts/DirectoryApiModel';

export default class LoginCheckModel extends DirectoryApiModel {
	static get path(): string {
		return '/login_check';
	}

	@computed
	public get token(): string {
		return this.get('token', '');
	}

	@computed
	public get refreshToken(): string {
		return this.get('refresh_token', '');
	}
}
