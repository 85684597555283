import { computed }         from 'mobx';
import { getIdFromUrn }     from '../../../../tools/UrnTools';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export default class ContractIterationItemActivityModel extends SalesPrivateApiModel {
	public filters: ModelFiltersExtended<{
		contractIterationItem: id;
		'contractIterationItem.contractIteration': id
		locationUrn: string;
	}> = {};

	static get path(): string {
		return '/contract_iteration_item_activities/{?id}';
	}

	@computed
	public get activityId(): number {
		return this.get('activity.id');
	}

	@computed
	public get activityIri(): number {
		return this.get('activity.@id');
	}

	@computed
	public get taskZoneId(): string {
		return getIdFromUrn(this.locationUrn);
	}

	@computed
	public get locationUrn(): string {
		return this.get('locationUrn', '');
	}

	@computed
	public get contractIterationItemId(): number {
		return this.get('contractIterationItem.id');
	}

	@computed
	public get priceExclTax(): number {
		return this.get('priceExclTax');
	}

	@computed
	public get totalPriceExclTax(): number {
		return this.get('totalPriceExclTax');
	}

	@computed
	public get totalPriceInclTax(): number {
		return this.get('totalPriceInclTax');
	}

	@computed
	public get vatUrn(): number {
		return this.get('vatUrn');
	}
}
