import ConfigProxy            from '../../../tools/ConfigProxy';
import { LoggedApiConnector } from '../connectors/LoggedApiConnector';
import AbstractApiModel       from './AbstractApiModel';

export const salesLoggedConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('sales', 'api_endpoint'),
});

export default class SalesPrivateApiModel extends AbstractApiModel {
	public static get connector() {
		return salesLoggedConnector;
	}
}
