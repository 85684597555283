import Card                        from 'antd/lib/card';
import Empty                       from 'antd/lib/empty';
import { ColumnsType }             from 'antd/lib/table/interface';
import { Page }                    from 'components/Page';
import { observer }                from 'mobx-react';
import moment                      from 'moment';
import React                       from 'react';
import { appStore, invoicesStore } from 'stores/index';
import { formatPrice }             from 'tools/PriceHelper';
import TablePaginationCollection   from '../../../components/TablePaginationCollection';
import UrnFilter                   from '../../../components/filters/UrnFilter';
import InvoiceModel                from '../../../modelx/models/private/invoice/InvoiceModel';
import BoxDropdownMenu             from './BoxDropdownMenu';
import InvoiceDropdown             from './InvoiceDropdown';

interface IMyInvoiceListPageProps {

}

@observer
class MyInvoiceListPage extends React.Component<IMyInvoiceListPageProps> {

	public render() {
		if (
			!appStore.contractIterationCollection.length
			&& !appStore.quotationCollection.length
		) {
			return (
				<Empty
					description="Aucune facture"
					style={{
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						justifyContent: 'center',
					}}
				/>
			);
		}

		const {
			invoiceCollection,
		} = invoicesStore;

		return (
			<Page title="Mes factures">
				<div>
					<UrnFilter />

					<Card
						className="card-with-table"
						extra={<BoxDropdownMenu />}
						loading={invoiceCollection.isLoading}
						title="Synthèse de mes factures"
					>
						<TablePaginationCollection
							collection={invoiceCollection}
							columns={this.columns}
							rowKey={invoice => invoice.id}
						/>
					</Card>
				</div>
			</Page>
		);
	}

	public get columns(): ColumnsType<InvoiceModel> {
		return [
			{
				key: 'number',
				render: (_v, invoice) => invoice.reference,
				sorter: true,
				title: 'Numéro',
			},
			{
				key: 'editedAt',
				render: (_v, invoice) => moment(invoice.date).format('L'),
				sorter: true,
				title: 'Date',
			},
			{
				key: 'totalPriceTaxExcl',
				render: (_v, invoice) => formatPrice(invoice.amountExclTax),
				sorter: true,
				title: 'Montant HT',
			},
			{
				align: 'right',
				fixed: 'right',
				render: (_v, invoice) => (
					<InvoiceDropdown invoice={invoice} />
				),
				title: '',
			},
		];
	}
}

export default MyInvoiceListPage;
