import Form                           from 'antd/lib/form';
import Select                         from 'antd/lib/select';
import Spin                           from 'antd/lib/spin';
import { groupedStatuses }            from 'constants/QuotationStatuses';
import { defaultQuotationGroupNames } from 'constants/QuotationStatuses';
import { observer }                   from 'mobx-react';
import React                          from 'react';
import { appStore }                   from '../../stores';

function QuotationFilter() {
	const {
		quotationStatusCollection,
		quotationStatusUrnFilter,
	} = appStore;

	const isLoading = (quotationStatusCollection.isLoading);

	const initialValues = React.useMemo(() => {
		if (quotationStatusUrnFilter) {
			const references = quotationStatusCollection
				.filter(status => quotationStatusUrnFilter.includes(status.urn))
				.map(status => status.reference);

			return Object.keys(groupedStatuses)
				.filter(name => groupedStatuses[name].statuses
					.some(item => references.includes(item.reference)),
				);
		}

		return defaultQuotationGroupNames;
	}, [isLoading]);

	if (isLoading) {
		return <Spin />;
	}

	return (
		<Form initialValues={{ urn: initialValues }}>
			<Form.Item label="Statut" name="urn" style={{ width: 400 }}>
				<Select
					allowClear
					loading={isLoading}
					mode="multiple"
					onChange={names => {
						const references = names
							.map(name => groupedStatuses[name].statuses.map(item => item.reference))
							.flat();

						appStore.setQuotationStatusUrnFilter(
							quotationStatusCollection
								.filter(status => references.includes(status.reference))
								.map(status => status.urn),
						);
					}}
					placeholder="Statut"
				>
					{Object.entries(groupedStatuses).map(([name, item]) => {
						return (
							<Select.Option key={name} value={name}>
								{item.label}
							</Select.Option>
						);
					})}
				</Select>
			</Form.Item>
		</Form>
	);
}

export default observer(QuotationFilter);
