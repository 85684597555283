import resolvable                     from 'decorators/resolvable';
import { computed, override }         from 'mobx';
import moment                         from 'moment';
import { getIdFromUrn }               from 'tools/UrnTools';
import SalesPrivateApiModel           from '../../abstracts/SalesPrivateApiModel';
import ContractModel                  from './ContractModel';

export default class ContractIterationModel extends SalesPrivateApiModel {
	@resolvable(ContractModel, { attributeName: 'contract' })
	declare contract: ContractModel;

	public filters: ModelFiltersExtended<{
		archived: boolean;
		'contract': id;
		'contract.owner.partitionUrn': string;
		'contractIterationStatus.reference': string;
		'items.itemActivities.locationUrn': string,
	}> = {};

	static get path(): string {
		return '/contract_iterations/{?id}';
	}

	@computed
	public get archived(): boolean {
		return this.get('archived');
	}

	@computed
	public get additionalDesignation(): string {
		return this.get('additionalDesignation');
	}

	@computed
	public get billingGroupId(): number {
		return parseInt(getIdFromUrn(this.get('billingGroupUrn')));
	}

	@computed
	public get clientReference(): string {
		return this.get('clientReference');
	}

	@computed
	public get clientValidationId(): number {
		return this.get('clientValidationId');
	}

	@computed
	public get closedAt(): string {
		return this.get('closedAt');
	}

	@computed
	public get comment(): string {
		return this.get('comment');
	}

	@computed
	public get contractId(): number {
		return this.get('contract.id');
	}

	@computed
	public get contractUrn(): number {
		return this.get('contract.@urn');
	}

	@computed
	public get contractIterationStatusId(): number {
		return this.get('contractIterationStatus.id');
	}

	@computed
	public get contractIterationStatusIri(): string {
		return this.get('contractIterationStatus.@id');
	}

	@computed
	public get contractIterationStatusUrn(): string {
		return this.get('contractIterationStatus.@urn');
	}

	@override
	public get createdAt(): string {
		return this.get('createdAt');
	}

	@computed
	public get dc4Mode(): boolean {
		return this.get('dc4Mode', false);
	}

	@computed
	public get denomination(): string {
		return this.get('denomination');
	}

	@computed
	public get denominationBis(): string {
		return this.get('denominationBis');
	}

	@computed
	public get discount(): number {
		return this.get('discount');
	}

	@computed
	public get duration(): number {
		return this.get('duration');
	}

	@computed
	public get endDate(): string {
		return this.get('endDate');
	}

	@computed
	public get endDateIsPassed(): boolean {
		return moment(this.endDate).isSameOrBefore(moment());
	}

	@computed
	public get onPurchaseOrder(): boolean {
		return this.get('onPurchaseOrder', false);
	}

	@computed
	public get note(): string {
		return this.get('note');
	}

	@computed
	public get startDate(): string {
		return this.get('startDate');
	}

	@computed
	public get totalOverchargedWhenClosed(): boolean {
		return this.get('totalOverchargedWhenClosed');
	}

	@override
	public get updatedAt(): string {
		return this.get('updatedAt');
	}

	@computed
	public get address(): string {
		return this.get('address');
	}

	@computed
	public get addressBis(): string {
		return this.get('addressBis');
	}

	@computed
	public get city(): string {
		return this.get('city');
	}

	@computed
	public get country(): string {
		return this.get('country');
	}

	@computed
	public get zipCode(): string {
		return this.get('zipCode');
	}

	@computed
	public get totalPriceExclTax(): number {
		return this.get('totalPriceExclTax', 0);
	}

	@computed
	public get totalPriceInclTax(): number {
		return this.get('totalPriceInclTax', 0);
	}

	@computed
	public get reassessmentTotalPriceExclTax(): number {
		return this.get('reassessmentTotalPriceExclTax', 0);
	}

	@computed
	public get title(): string {
		return this.get('title');
	}
}
