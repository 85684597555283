import { ExclamationCircleOutlined }     from '@ant-design/icons';
import Card                              from 'antd/lib/card';
import Col                               from 'antd/lib/col';
import Descriptions                      from 'antd/lib/descriptions';
import Modal                             from 'antd/lib/modal';
import notification                      from 'antd/lib/notification';
import Row                               from 'antd/lib/row';
import { Page }                          from 'components/Page';
import { getGenderLabel }                from 'constants/Genders';
import { observer }                      from 'mobx-react';
import React, { useState }               from 'react';
import ContactModalForm                  from '../../forms/ContactModalForm';
import ContactPasswordModalForm          from '../../forms/ContactPasswordModalForm';
import { appStore, authenticationStore } from '../../stores';
import formManageApiError                from '../../utils/formManageApiError';
import notificationApiError              from '../../utils/notificationApiError';

function MyAccountPage() {
	const {
		globalContact,
	} = appStore;

	const [visibleModalEditInfo, setVisibleModalEditInfo] = useState(false);
	const [visibleModalPassword, setVisibleModalPassword] = useState(false);

	return (
		<Page title="Mon compte">
			<div>
				<Row gutter={20}>
					<Col md={12} xs={24}>
						<Card
							title="Mes informations"
						>
							<b style={{ fontSize: 16 }}>{globalContact.fullName}</b>

							<Descriptions column={1} size="small" style={{ marginTop: 10 }}>
								<Descriptions.Item label="Genre">
									{getGenderLabel(globalContact.gender)}
								</Descriptions.Item>

								<Descriptions.Item label="Email">
									{globalContact.email}
								</Descriptions.Item>

								<Descriptions.Item label="Téléphone principal">
									{globalContact.phoneNumber}
								</Descriptions.Item>

								<Descriptions.Item label="Téléphone secondaire">
									{globalContact.phoneNumberBis}
								</Descriptions.Item>
							</Descriptions>
						</Card>
					</Col>
				</Row>

				<ContactModalForm
					formProps={{
						initialValues: {
							email: globalContact.email,
							firstName: globalContact.firstName,
							gender: globalContact.gender,
							lastName: globalContact.lastName,
							phoneNumber: globalContact.phoneNumber,
							phoneNumberBis: globalContact.phoneNumberBis,
						},
					}}
					onCancel={() => setVisibleModalEditInfo(false)}
					onSubmit={(values, form) => {
						const isUpdatedMail = values.email !== globalContact.email;

						const submit = async () => {
							try {
								await globalContact.patch(values);

								notification.success({
									description: 'Vos informations ont bien été modifiées',
									message: 'Informations modifiées',
								});

								setVisibleModalEditInfo(false);
							} catch (e) {
								notificationApiError(e);
								formManageApiError(e, form);
							}
						};

						if (isUpdatedMail) {
							Modal.confirm({
								content: 'En continuant votre adresse email va être modifiée et vous devrez vous connecter de nouveau',
								icon: <ExclamationCircleOutlined />,
								onOk: () => {
									submit();
									authenticationStore.logout();
								},
								title: 'Votre adresse email va être modifiée',
							});
						} else {
							submit();
						}
					}}
					open={visibleModalEditInfo}
					title="Modifier mes informations"
				/>

				<ContactPasswordModalForm
					onCancel={() => setVisibleModalPassword(false)}
					onSubmit={async values => {
						const {
							contact,
						} = appStore;

						await contact.patch({
							plainPassword: values.plainPassword,
						});

						notification.success({
							description: 'Votre mot de passe a bien été modifié',
							message: 'Mot de passe modifié',
						});

						setVisibleModalPassword(false);
					}}
					open={visibleModalPassword}
					title="Modifier mon mot de passe"
				/>
			</div>
		</Page>
	);
}

export default observer(MyAccountPage);