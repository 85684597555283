import { computed }         from 'mobx';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

type SalesFrameworkAgreementReference = string; // TODO

export default class FrameworkAgreementModel extends SalesPrivateApiModel {
	static get path(): string {
		return '/framework_agreements/{?id}';
	}

	public filters: ModelFiltersExtended<{
		'frameworkAgreementEnterprises': id;
		'frameworkAgreementIterations': id;
		'frameworkAgreementStatus': id;
		'frameworkAgreementStatusHistories': id;
		'frameworkAgreementSubPartitions': id;
		'ownerPartition': id;
	}> = {};

	@computed
	public get frameworkAgreementStatusId(): number {
		return this.get('frameworkAgreementStatus.id');
	}

	@computed
	public get label(): string {
		return this.get('label');
	}

	@computed
	public get number(): number {
		return this.get('number');
	}

	@computed
	public get oldNumber(): string {
		return this.get('oldNumber');
	}

	@computed
	public get ownerPartitionId(): number {
		return this.get('ownerPartition.id');
	}

	@computed
	public get reference(): SalesFrameworkAgreementReference {
		return this.get('reference');
	}
}
