import { computed }         from 'mobx';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

export type SalesQuotationOptionReference =
	'QUOTATION_BPU'
	| 'QUOTATION_OUT_OF_BPU'
	| 'QUOTATION_REQUEST_BPU'
	| 'QUOTATION_REQUEST_OUT_OF_BPU';

export default class QuotationOptionModel extends SalesPrivateApiModel {
	static get path(): string {
		return '/quotation_options/{?id}';
	}

	public filters: ModelFiltersExtended<unknown> = {};

	// public _sorts: ModelSortsExtended<{
	// 	'id': string;
	// 	'label': string;
	// 	'reference': string;
	// 	'updatedAt': string;
	// }> = {};

	@computed
	public get description(): string {
		return this.get('description');
	}

	@computed
	public get label(): string {
		return this.get('label');
	}

	@computed
	public get quotationFrameworkAgreementIterationTypeId(): number {
		return this.get('quotationFrameworkAgreementIterationType.id');
	}

	@computed
	public get QuotationOptionEntityModelId(): number {
		return this.get('quotationOptionEntityModel.id');
	}

	@computed
	public get reference(): string {
		return this.get('reference');
	}
}
