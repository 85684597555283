import { ApiCollection }      from 'Collections/ApiCollection';
import useTo                  from 'hooks/useTo';
import { observer }           from 'mobx-react';
import { Link }               from 'react-router-dom';
import React                  from 'react';
import ContractIterationModel from '../../modelx/models/private/sales/ContractIterationModel';
import ContractModel          from '../../modelx/models/private/sales/ContractModel';

const ContractIterationNumberLink = ({
	contractCollection,
	contractIteration,
}: {
	contractCollection: ApiCollection<ContractModel>;
	contractIteration: ContractIterationModel;
}) => {
	const contract = contractCollection.find(contract => contract.id === contractIteration.contractId);
	const to = useTo();

	if (!contract) {
		return null;
	}

	return (
		<Link to={to(`/contracts/${contractIteration.id}`)}>{contract.number}</Link>
	);
};

export default observer(ContractIterationNumberLink);
