import { computed }      from 'mobx';
import RhPrivateApiModel from '../../abstracts/RhPrivateApiModel';

export default class StaffMemberModel extends RhPrivateApiModel {
	public filters: ModelFiltersExtended<{
		partitionUrn: string;
		staff: id;
	}> = {};

	static get path(): string {
		return '/staff_members/{?id}';
	}

	@computed
	public get staffIri(): string {
		return this.get('staff.@id', '');
	}

	@computed
	public get partitionUrn(): string {
		return this.get('partitionUrn', '');
	}

	@computed
	public get email(): string {
		return this.get('email', '');
	}

	@computed
	public get phoneNumber(): string {
		return this.get('phoneNumber', '');
	}

	@computed
	public get dateIn(): string {
		return this.get('dateIn', '');
	}

	@computed
	public get dateOut(): string {
		return this.get('dateOut', '');
	}

	@computed
	public get hasMutual(): boolean {
		return this.get('hasMutual', false);
	}

	@computed
	public get contractIri(): string {
		return this.get('contract.@id', '');
	}

	@computed
	public get staffId(): number {
		return this.get('staff.id', '');
	}
}
