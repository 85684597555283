import { computed }             from 'mobx';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

export default class EnterprisePartitionModel extends DirectoryPrivateApiModel {
	public filters: ModelFiltersExtended<{
		contacts: id;
		'enterprise.enterpriseGroup': id;
		'enterprise.enterpriseType': id;
		'partitionUrn': string;
	}> = {};

	static get path(): string {
		return '/enterprise_partitions/{?id}';
	}

	@computed
	public get companyId(): number {
		return this.get('company.id', '');
	}

	@computed
	public get enterpriseId(): number {
		return this.get('enterprise.id', '');
	}

	@computed
	public get enterpriseUrn(): string {
		return this.get('enterprise.@urn', '');
	}

	@computed
	public get partitionUrn(): string {
		return this.get('partitionUrn', '');
	}
}
