import { computed }                from 'mobx';
import { getIdFromUrn }            from 'tools/UrnTools';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class OwnerModel extends InterventionPrivateApiModel {
	public filters: ModelFiltersExtended<{
		'clientUrn': string;
		'companyUrn': string;
		'enterprisePartitionUrn': string;
		'enterpriseUrn': string;
	}> = {};

	static get path(): string {
		return '/owners/{?id}';
	}

	@computed
	public get companyUrn(): string {
		return this.get('companyUrn', '');
	}

	@computed
	public get companyId(): number {
		return parseInt(getIdFromUrn(this.companyUrn));
	}

	@computed
	public get clientPartitionId(): number {
		return parseInt(getIdFromUrn(this.get('clientPartitionUrn', '')));
	}

	@computed
	public get clientPartitionUrn(): string {
		return this.get('clientPartitionUrn');
	}

	@computed
	public get clientUrn() {
		return this.get('clientUrn');
	}

	@computed
	public get enterprisePartitionUrn(): string {
		return this.get('enterprisePartitionUrn', '');
	}

	@computed
	public get enterpiseUrn(): string {
		return this.get('enterpiseUrn', '');
	}

	@computed
	public get ownerSubPartition() {
		return this.get('ownerSubPartition');
	}

	@computed
	public get partitionUrn(): string {
		return this.get('partitionUrn');
	}

	@computed
	public get subPartitionUrn() {
		return this.get('subPartitionUrn');
	}
}
