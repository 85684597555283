import ConfigProxy      from '../../../tools/ConfigProxy';
import { ApiConnector } from '../connectors/ApiConnector';
import AbstractApiModel from './AbstractApiModel';

export const rhConnector = new ApiConnector({
	baseURL: ConfigProxy.getServiceConfig('rh', 'api_endpoint'),
});

export default class RhApiModel extends AbstractApiModel {
	public static get connector() {
		return rhConnector;
	}
}
