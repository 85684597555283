import { UserOutlined }          from '@ant-design/icons';
import Button                    from 'antd/lib/button';
import Card                      from 'antd/lib/card';
import Form                      from 'antd/lib/form';
import Input                     from 'antd/lib/input';
import Result                    from 'antd/lib/result';
import { Page }                  from 'components/Page';
import { Link }                  from 'react-router-dom';
import React, { useState }       from 'react';
import ConfigProxy               from 'tools/ConfigProxy';
import ResetPasswordRequestModel from '../../modelx/models/public/directory/ResetPasswordRequestModel';
import PublicLayout              from '../../projectComponents/PublicLayout';
import notificationApiError      from '../../utils/notificationApiError';

const ForgottenPasswordForm = () => {
	const [loading, setLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	const onFinish = async values => {
		setLoading(true);

		try {
			await new ResetPasswordRequestModel({
				...values,
				partitionUrn: ConfigProxy.get('PARTITION_URN'),
				// routeUrl: window.location.origin,
			}).save();

			setIsSuccess(true);
		} catch (err) {
			notificationApiError(err);
		} finally {
			setLoading(false);
		}
	};

	if (isSuccess) {
		return (
			<Result
				status="success"
				style={{ padding: '20px 0' }}
				title="Un e-mail vous a été envoyé !"
			/>
		);
	}

	return (
		<Form name="forgotten_password_form" onFinish={onFinish}>
			<Form.Item
				name="email"
				rules={[
					{
						message: 'Veuillez renseigner votre adresse email!',
						required: true,
					},
				]}
			>
				<Input
					placeholder="Adresse email"
					prefix={<UserOutlined />}
				/>
			</Form.Item>

			<Button
				block={true}
				className="login-form-button"
				disabled={loading}
				htmlType="submit"
				loading={loading}
				type="primary"
			>
				Envoyer
			</Button>
		</Form>
	);
};

export default function ForgottenPasswordPage() {
	return (
		<PublicLayout title="Mot de passe oublié ?">
			<Page title="Mot de passe oublié">
				<p style={{ textAlign: 'center' }}>
					{`Indiquez l'adresse e-mail associée à votre espace client et nous vous enverrons par e-mail les`}
					{`informations pour créer un nouveau mot de passe`}
				</p>

				<Card>
					<ForgottenPasswordForm />
				</Card>

				<Link style={{ marginTop: 30, textAlign: 'center' }} to="/">
					{`Retour à la page d'accueil`}
				</Link>
			</Page>
		</PublicLayout>
	);
}