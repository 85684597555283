import { computed }      from 'mobx';
import LocalStorageModel from './abstracts/LocalStorageModel';

export default class FiltersModel extends LocalStorageModel {
	static get path(): string {
		return '/filters';
	}

	public setClientPartitionUrn(clientPartitionUrn: string | null | undefined): this {
		this.set('clientPartitionUrn', clientPartitionUrn);

		return this;
	}

	public setClientUrn(clientUrn: string | null | undefined): this {
		this.set('clientUrn', clientUrn);

		return this;
	}

	public setCompanyUrn(companyUrn: string | null | undefined): this {
		this.set('companyUrn', companyUrn);

		return this;
	}

	public setContactSearch(contactSearch: string | null | undefined): this {
		this.set('contactSearch', contactSearch);

		return this;
	}

	public setContractUrn(contractUrn: string | null | undefined): this {
		this.set('contractUrn', contractUrn);

		return this;
	}

	public setEnterpriseUrn(enterpriseUrn: string | null | undefined): this {
		this.set('enterpriseUrn', enterpriseUrn);

		return this;
	}

	public setInterventionUrn(interventionUrn: string | null | undefined): this {
		this.set('interventionUrn', interventionUrn);

		return this;
	}

	public setPurchaseOrderUrn(purchaseOrderUrn: string | null | undefined): this {
		this.set('purchaseOrderUrn', purchaseOrderUrn);

		return this;
	}

	public setTaskZoneGroupUrn(taskZoneGroupUrn: string | null | undefined): this {
		this.set('taskZoneGroupUrn', taskZoneGroupUrn);

		return this;
	}

	public setTaskZoneUrn(taskZoneUrn: string | null | undefined): this {
		this.set('taskZoneUrn', taskZoneUrn);

		return this;
	}

	@computed
	public get clientUrn(): string | null {
		return this.get('clientUrn');
	}

	@computed
	public get clientPartitionUrn(): string | null {
		return this.get('clientPartitionUrn');
	}

	@computed
	public get companyUrn(): string | null {
		return this.get('companyUrn');
	}

	@computed
	public get contactSearch(): string | null {
		return this.get('contactSearch');
	}

	@computed
	public get contractUrn(): string | null {
		return this.get('contractUrn');
	}

	@computed
	public get enterpriseUrn(): string | null {
		return this.get('enterpriseUrn');
	}

	@computed
	public get interventionUrn(): string | null {
		return this.get('interventionUrn');
	}

	@computed
	public get taskZoneGroupUrn(): string | null {
		return this.get('taskZoneGroupUrn');
	}

	@computed
	public get taskZoneUrn(): string | null {
		return this.get('taskZoneUrn');
	}

	@computed
	public get contractIterationStatusUrn(): string[] | undefined {
		return this.get('contractIterationStatusUrn');
	}

	public setContractIterationStatusUrn(contractIterationStatusUrn: string[]): this {
		this.set('contractIterationStatusUrn', contractIterationStatusUrn);

		return this;
	}

	@computed
	public get purchaseOrderUrn(): string[] | undefined {
		return this.get('purchaseOrderUrn');
	}

	@computed
	public get quotationStatusUrn(): string[] | undefined {
		return this.get('quotationStatusUrn');
	}

	public setQuotationStatusUrn(quotationStatusUrn: string[]): this {
		this.set('quotationStatusUrn', quotationStatusUrn);

		return this;
	}
}
