import Form                                            from 'antd/lib/form';
import Select                                          from 'antd/lib/select';
import usePutUrlParams                                 from 'hooks/usePutUrlParams';
import useUrlParam                                     from 'hooks/useUrlParam';
import { observer }                                    from 'mobx-react';
import React, { useEffect }                            from 'react';
import { appStore }                                    from '../../stores';
import { interventionsStore }                          from '../../stores';

function InterventionFilter() {
	const { interventionUrnFilter } = appStore;
	const { completedInterventionCollection, nextInterventionCollection } = interventionsStore;

	const completedInterventionCollectionClone = React.useMemo(() => completedInterventionCollection.clone(), [completedInterventionCollection.isLoading]);
	const nextInterventionCollectionClone = React.useMemo(() => nextInterventionCollection.clone(), [nextInterventionCollection.isLoading]);

	const putUrlParams = usePutUrlParams();
	const defaultUrn = useUrlParam('interventionUrn');
	
	const isLoading = (
		completedInterventionCollectionClone.isLoading
		|| nextInterventionCollectionClone.isLoading
	);
	
	useEffect(() => {
		if (defaultUrn) {
			appStore.setContractByInterventionUrn(defaultUrn);
		}
		else if(!isLoading) {
			if (completedInterventionCollectionClone.first()) {
				putUrlParams({ 'interventionUrn': completedInterventionCollectionClone.first()!.urn });
			}
			else if (nextInterventionCollectionClone.first()) {
				putUrlParams({ 'interventionUrn': nextInterventionCollectionClone.first()!.urn });
			}
		}
	}, [isLoading]);

	return (
		<Form
			initialValues={{ urn: interventionUrnFilter }}
			key={interventionUrnFilter}
		>
			<Form.Item
				label="Intervention"
				name="urn"
				style={{ width: 512 }}
			>
				<Select
					filterOption={false}
					loading={isLoading}
					onChange={urn => {
						appStore.setInterventionUrnFilter(urn.toString());
						putUrlParams({ interventionUrn: urn.toString() });
					}}
					onSearch={(searchValue) => {
						if (searchValue) {
							completedInterventionCollectionClone.setFilters(completedInterventionCollection.getFilters());
							nextInterventionCollectionClone.setFilters(nextInterventionCollection.getFilters());
							completedInterventionCollectionClone.replaceSorts(completedInterventionCollection.getSorts());
							nextInterventionCollectionClone.replaceSorts(nextInterventionCollection.getSorts());
							completedInterventionCollectionClone.setFilter('search', searchValue);
							nextInterventionCollectionClone.setFilter('search', searchValue);
						}
						else {
							completedInterventionCollectionClone.removeFilter('search');
							nextInterventionCollectionClone.removeFilter('search');
						}
						completedInterventionCollectionClone.list();
						nextInterventionCollectionClone.list();
					}}
					placeholder="Choisissez une intervention"
					showSearch
				>
					{nextInterventionCollectionClone.length && (
						<Select.OptGroup label="Prochaines interventions">
							{nextInterventionCollectionClone.map(intervention => (
								<Select.Option
									key={intervention.urn}
									value={intervention.urn}
								>
									{intervention.number} : {intervention.displayDate}
								</Select.Option>
							))}
						</Select.OptGroup>
					)}

					{completedInterventionCollectionClone.length && (
						<Select.OptGroup label="Interventions effectuées">
							{completedInterventionCollectionClone.map(intervention => (
								<Select.Option
									key={intervention.urn}
									value={intervention.urn}
								>
									{intervention.number} : {intervention.displayDate}
								</Select.Option>
							))}
						</Select.OptGroup>
					)}
				</Select>
			</Form.Item>
		</Form>
	);
}

export default observer(InterventionFilter);
