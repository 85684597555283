import Col                from 'antd/lib/col';
import { Page }           from 'components/Page';
import FileListBox        from 'entityComponents/File/FileListBox';
import { observer }       from 'mobx-react';
import React              from 'react';
import {
	quotationDashboardStore,
}                         from 'stores/index';
import QuotationDashboard from './QuotationDashboard';

export default observer(function QuotationDashboardPage() {
	return (
		<Page title="Tableau de bord du devis">
			<QuotationDashboard
				backPath="/quotations"
				onReady={id => quotationDashboardStore.fetch(id)}
				title="Tableau de bord du devis"
			>
				<Col md={12} xs={24}>
					<FileListBox
						references={[
							'quotation_document_for_client',
						]}
						servicesUrns={[]}
						sourcesUrns={[
							quotationDashboardStore.quotation.urn,
						]}
					/>
				</Col>
			</QuotationDashboard>
		</Page>
	);
});
