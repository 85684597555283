import React                from 'react';
import ConfigProxy          from 'tools/ConfigProxy';
import logoADDD             from '../../images/addd-logo.png';
import logoAGETEP           from '../../images/agetep-logo.png';
import logoAIRPLUSNETOUEST  from '../../images/airplusnetouest-logo.png';
import logoCG2D             from '../../images/cg2d-logo.png';
import logoDICOP            from '../../images/dicop-logo.png';
import logoHPR              from '../../images/hpr-logo.png';
import logoIGC              from '../../images/igc-logo.png';
import logoINTER            from '../../images/inter-logo.png';
import logoD2H              from '../../images/logo-D2H.png';
import logoADIAMS           from '../../images/logo-adiams.png';
import logoAIRSN            from '../../images/logo-airsn.png';
import logoIGIENAIR         from '../../images/logo-igienair.png';
import logoMAPCLIM          from '../../images/logo-mapclim.png';
import logoQHS              from '../../images/logo-qhs.png';
import logoZAACK            from '../../images/logo-zacck.png';
import logoNANOSENSE        from '../../images/nanosense-logo.png';
import logoRCC              from '../../images/rcc-logo.png';

const LogoComponent = (props) => {
	const partitionName = ConfigProxy.get('PARTITION_NAME');
	const partitionUrn = ConfigProxy.get('PARTITION_URN');

	switch (partitionUrn) {
		case '$:partition:partition:1':
			return <img alt={`logo ${partitionName}`} src={logoQHS} {...props} />;
		case '$:partition:partition:2':
			return <img alt={`logo ${partitionName}`} src={logoDICOP} {...props} />;
		case '$:partition:partition:3':
			return <img alt={`logo ${partitionName}`} src={logoHPR} {...props} />;
		case '$:partition:partition:4':
			return <img alt={`logo ${partitionName}`} src={logoHPR} {...props} />;
		case '$:partition:partition:5':
			return <img alt={`logo ${partitionName}`} src={logoCG2D} {...props} />;
		case '$:partition:partition:6':
			return <img alt={`logo ${partitionName}`} src={logoRCC} {...props} />;
		case '$:partition:partition:7':
			return <img alt={`logo ${partitionName}`} src={logoIGIENAIR} {...props} />;
		case '$:partition:partition:8':
			return <img alt={`logo ${partitionName}`} src={logoADIAMS} {...props} />;
		case '$:partition:partition:9':
			return <img alt={`logo ${partitionName}`} src={logoAIRSN} {...props} />;
		case '$:partition:partition:10':
			return <img alt={`logo ${partitionName}`} src={logoMAPCLIM} {...props} />;
		case '$:partition:partition:11':
			return <img alt={`logo ${partitionName}`} src={logoD2H} {...props} />;
		case '$:partition:partition:12':
			return <img alt={`logo ${partitionName}`} src={logoZAACK} {...props} />;
		case '$:partition:partition:13':
			return <img alt={`logo ${partitionName}`} src={logoNANOSENSE} {...props} />;
		case '$:partition:partition:14':
			return <img alt={`logo ${partitionName}`} src={logoADDD} {...props} />;
		case '$:partition:partition:15':
			return <img alt={`logo ${partitionName}`} src={logoAGETEP} {...props} />;
		case '$:partition:partition:16':
			return <img alt={`logo ${partitionName}`} src={logoIGC} {...props} />;
		case '$:partition:partition:17':
			return <img alt={`logo ${partitionName}`} src={logoINTER} {...props} />;
		case '$:partition:partition:18':
			return <img alt={`logo ${partitionName}`} src={logoAIRPLUSNETOUEST} {...props} />;
		default:
			return null;
	}
};

export default LogoComponent;
