import Card             from 'antd/lib/card';
import Table            from 'antd/lib/table';
import { TableProps }   from 'antd/lib/table';
import React            from 'react';
import AbstractApiModel from '../modelx/models/abstracts/AbstractApiModel';

const DashboardListBox = (props: Partial<TableProps<AbstractApiModel>> & {
	boxTitle: string;
	loading?: boolean;
}) => {
	return (
		<Card
			loading={props.loading}
			title={props.boxTitle}
		>
			<Table {...props} />
		</Card>
	);
};

DashboardListBox.defaultProps = {
	loading: false,
};

export default DashboardListBox;