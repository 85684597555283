import { computed, override }            from 'mobx';
import DirectoryPrivateApiModel          from 'modelx/models/abstracts/DirectoryPrivateApiModel';
import { getIdFromUrn }                  from 'tools/UrnTools';
import StaffGenders, { GenderReference } from '../../../../constants/Genders';

export default class GlobalContactModel extends DirectoryPrivateApiModel {

	public filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'email': string;
		'firstName': string;
		'lastName': string;
		'phoneNumber': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	static get path(): string {
		return '/global_contacts/{?id}';
	}

	@computed
	public get firstName(): string {
		return this.get('firstName', '');
	}

	@computed
	public get lastName(): string {
		return this.get('lastName', '');
	}

	@computed
	public get fullName(): string {
		return `${this.firstName} ${this.lastName}`;
	}

	@computed
	public get fullNameReversed(): string {
		return `${this.lastName} ${this.firstName}`;
	}

	@computed
	public get civilityLabel(): string {
		return StaffGenders.find(g => g.reference === this.gender)?.civility || '';
	}

	@override
	public get createdAt(): string {
		return this.get('createdAt', '');
	}

	@computed
	public get faxNumber(): string {
		return this.get('faxNumber', '');
	}

	@computed
	public get phoneNumber(): string {
		return this.get('phoneNumber', '');
	}

	@computed
	public get phoneNumberBis(): string {
		return this.get('phoneNumberBis', '');
	}

	@computed
	public get gender(): GenderReference {
		return this.get('gender');
	}

	@computed
	public get genderName(): string {
		return StaffGenders.find(g => g.reference === this.gender)?.label || '';
	}

	@computed
	public get email(): string {
		return this.get('email', '');
	}

	@override
	public get createdById(): number {
		return getIdFromUrn(this.get('createdBy', '')) as unknown as number || 0;
	}

	@computed
	public get initials(): string {
		return (this.firstName ? this.firstName[0] : '') + (this.lastName ? this.lastName[0] : '');
	}
}
