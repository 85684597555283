import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class ReportInterventionModel extends InterventionPrivateApiModel {
	public filters: ModelFiltersExtended<{ 
		intervention: id; 
	}> = {};

	static get path(): string {
		return '/report_interventions/{?id}';
	}

	@computed
	public get interventionId(): number {
		return this.get('intervention.id');
	}

	@computed
	public get reportId(): number {
		return this.get('report.id');
	}

	@computed
	public get reportUrn(): string {
		return this.get('report.@urn');
	}
}
