import Modal from 'antd/lib/modal';

export default async (onOk: () => void, onCancel?: () => void): Promise<void> => {
	return new Promise((resolve => {
		Modal.confirm({
			cancelText: 'Non',
			okText: 'Oui',
			onCancel: onCancel && (() => {
				onCancel();

				resolve();
			}),
			onOk: () => {
				onOk();

				resolve();
			},
			title: 'Souhaitez-vous créer des accès pour ce contact ?',
			width: 450,
		});
	}));
};