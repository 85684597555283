import dot          from 'dot-object';
import GroupStorage from 'tools/GroupStorage';

const hasStorage = typeof localStorage !== 'undefined';

export const searchUrlParams = (search) => {
	let hashes = search.slice(search.indexOf('?') + 1).split('&');
	hashes = hashes.reduce((params, hash) => {
		const [key, val] = hash.split('=');
		return Object.assign(params, { [key]: decodeURIComponent(val) });
	}, {});
	return dot.object(hashes);
};

export const saveParamsInLocalStorage = (key: string, params: Record<string, unknown>): void => {
	if (hasStorage) {
		localStorage.setItem(key, JSON.stringify(params));
	}
};

export const getParamsInLocalStorage = (key: string): Record<string, string> => {
	if (hasStorage) {
		const str = localStorage.getItem(key);

		if (str) {
			return JSON.parse(str || '{}');
		}
	}

	return {};
};

export const getUrlParams = (): Record<string, string> => {
	const queryString = window.location.search;

	const urlParams = new URLSearchParams(queryString);

	const params = {};

	const entries = Array.from(urlParams.entries());

	for (const entry of entries) {
		params[entry[0]] = entry[1];
	}

	return params;
};

export const getUrlOrLocalStorageParams = (key: string): Record<string, string> => {
	let params: Record<string, string> = {};

	const urlParams = getUrlParams();

	// Si des paramètres sont passés dans l'url, ils ont la priorité sur le localStorage
	if (Object.keys(urlParams).length) {
		params = urlParams;
	} else {
		const localStorageParams = getParamsInLocalStorage(key);

		if (localStorageParams !== null) {
			params = localStorageParams;
		}
	}

	return params;
};

export const getUrlOrGroupStorageParams = (key: string, storage: GroupStorage): Record<string, string> => {
	let params: Record<string, string> = {};

	const urlParams = getUrlParams();

	// Si des paramètres sont passés dans l'url, ils ont la priorité sur le localStorage
	if (Object.keys(urlParams).length) {
		params = urlParams;
	} else {
		const storageParams = storage.getData(key) as Record<string, string>;

		if (storageParams) {
			params = storageParams;
		}
	}

	return params;
};
