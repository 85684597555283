import resolvable                  from 'decorators/resolvable';
import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';
import TaskOperationModel          from './TaskOperationModel';

export default class InterventionOperationModel extends InterventionPrivateApiModel {
	public filters: ModelFiltersExtended<{ intervention: id; taskOperation: id; }> = {};

	static get path(): string {
		return '/intervention_operations/{?id}';
	}

	@computed
	public get interventionId(): number {
		return this.get('intervention.id', 0);
	}

	@computed
	public get taskOperationId(): number {
		return this.get('taskOperation.id', 0);
	}

	@computed
	public get interventionOperationStatusId(): number {
		return this.get('interventionOperationStatus.id', 0);
	}
	
	@resolvable(TaskOperationModel, { attributeName: 'taskOperation' })
	declare taskOperation: TaskOperationModel;
}
