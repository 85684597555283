import { computed }         from 'mobx';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

type ExportStatusReference = 'to_do' | 'done' | 'to_send' | 'sent';

const colors: Record<ExportStatusReference, string> = {
	done: 'green',
	sent: 'green',
	to_do: 'blue',
	to_send: 'blue',
};

export default class ExportStatusModel extends SalesPrivateApiModel {
	static get path(): string {
		return '/export_statuses/{?id}';
	}

	@computed
	public get reference(): ExportStatusReference {
		return this.get('reference', '');
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	@computed
	public get color(): string {
		return colors[this.reference];
	}
}
