import { computed }         from 'mobx';
import InterventionApiModel from '../../abstracts/InterventionApiModel';

export default class PublicPartitionMetaModel extends InterventionApiModel {
	static get path(): string {
		return '/partition_metas';
	}

	public filters: ModelFiltersExtended<{
		'partitionUrn': string;
		'reference': string;
	}> = {};

	@computed
	public get value() {
		return this.get('value');
	}
}
