import FilePrivateApiModel from '../../abstracts/FilePrivateApiModel';

export default class FileTypeModel extends FilePrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': FileTypeReference;
	}> = {};

	static get path(): string {
		return '/stonecode_file_types/{?id}';
	}

	public get label(): string {
		return this.get('label');
	}

	public get reference(): FileTypeReference {
		return this.get('reference');
	}
}
