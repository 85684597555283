import TaskModel                             from 'Models/intervention/TaskModel';
import ContractIterationItemModel            from 'Models/sales/ContractIterationItemModel';
import resolvable                            from 'decorators/resolvable';
import { computed }                          from 'mobx';
import moment                                from 'moment';
import SalesPrivateApiModel                  from '../../abstracts/SalesPrivateApiModel';

export default class ContractIterationItemEventModel extends SalesPrivateApiModel {

	static get path(): string {
		return '/contract_iteration_item_events/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'contractIterationItem': id;
		'contractIterationItem.contractIteration': id;
		'contractIterationItemEventStatus.reference': string;
	}> = {};


	@resolvable(ContractIterationItemModel, { attributeName: 'contractIterationItem' })
	declare contractIterationItem: ContractIterationItemModel;

	@computed
	public get scheduleEndDate(): Moment {
		return moment(this.get('scheduleEndDate', moment()));
	}

	@computed
	public get scheduleStartDate(): Moment {
		return moment(this.get('scheduleStartDate', moment()));
	}

	@computed
	public get taskUrn(): string {
		return this.get('taskUrn');
	}

	@resolvable(TaskModel, { attributeName: 'taskUrn' })
	declare task: TaskModel;
}