import { ApiCollection }            from 'Collections/ApiCollection';
import ClientPartitionModel         from 'Models/directory/ClientPartitionModel';
import ContactModel                 from '../modelx/models/private/directory/ContactModel';
import InterventionContactTypeModel from '../modelx/models/private/intervention/ContactTypeModel';
import OperationCategoryModel       from '../modelx/models/private/intervention/OperationCategoryModel';
import TaskZoneModel                from '../modelx/models/private/intervention/TaskZoneModel';
import ZoneContactModel             from '../modelx/models/private/intervention/ZoneContactModel';
import InvoiceContactTypeModel      from '../modelx/models/private/invoice/ContactTypeModel';
import InvoicingContactModel        from '../modelx/models/private/invoice/InvoicingContactModel';
import SalesContactModel            from '../modelx/models/private/sales/SalesContactModel';
import SalesContactTypeModel        from '../modelx/models/private/sales/SalesContactTypeModel';
import { appStore }                 from '../stores';
import ConfigProxy                  from '../tools/ConfigProxy';

export default class FunctionsStore implements Store {

	public clientContactCollection = new ApiCollection(ContactModel);
	public invoiceContactTypeCollection = new ApiCollection(InvoiceContactTypeModel);
	public invoicingContactCollection = new ApiCollection(InvoicingContactModel);
	public operationCategoryCollection = new ApiCollection(OperationCategoryModel);
	public salesContactCollection = new ApiCollection(SalesContactModel);
	public salesContactTypeCollection = new ApiCollection(SalesContactTypeModel);
	public taskZoneContactCollection = new ApiCollection(ContactModel);
	public taskZoneContactTypeCollection = new ApiCollection(InterventionContactTypeModel);
	public zoneContactCollection = new ApiCollection(ZoneContactModel);

	public clear(): void {
		this.clientContactCollection.clear();
		this.invoiceContactTypeCollection.clear();
		this.invoicingContactCollection.clear();
		this.operationCategoryCollection.clear();
		this.salesContactCollection.clear();
		this.salesContactTypeCollection.clear();
		this.taskZoneContactCollection.clear();
		this.taskZoneContactTypeCollection.clear();
		this.zoneContactCollection.clear();
	}

	public async fetchClientContact() {
		this.invoiceContactTypeCollection
			.list()
			.catch(err => {
				if (err?.request?.status === 403) {
					console.warn('Silent 403 for InvoiceContactTypeCollection');
				}
			});

		this.salesContactTypeCollection.list();

		await Promise.all([
			this.invoicingContactCollection
				.list()
				.catch(err => {
					if (err?.request?.status === 403) {
						console.warn('Silent 403 for InvoicingContactCollection');
					}
				}),

			this.salesContactCollection.list(),
		]);

		const contactsIds: number[] = [];
		this.invoicingContactCollection.forEach(invoicingContact => contactsIds.push(invoicingContact.contactId));
		this.salesContactCollection.map(salesContact => contactsIds.push(salesContact.contactId));

		this.clientContactCollection.setFilter('id', contactsIds);
		await this.clientContactCollection.list();
	}

	public async fetchTaskZoneContact() {
		await Promise.all([
			this.taskZoneContactTypeCollection.list(),
			this.zoneContactCollection.list().then(async () => {
				await this.taskZoneContactCollection
					.listByFromCollection(this.zoneContactCollection, 'contactId', 'id');
			}),
		]);
	}

	public async setClientPartitionFilterAndFetch(clientPartition: ClientPartitionModel) {
		this.invoicingContactCollection.setFilters({
			enterprisePartitionUrn: appStore.selectedEnterprisePartition?.urn,
		});

		this.salesContactCollection.setFilters({
			'owner.enterprisePartitionUrn': clientPartition.enterprisePartitionUrn,
			'owner.ownerSubPartition.partitionUrn': ConfigProxy.get('PARTITION_URN'),
		});

		await this.fetchClientContact();
	}

	public async setTaskZoneFilterAndFetch(taskZone: TaskZoneModel) {
		this.operationCategoryCollection.setFilters({
			partitionUrn: ConfigProxy.get('PARTITION_URN'),
		});

		this.zoneContactCollection.setFilters({
			taskZone: taskZone.id,
		});

		await Promise.all([
			this.operationCategoryCollection.list(),
			this.fetchTaskZoneContact(),
		]);
	}
}
