import * as Sentry      from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactDOM         from 'react-dom/client';
import React            from 'react';
import App              from './App';
import ConfigProxy      from './tools/ConfigProxy';

if (ConfigProxy.get('SENTRY_DSN_FRONT_CONTACT')) {
	Sentry.init({
		dsn: ConfigProxy.get('SENTRY_DSN_FRONT_CONTACT'),
		environment: ConfigProxy.get('APP_ENV'),
		ignoreErrors: [
			'ResizeObserver loop limit exceeded',
			'ResizeObserver loop completed with undelivered notifications.',
		],
		integrations: [new Integrations.BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});

	Sentry.setContext('app-context', {
		app: 'front-contact',
		partition: undefined,
	});
}

ReactDOM.createRoot(document.getElementById('main') as HTMLElement).render(<App />);

