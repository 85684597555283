import { ApiCollection }              from 'Collections/ApiCollection';
import ZoneContactModel               from 'Models/intervention/ZoneContactModel';
import Button                         from 'antd/lib/button';
import Col                            from 'antd/lib/col';
import { FormInstance }               from 'antd/lib/form/Form';
import Form                           from 'antd/lib/form';
import notification                   from 'antd/lib/notification';
import Radio                          from 'antd/lib/radio';
import Row                            from 'antd/lib/row';
import Select                         from 'antd/lib/select';
import { SelectValue }                from 'antd/lib/select';
import { observer }                   from 'mobx-react';
import React, { useEffect, useState } from 'react';
import ModalForm, { ModalFormProps }  from '../components/ModalForm';
import ContactModel                   from '../modelx/models/private/directory/ContactModel';
import ContactTypeModel               from '../modelx/models/private/intervention/ContactTypeModel';
import { appStore, functionsStore }   from '../stores';
import suggestCreateUser              from '../utils/suggestCreateUser';
import ContactAccessModalForm         from './ContactAccessModalForm';
import ContactModalForm               from './ContactModalForm';

interface ITaskZoneContactModalFormProps extends ModalFormProps {
	taskZoneContactTypeCollection: ApiCollection<ContactTypeModel>;
	type?: string;
	zoneContactCollection: ApiCollection<ZoneContactModel>;
}

const modalFormRef = React.createRef<FormInstance>();

const TaskZoneContactModalForm: React.FC<ITaskZoneContactModalFormProps> = props => {
	useEffect(() => {
		if (props.type) {
			setContactType(props.type);
		}
	}, [props.type]);

	const [addContactAccessVisible, setAddContactAccessVisible] = useState(false);
	const [contactType, setContactType] = useState<string>();
	const [currentContact, setCurrentContact] = useState<ContactModel>();
	const [modalVisible, setModalVisible] = useState(false);
	const [operationCategory, setOperationCategory] = useState<SelectValue>();

	const {
		taskZoneContactTypeCollection,
		zoneContactCollection,
	} = props;

	const isBuying = taskZoneContactTypeCollection.find(ct => ct.iri === contactType)?.reference === 'buying';

	return (
		<ModalForm
			ref={modalFormRef}
			title="Ajouter un point de contact"
			{...props}
		>
			<Form.Item
				name="type"
				rules={[{
					message: 'Veuillez renseigner le type du point de contact',
					required: true,
				}]}
			>
				<Radio.Group
					onChange={v => setContactType(v.target.value)}
					size="large"
				>
					<Row gutter={20}>
						{taskZoneContactTypeCollection.map(contactType => (
							<Col key={contactType.iri} lg={12} md={12} xs={24}>
								<Radio.Button
									style={{ height: 'auto', padding: 20 }}
									value={contactType.iri}
								>
									<b>{contactType.label}</b>
									<div style={{ fontSize: 14, lineHeight: '18px' }}>
										{contactType.description}
									</div>
								</Radio.Button>
							</Col>
						))}
					</Row>
				</Radio.Group>
			</Form.Item>

			{contactType && (
				<>
					<Form.Item
						label="Veuillez spécifier le service"
						name="operationCategory"
						rules={[{
							message: 'Veuillez renseigner le service',
							required: !isBuying,
						}]}
					>
						<Select
							disabled={isBuying}
							onChange={v => setOperationCategory(v)}
							optionFilterProp="children"
							placeholder="Service"
							showSearch={true}
						>
							{functionsStore.operationCategoryCollection.map(operationCategory => (
								<Select.Option key={operationCategory.id} value={operationCategory.iri}>
									{operationCategory.label}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item
						label="Contact pré-enregistré"
						name="contact"
						rules={[{
							message: 'Veuillez renseigner un contact',
							required: true,
						}]}
					>
						<Select
							placeholder="Contact pré-enregistré"
						>
							{appStore.contactCollection.map(contact => {
								const isDisabled = !!zoneContactCollection.find(zoneContact => {
									return (
										zoneContact.contactTypeIri === contactType
										&& zoneContact.contactId === contact.id
										&& (operationCategory ? zoneContact.operationCategoryIri === operationCategory : true)
									);
								});

								return (
									<Select.Option
										disabled={isDisabled}
										key={contact.id}
										value={contact.urn}
									>
										{contact.fullName}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>

					<Button onClick={() => setModalVisible(true)} type="default">
						Nouveau contact
					</Button>

					{/*Ajouter un contact*/}
					<ContactModalForm
						onCancel={() => setModalVisible(false)}
						onSubmit={async (values) => {
							const contact = new ContactModel();

							await contact.set({
								...values,
								clientUrn: appStore.selectedClient?.urn,
							}).save();

							appStore.contactCollection.push(contact);

							modalFormRef?.current?.setFieldsValue({ contact: contact.iri });

							notification.success({
								description: 'Le contact a bien été ajouté à l\'entreprise',
								message: 'Contact ajouté',
							});

							suggestCreateUser(
								() => {
									// Ouverture du formulaire utilisateur
									setCurrentContact(contact);
									setAddContactAccessVisible(true);
								},
							);

							setModalVisible(false);
						}}
						open={modalVisible}
					/>

					{/*Ajouter un utilisateur*/}
					<ContactAccessModalForm
						formProps={{
							initialValues: {
								email: currentContact?.email,
							},
						}}
						onCancel={() => setAddContactAccessVisible(false)}
						onSubmit={async (values) => {
							if (currentContact) {
								await currentContact.patch({
									...values,
									enabled: true,
								});

								notification.success({ message: `Accès à l'interface enregistrés` });
							}

							setAddContactAccessVisible(false);
						}}
						open={addContactAccessVisible}
					/>
				</>
			)}
		</ModalForm>
	);
};

export default observer(TaskZoneContactModalForm);
