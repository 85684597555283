import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class ReportExportZipModel extends InterventionPrivateApiModel {
	public filters: ModelFiltersExtended<{
		'reportInterventions.intervention': id;
	}> = {};

	static get path(): string {
		return '/reports/export/zip';
	}
}
