import { EllipsisOutlined }                                                      from '@ant-design/icons';
import Button                                                                    from 'antd/lib/button';
import Dropdown                                                                  from 'antd/lib/dropdown';
import { Page }                                                                  from 'components/Page';
import useTo                                                                     from 'hooks/useTo';
import { observer }                                                              from 'mobx-react';
import { useNavigate }                                                           from 'react-router-dom';
import React                                                                     from 'react';
import { appStore }                                                              from 'stores/index';
import { interventionsStore }                                                    from 'stores/index';
import AbstractInterventionList                                                  from '../../../entityComponents/Intervention/AbstractInterventionList';
import InterventionModel                                                         from '../../../modelx/models/private/intervention/InterventionModel';
import InterventionListCompletedPageBoxMenu                                      from './InterventionListCompletedPageBoxMenu';

export default observer(function InterventionListCompletedPage() {
	const navigate = useNavigate();
	return (
		<Page title="Interventions effectuées">
			<AbstractInterventionList
				cardProps={{
					extra: <BoxDropdownMenu />,
					title: 'Interventions effectuées',
				}}
				collection={interventionsStore.completedInterventionCollection}
				customColumns={(defaultColumns => [
					...defaultColumns,
					{
						align: 'right',
						fixed: 'right',
						render: (v, i: InterventionModel) => {
							return (
								<Dropdown menu={interventionMenu({ intervention: i, navigate })} trigger={['click']}>
									<Button style={{ border: 'none', padding: 0 }}>
										<EllipsisOutlined style={{ fontSize: 20 }} />
									</Button>
								</Dropdown>
							);
						},
						title: '',
					}
				])}
			/>
		</Page>
	);
});

const BoxDropdownMenu = observer(() => {
	return (
		<Dropdown
			menu={InterventionListCompletedPageBoxMenu()}
			trigger={['click']}
		>
			<Button style={{ padding: '0 8px' }} type="primary">
				<EllipsisOutlined style={{ fontSize: 20, verticalAlign: 'top', }} />
			</Button>
		</Dropdown>
	);
});

const interventionMenu = ({
	intervention,
	navigate,
}: {
	intervention: InterventionModel,
	navigate: (to: string) => void,
}) => {
	const to = useTo();
	return {
		items: [
			{
				key: 'files',
				label: 'Consulter les documents',
				onClick: () => {
					appStore.setInterventionUrnFilter(intervention.urn);
					navigate(to('/files'));
				},
			},
		],
	};
};
