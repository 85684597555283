import moment from 'moment';

export type GroupItem = { data: unknown, exp: string };

export default class GroupStorage {
	private readonly _groupKey: string = '';
	private readonly _item: Record<string, GroupItem> = {};
	private readonly _storage: typeof localStorage | typeof sessionStorage;

	public constructor(groupKey: string, storageType: 'local' | 'session' = 'session') {
		this._groupKey = groupKey;

		this._storage = storageType === 'local' ? localStorage : sessionStorage;

		this._item = JSON.parse(this._storage.getItem(groupKey) || '{}');

		this.clean().save();
	}

	public clean() {
		Object.keys(this._item).filter(this.isExpired.bind(this)).forEach(this.removeItem.bind(this));

		return this;
	}

	public destroy() {
		Object.keys(this._item).forEach(this.removeItem.bind(this));

		return this._storage.removeItem(this._groupKey);
	}

	public getData(key: string) {
		return this.getItem(key)?.data;
	}

	public getItem(key: string): GroupItem | undefined {
		if (this.isExpired(key)) {
			this.removeItem(key);

			return undefined;
		}

		return this._item[key];
	}

	public isExpired(key: string) {
		return this._item[key] && !moment(new Date(this._item[key].exp)).isAfter();
	}

	public removeItem(key: string) {
		delete this._item[key];

		return this;
	}

	public save() {
		try {
			this._storage.setItem(this._groupKey, JSON.stringify(this._item));
		} catch (err) {
			if (err instanceof Error) {
				console.error(err.message);
			}
		}

		return this;
	}

	public setItem(key: string, data: unknown, duration?: number) {
		const exp = (duration ? moment().add(duration, 'seconds') : moment().add(100, 'years')).toString();

		this._item[key] = { data, exp };

		return this;
	}
}