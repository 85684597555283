import { CheckOutlined }                                     from '@ant-design/icons/lib';

import { PageHeader }                                        from '@ant-design/pro-layout';
import ContractIterationStatusHistoryModel                   from 'Models/sales/ContractIterationStatusHistoryModel';
import Button                                                from 'antd/lib/button';
import Col                                                   from 'antd/lib/col';
import Descriptions                                          from 'antd/lib/descriptions';
import Modal                                                 from 'antd/lib/modal';
import Row                                                   from 'antd/lib/row';
import Spin                                                  from 'antd/lib/spin';
import {
	CONTRACT_ITERATION_STATUS_SENT_TO_CLIENT,
	CONTRACT_ITERATION_STATUS_VALIDATED_BY_CLIENT,
}                                                           from 'constants/ContractIterationStatuses';
import FileListBox                                           from 'entityComponents/File/FileListBox';
import useTo                                                 from 'hooks/useTo';
import { observer }                                          from 'mobx-react';
import moment                                                from 'moment';
import { useNavigate, useParams }                            from 'react-router-dom';
import React, { useEffect, useState }                        from 'react';
import { appStore, contractDashboardStore, contractsStore }  from 'stores/index';
import { formatPrice }                                       from 'tools/PriceHelper';
import { notificationError, notificationSuccess }            from 'tools/notification';
import HttpResult                                            from '../../../components/HttpResult';
import ContractActorCommercial                               from '../../../entityComponents/ContractActor/ContractActorCommercial';
import notificationApiError                                  from '../../../utils/notificationApiError';
import ContractIterationItemBox                              from './ContractIterationItemBox';
import DropdownMenu                                          from './DropdownMenu';
import '../css/ContractDashboard.scss';

interface ContractDashboardProps {
	backPath: string;
	children?: React.ReactNode;
	onReady: (id: number) => Promise<unknown>;
	title: string;
}

const ContractDashboard: React.FC<ContractDashboardProps> = props => {
	const { id } = useParams<{ id?: string | undefined; }>();
	const navigate = useNavigate();
	const [error, setError] = useState(undefined);

	const contractId = id ? parseInt(id) : 0;

	const to = useTo();

	const {
		backPath,
		children,
		onReady,
		title,
	} = props;

	const {
		contractIterationStatusCollection,
	} = appStore;

	const {
		contract,
		contractActorCollection,
		contractIteration,
		contractIterationFileCollection,
		staffCollection,
		staffMemberCollection,
	} = contractDashboardStore;

	useEffect(() => {
		onReady(contractId).catch(e => setError(e));
	}, [id, onReady]);

	const isLoading = (
		contract.isLoading
		|| contractActorCollection.isLoading
		|| contractIteration.isLoading
		|| contractIterationFileCollection.isLoading
		|| staffCollection.isLoading
		|| staffMemberCollection.isLoading
	);

	const contractIterationStatus = contractIterationStatusCollection.find(contractIterationStatus => contractIterationStatus.id === contractIteration.contractIterationStatusId);

	if (!contractIterationStatus) {
		return null;
	}

	return (
		<HttpResult error={error}>
			<div className="dashboard contract-dashboard">
				{isLoading ? <Spin /> : (
					<>
						<PageHeader
							extra={
								<>
									{(contractIterationStatus.reference === CONTRACT_ITERATION_STATUS_SENT_TO_CLIENT.reference) && (
										<Button
											onClick={() => {
												Modal.confirm({
													cancelText: `Annuler`,
													// className: 'qhs-confirm-modal',
													maskClosable: true,
													okText: `Valider`,
													onOk: () => {
														const contractIterationStatusValidatedByClient = contractIterationStatusCollection.find(contractIterationStatus => contractIterationStatus.reference === CONTRACT_ITERATION_STATUS_VALIDATED_BY_CLIENT.reference);
														if (!contractIterationStatusValidatedByClient) {
															notificationError({ message: 'Une erreur s\'est produite', });
															return;
														}
														const contractIterationStatusHistory = new ContractIterationStatusHistoryModel({
															contractIteration: contractIteration.iri,
															contractIterationStatus: contractIterationStatusValidatedByClient.iri,
														});
														contractIterationStatusHistory
															.save()
															.then(() => {
																notificationSuccess({ message: 'Validation réussie !', });
																contractDashboardStore.fetch(contractIteration.id);
																contractsStore.fetch();
															})
															.catch(notificationApiError);
													},
													title: `Valider le contrat ?`,
													width: 512,
												});
											}}
										>
											<CheckOutlined />
										</Button>
									)}
									<DropdownMenu
										contractIterationFileCollection={contractIterationFileCollection}
									/>
								</>
							}
							onBack={() => navigate(to(backPath), { replace: true })}
							title={`${title} ${contract.number}`}
						>
							<Descriptions column={3} size="small">
								<Descriptions.Item label="État">
									{contractIterationStatus && contractIterationStatus.label}
								</Descriptions.Item>

								<Descriptions.Item label="Titre">
									{contractIteration.title}
								</Descriptions.Item>


								<Descriptions.Item label="Dates">
									du&nbsp;
									{moment(contractIteration.startDate).format('L')}
									&nbsp;au&nbsp;
									{moment(contractIteration.endDate).format('L')}
								</Descriptions.Item>

								<Descriptions.Item label="Montant total HT">
									{formatPrice(contractIteration.totalPriceExclTax)}
								</Descriptions.Item>

								<Descriptions.Item label="Montant total TTC">
									{formatPrice(contractIteration.totalPriceInclTax)}
								</Descriptions.Item>

								<Descriptions.Item label="Commercial">
									<ContractActorCommercial
										contractActorCommercialCollection={contractActorCollection}
										staffCollection={staffCollection}
										staffMemberCollection={staffMemberCollection}
									/>
								</Descriptions.Item>

								<Descriptions.Item label="Durée">
									{contractIteration.duration} mois
								</Descriptions.Item>
							</Descriptions>
						</PageHeader>

						<Row gutter={20}>
							<Col md={24} xs={24}>
								<ContractIterationItemBox />
							</Col>

							<Col md={12} xs={24}>
								<FileListBox
									references={[
										'contract_iteration_document_for_client',
									]}
									servicesUrns={[]}
									sourcesUrns={[
										contractIteration.urn,
									]}
								/>
							</Col>

							{children}
						</Row>
					</>
				)}
			</div>
		</HttpResult>
	);
};

export default observer(ContractDashboard);
