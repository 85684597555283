export const CONTRACT_ACTOR_GROUP_COMMERCIAL = {
	label: 'Commercial',
	reference: 'CONTRACT_ACTOR_GROUP_COMMERCIAL',
};

export const CONTRACT_ACTOR_GROUP_BUYING = {
	label: 'Contact Achat',
	reference: 'CONTRACT_ACTOR_GROUP_BUYING',
};

export default {
	CONTRACT_ACTOR_GROUP_BUYING,
	CONTRACT_ACTOR_GROUP_COMMERCIAL,
};
