import { DownOutlined }                  from '@ant-design/icons';
import Dropdown                          from 'antd/lib/dropdown';
import { observer }                      from 'mobx-react';
import { Link }                          from 'react-router-dom';
import React                             from 'react';
import { appStore, authenticationStore } from '../stores';
import './css/HeaderAccountMenu.scss';

function HeaderAccountMenu() {
	return (
		<>
			<div className="header-account-menu">
				<Dropdown
					menu={{
						items: [
							{
								key: 'account',
								label: <Link to="/my-account">Mon compte</Link>,
							},
							{
								type: 'divider',
							},
							{
								key: 'logout',
								label: 'Déconnexion',
								onClick: () => authenticationStore.logout()
							}
						]
					}}
					trigger={['click']}
				>
					<div>
						<span>
							{authenticationStore.isImpersonate ? (
								<div>
									{`Connecté en tant que ${appStore.globalContact.fullName}`}
								</div>
							) : (
								<div>
									{appStore.globalContact.fullName}
								</div>
							)}
						</span>
						<DownOutlined />
					</div>
				</Dropdown>
			</div>
		</>
	);
}

export default observer(HeaderAccountMenu);