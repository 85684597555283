import { ApiCollection }                              from 'Collections/ApiCollection';
import FileModel                                      from 'Models/file/FileModel';
import FileSourceModel                                from 'Models/file/FileSourceModel';
import FileTypeSourceModel                            from 'Models/file/FileTypeSourceModel';
import InterventionOperationModel                     from 'Models/intervention/InterventionOperationModel';
import OperationCategoryModel                         from 'Models/intervention/OperationCategoryModel';
import OperationModel                                 from 'Models/intervention/OperationModel';
import OwnerResourceModel                             from 'Models/intervention/OwnerResourceModel';
import ReportInterventionModel                        from 'Models/intervention/ReportInterventionModel';
import ResourceModel                                  from 'Models/intervention/ResourceModel';
import TaskOperationModel                             from 'Models/intervention/TaskOperationModel';
import { INTERVENTION_STATUS_IN_PROGRESS }            from 'constants/InterventionStatuses';
import { INTERVENTION_STATUS_SCHEDULED }              from 'constants/InterventionStatuses';
import { INTERVENTION_STATUS_VALIDATED }              from 'constants/InterventionStatuses';
import { INTERVENTION_STATUS_WAITING_PRE_VALIDATION } from 'constants/InterventionStatuses';
import { INTERVENTION_STATUS_POINTED }                from 'constants/InterventionStatuses';
import { INTERVENTION_STATUS_DONE }                   from 'constants/InterventionStatuses';
import { INTERVENTION_STATUS_PRE_VALIDATED }          from 'constants/InterventionStatuses';
import { RESOURCE_TYPE_TECHNICIAN }                   from 'constants/ResourceTypes';
import moment                                         from 'moment';
import InterventionModel                              from '../modelx/models/private/intervention/InterventionModel';
import InterventionResourceModel                      from '../modelx/models/private/intervention/InterventionResourceModel';
import TaskZoneModel                                  from '../modelx/models/private/intervention/TaskZoneModel';
import StaffMemberModel                               from '../modelx/models/private/rh/StaffMemberModel';
import StaffModel                                     from '../modelx/models/private/rh/StaffModel';

export default class InterventionsStore implements Store {

	public completedInterventionCollection = new ApiCollection(InterventionModel);
	public completedInterventionReportCertificateReportFileCollection = new ApiCollection(FileModel);
	public completedInterventionReportInterventionCollection = new ApiCollection(ReportInterventionModel);
	public completedInterventionReportReportReportFileCollection = new ApiCollection(FileModel);
	public completedInterventionReportSheetReportFileCollection = new ApiCollection(FileModel);
	public interventionFileCollection = new ApiCollection(FileModel);
	public interventionOperationCollection = new ApiCollection(InterventionOperationModel);
	public interventionResourceCollection = new ApiCollection(InterventionResourceModel);
	public nextInterventionCollection = new ApiCollection(InterventionModel);
	public operationCategoryCollection = new ApiCollection(OperationCategoryModel);
	public operationCollection = new ApiCollection(OperationModel);
	public ownerResourceCollection = new ApiCollection(OwnerResourceModel);
	public reportInterventionFileSourceCollection = new ApiCollection(FileSourceModel);
	public reportInterventionFileTypeSourceCollection = new ApiCollection(FileTypeSourceModel);
	public resourceCollection = new ApiCollection(ResourceModel);
	public staffCollection = new ApiCollection(StaffModel);
	public staffMemberCollection = new ApiCollection(StaffMemberModel);
	public taskOperationCollection = new ApiCollection(TaskOperationModel);

	private _contractUrn = '';
	private _taskZoneIri = '';
	private _taskZoneUrn = '';

	public clear() {
		this.nextInterventionCollection.clear();
		this.completedInterventionCollection.clear();
	}

	public fetch = async () => {
		
		this.nextInterventionCollection
			.setSort('startDate', false)
			.setFilters({
				'interventionOperations.taskOperation.task.taskMetas.value': this._contractUrn,
				'interventionStatus.reference': [
					INTERVENTION_STATUS_DONE.reference,
					INTERVENTION_STATUS_IN_PROGRESS.reference,
					INTERVENTION_STATUS_PRE_VALIDATED.reference,
					INTERVENTION_STATUS_SCHEDULED.reference,
					INTERVENTION_STATUS_VALIDATED.reference,
					INTERVENTION_STATUS_WAITING_PRE_VALIDATION.reference,
				],
				'scheduleStartDate[after]': moment().format('YYYY-MM-DD'),
				taskZone: this._taskZoneIri,
			});

		this.completedInterventionCollection

			.setSort('startDate', false)
			.setFilters({
				'interventionOperations.taskOperation.task.taskMetas.value': this._contractUrn,
				'interventionStatus.reference': [
					INTERVENTION_STATUS_POINTED.reference,
				],
				taskZone: this._taskZoneIri,
			});

		await Promise.all([
			this.nextInterventionCollection.list(),

			this.completedInterventionCollection.list()
		]);

		const completedInterventionsIds = this.completedInterventionCollection.distinctDefinedKey('id');

		await this.completedInterventionReportInterventionCollection
			.setFilter('intervention', completedInterventionsIds)
			.list();

		const completedInterventionReportUrns = this.completedInterventionReportInterventionCollection.distinctKey('reportUrn');

		try {
			await Promise.all([
				this.completedInterventionReportCertificateReportFileCollection
					.setFilters({
						'typeSource.source.entityUrn': completedInterventionReportUrns,
						'typeSource.type.reference': 'report_certificate',
					})
					.list(),

				this.completedInterventionReportReportReportFileCollection
					.setFilters({
						'typeSource.source.entityUrn': completedInterventionReportUrns,
						'typeSource.type.reference': 'report_report',
					})
					.list(),

				this.completedInterventionReportSheetReportFileCollection
					.setFilters({
						'typeSource.source.entityUrn': completedInterventionReportUrns,
						'typeSource.type.reference': 'report_sheet',
					})
					.list(),

				// this.reportInterventionFileTypeSourceCollection
				// 	.setFilter('source.urn', completedInterventionReportUrns)
				// 	.list(),
				//
				// this.reportInterventionFileSourceCollection
				// 	.setFilter('entityUrn', completedInterventionReportUrns)
				// 	.list(),
			]);
		} catch (error) {
			console.warn(error);
		}

		const typeSourcesIds: number[] = [];
		this.completedInterventionReportCertificateReportFileCollection.forEach(file => {
			typeSourcesIds.push(file.typeSourceId);
		});
		this.completedInterventionReportReportReportFileCollection.forEach(file => {
			typeSourcesIds.push(file.typeSourceId);
		});
		this.completedInterventionReportSheetReportFileCollection.forEach(file => {
			typeSourcesIds.push(file.typeSourceId);
		});

		await this.reportInterventionFileTypeSourceCollection
			.setFilter('id', typeSourcesIds)
			.list();

		const sourcesIds = this.reportInterventionFileTypeSourceCollection.map(typeSource => typeSource.sourceId);

		await this.reportInterventionFileSourceCollection
			.setFilter('id', sourcesIds)
			.list();

		const interventionsIds: id[] = [];

		this.nextInterventionCollection.forEach(intervention => {
			interventionsIds.push(intervention.id);
		});

		this.completedInterventionCollection.forEach(intervention => {
			interventionsIds.push(intervention.id);
		});

		if (!interventionsIds.length) {
			return;
		}

		this.interventionResourceCollection
			.setFilters({
				intervention: interventionsIds,
				'resource.resourceType.reference': RESOURCE_TYPE_TECHNICIAN,
			})
			.list()
			.then(async () => {
				await this.resourceCollection.listByFromCollection(this.interventionResourceCollection, 'resourceId', 'id');
				await this.ownerResourceCollection.listByFromCollection(this.resourceCollection, 'ownerResourceId', 'id');
				await this.staffMemberCollection.listByFromCollection(this.ownerResourceCollection, 'entityId', 'id');
				await this.staffCollection.listByFromCollection(this.staffMemberCollection, 'staffId', 'id');
			});

		this.interventionOperationCollection
			.setFilters({
				intervention: interventionsIds,
			})
			.list()
			.then(async () => {
				await this.taskOperationCollection.listByFromCollection(this.interventionOperationCollection, 'taskOperationId', 'id');
				await this.operationCollection.listByFromCollection(this.taskOperationCollection, 'operationId', 'id');
				await this.operationCategoryCollection.listByFromCollection(this.operationCollection, 'operationCategoryId', 'id');
			});
	};

	public getReportCertificates = (reportInterventions: ReportInterventionModel[]) => {
		return this.getReportFiles(reportInterventions, this.completedInterventionReportCertificateReportFileCollection.models);
	};

	public getReportFiles = (reportInterventions: ReportInterventionModel[], files: FileModel[]) => {
		const reportsUrns = reportInterventions.map(ri => ri.reportUrn);

		const sources = this.reportInterventionFileSourceCollection.models.filter(
			(source: FileSourceModel) => reportsUrns.find(ru => ru === source.entityUrn),
		);

		const typeSources = this.reportInterventionFileTypeSourceCollection.models.filter(
			(typeSource: FileTypeSourceModel) => sources.find(s => s.id === typeSource.sourceId),
		);

		return files.filter(
			(file: FileModel) => typeSources.find(ts => ts.id === file.typeSourceId),
		);
	};

	public getReportReports = (reportInterventions: ReportInterventionModel[]) => {
		return this.getReportFiles(reportInterventions, this.completedInterventionReportReportReportFileCollection.models);
	};

	public getReportSheets = (reportInterventions: ReportInterventionModel[]) => {
		return this.getReportFiles(reportInterventions, this.completedInterventionReportSheetReportFileCollection.models);
	};

	public setTaskZoneFilterAndFetch(taskZone: TaskZoneModel) {
		this._taskZoneUrn = taskZone.urn;
		this._taskZoneIri = taskZone.iri;

		if (this._contractUrn) {
			return this.fetch();
		}
	}

	public setUrnFilterAndFetch(urn: string) {
		this._contractUrn = urn;

		if (this._taskZoneUrn) {
			return this.fetch();
		}
	}
}
