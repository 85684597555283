import { jwtDecode }     from 'jwt-decode';
import { computed }      from 'mobx';
import { getIdFromUrn }  from 'tools/UrnTools';
import LocalStorageModel from './abstracts/LocalStorageModel';

export default class SessionModel extends LocalStorageModel {

	static get path(): string {
		return '/session';
	}

	@computed
	public get tokenData(): TokenData {
		return jwtDecode(this.token) || {};
	}

	@computed
	public get token(): string {
		return this.get('token', '');
	}

	@computed
	public get refreshToken(): string {
		return this.get('refreshToken', '');
	}

	@computed
	public get exp(): number {
		return this.tokenData.exp;
	}

	@computed
	public get ownerId(): number {
		return parseInt(getIdFromUrn(this.ownerUrn as string));
	}

	@computed
	public get ownerUrn(): string {
		return this.tokenData['owner.urn'] as string;
	}

	@computed
	public get isContact(): boolean {
		return this.ownerUrn.includes('$:directory:contact:');
	}

	@computed
	public get isGlobalContact(): boolean {
		return this.ownerUrn.includes('$:directory:global_contact:');
	}

	@computed
	public get isStaffMember(): boolean {
		return this.ownerUrn.includes('$:rh:staff_member:');
	}

	@computed
	public get impersonateId(): number {
		return parseInt(getIdFromUrn(this.impersonateUrn as string));
	}

	@computed
	public get impersonateUrn(): string {
		return this.tokenData['impersonate.urn'] as string;
	}

	@computed
	public get parentTokenData(): TokenData {
		return jwtDecode(this.parentToken) || {};
	}

	@computed
	public get parentToken(): string {
		return this.get('parentToken.token', '');
	}

	@computed
	public get parentRefreshToken(): string {
		return this.get('parentToken.refreshToken', '');
	}

	@computed
	public get parentExp(): number {
		return this.parentTokenData.exp;
	}
}
