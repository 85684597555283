import Spin                 from 'antd/lib/spin';
import { Page }             from 'components/Page';
import { observer }         from 'mobx-react';
import React, { useEffect } from 'react';
import { functionsStore }   from 'stores/index';
import ClientSection        from './ClientSection';
import TaskZoneSection      from './TaskZoneSection';
import '../css/FunctionsListPage.scss';

function FunctionsListPage() {
	useEffect(() => {
		// if (!appStore.serviceCollection.isLoaded) {
		//     appStore.serviceCollection.list();
		// }
		//
		// if (!appStore.contactCollection.isLoaded) {
		//     appStore.contactCollection.list();
		// }
		//
		// contactsStore.fetch();
	}, []);

	const {
		clientContactCollection,
		invoiceContactTypeCollection,
		invoicingContactCollection,
		salesContactCollection,
		salesContactTypeCollection,
		taskZoneContactCollection,
		taskZoneContactTypeCollection,
		zoneContactCollection,
	} = functionsStore;

	const isLoading = (
		clientContactCollection.isLoading ||
		invoiceContactTypeCollection.isLoading ||
		invoicingContactCollection.isLoading ||
		salesContactCollection.isLoading ||
		salesContactTypeCollection.isLoading ||
		taskZoneContactCollection.isLoading ||
		taskZoneContactTypeCollection.isLoading ||
		zoneContactCollection.isLoading
	);

	if (isLoading) {
		return (
			<Spin
				size="large"
				style={{
					alignItems: 'center',
					display: 'flex',
					height: '100%',
					justifyContent: 'center',
				}}
			/>
		);
	}

	return (
		<Page title="Contacts du site">
			<div className="functions-list-page">
				{/*CLIENT*/}
				<ClientSection
					// title={`Contacts de l'entreprise ${divisionsStore.selectedCompany?.title}`}
					// dataPoints={companyDataPoints.get()}
					// division={divisionsStore.selectedCompany}
				/>

				{/*/!*SUB_COMPANY*!/*/}
				{/*<Section*/}
				{/*    title={`Contacts de la sous-entreprise ${divisionsStore.selectedSubCompany?.title}`}*/}
				{/*    dataPoints={subCompanyDataPoints.get()}*/}
				{/*    division={divisionsStore.selectedSubCompany}*/}
				{/*/>*/}

				{/*TASK ZONE*/}
				<TaskZoneSection
					// title={`Contacts du site ${divisionsStore.selectedSite?.title}`}
					// dataPoints={siteDataPoints.get()}
					// division={divisionsStore.selectedSite}
				/>

				{/*/!*ZONE*!/*/}
				{/*<Section*/}
				{/*    title={`Contacts de la zone ${divisionsStore.selectedZone?.title}`}*/}
				{/*    dataPoints={zoneDataPoints.get()}*/}
				{/*    division={divisionsStore.selectedZone}*/}
				{/*/>*/}
			</div>
		</Page>
	);
}

export default observer(FunctionsListPage);
