import { computed }      from 'mobx';
import DirectoryApiModel from '../../abstracts/DirectoryApiModel';

export default class TokenRefreshModel extends DirectoryApiModel {
	static get path(): string {
		return '/token/refresh';
	}

	@computed
	public get token() {
		return this.get('token', '');
	}

	@computed
	public get refreshToken() {
		return this.get('refresh_token', '');
	}
}
