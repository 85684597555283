import { computed } from 'mobx';
import RhApiModel   from '../../abstracts/RhApiModel';

export default class TokenRefreshModel extends RhApiModel {
	static get path(): string {
		return '/login_check';
	}

	@computed
	public get token(): string {
		return this.get('token', '');
	}

	@computed
	public get refreshToken(): string {
		return this.get('refresh_token', '');
	}
}
