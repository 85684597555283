import { DownloadOutlined, EllipsisOutlined } from '@ant-design/icons';
import InvoiceExportZipModel                  from 'Models/invoice/InvoiceExportZipModel';
import Button                                 from 'antd/lib/button';
import Dropdown                               from 'antd/lib/dropdown';
import { observer }                           from 'mobx-react';
import React                                  from 'react';
import { invoicesStore }                      from 'stores/index';
import notificationApiError                   from 'tools/notificationApiError';
import { notificationSuccess }                from 'tools/notification';

const BoxDropdownMenu = () => {
	return (
		<Dropdown
			menu={{
				items: [
					{
						disabled: !invoicesStore.invoiceCollection.length,
						key: 'download',
						label: <><DownloadOutlined /> Télécharger toutes les factures</>,
						onClick: () => {
							const model = new InvoiceExportZipModel();

							const invoicesIds = invoicesStore.invoiceCollection.map(i => i.id);

							if (invoicesIds.length) {
								model
									.fetch({ params: { 'id': invoicesIds } })
									.then(() => {
										notificationSuccess({
											message: `L'export est en cours de génération. 
													Vous allez recevoir un email avec le lien de téléchargement`,
										});
									})
									.catch(err => notificationApiError(err));
							}
						},
					},
				]
			}}
			trigger={['click']}
		>
			<Button
				style={{ padding: '0 8px' }}
				type="primary"
			>
				<EllipsisOutlined
					style={{ fontSize: 20, verticalAlign: 'top' }}
				/>
			</Button>
		</Dropdown>
	);
};

export default observer(BoxDropdownMenu);
