/*eslint-disable */
import $protobuf from "protobufjs/minimal";

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.Service = (function() {

    /**
     * Namespace Service.
     * @exports Service
     * @namespace
     */
    var Service = {};

    Service.Intervention = (function() {

        /**
         * Namespace Intervention.
         * @memberof Service
         * @namespace
         */
        var Intervention = {};

        Intervention.InterventionNotificationValidationData = (function() {

            /**
             * Properties of an InterventionNotificationValidationData.
             * @memberof Service.Intervention
             * @interface IInterventionNotificationValidationData
             * @property {Service.Intervention.InterventionNotificationValidationData.IIntervention|null} [intervention] InterventionNotificationValidationData intervention
             * @property {Service.Intervention.InterventionNotificationValidationData.ITaskZone|null} [taskZone] InterventionNotificationValidationData taskZone
             * @property {Array.<Service.Intervention.InterventionNotificationValidationData.IOperation>|null} [operations] InterventionNotificationValidationData operations
             * @property {Array.<Service.Intervention.InterventionNotificationValidationData.ITechnician>|null} [technicians] InterventionNotificationValidationData technicians
             * @property {Array.<Service.Intervention.InterventionNotificationValidationData.IVehicle>|null} [vehicles] InterventionNotificationValidationData vehicles
             */

            /**
             * Constructs a new InterventionNotificationValidationData.
             * @memberof Service.Intervention
             * @classdesc Represents an InterventionNotificationValidationData.
             * @implements IInterventionNotificationValidationData
             * @constructor
             * @param {Service.Intervention.IInterventionNotificationValidationData=} [properties] Properties to set
             */
            function InterventionNotificationValidationData(properties) {
                this.operations = [];
                this.technicians = [];
                this.vehicles = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * InterventionNotificationValidationData intervention.
             * @member {Service.Intervention.InterventionNotificationValidationData.IIntervention|null|undefined} intervention
             * @memberof Service.Intervention.InterventionNotificationValidationData
             * @instance
             */
            InterventionNotificationValidationData.prototype.intervention = null;

            /**
             * InterventionNotificationValidationData taskZone.
             * @member {Service.Intervention.InterventionNotificationValidationData.ITaskZone|null|undefined} taskZone
             * @memberof Service.Intervention.InterventionNotificationValidationData
             * @instance
             */
            InterventionNotificationValidationData.prototype.taskZone = null;

            /**
             * InterventionNotificationValidationData operations.
             * @member {Array.<Service.Intervention.InterventionNotificationValidationData.IOperation>} operations
             * @memberof Service.Intervention.InterventionNotificationValidationData
             * @instance
             */
            InterventionNotificationValidationData.prototype.operations = $util.emptyArray;

            /**
             * InterventionNotificationValidationData technicians.
             * @member {Array.<Service.Intervention.InterventionNotificationValidationData.ITechnician>} technicians
             * @memberof Service.Intervention.InterventionNotificationValidationData
             * @instance
             */
            InterventionNotificationValidationData.prototype.technicians = $util.emptyArray;

            /**
             * InterventionNotificationValidationData vehicles.
             * @member {Array.<Service.Intervention.InterventionNotificationValidationData.IVehicle>} vehicles
             * @memberof Service.Intervention.InterventionNotificationValidationData
             * @instance
             */
            InterventionNotificationValidationData.prototype.vehicles = $util.emptyArray;

            /**
             * Creates a new InterventionNotificationValidationData instance using the specified properties.
             * @function create
             * @memberof Service.Intervention.InterventionNotificationValidationData
             * @static
             * @param {Service.Intervention.IInterventionNotificationValidationData=} [properties] Properties to set
             * @returns {Service.Intervention.InterventionNotificationValidationData} InterventionNotificationValidationData instance
             */
            InterventionNotificationValidationData.create = function create(properties) {
                return new InterventionNotificationValidationData(properties);
            };

            /**
             * Encodes the specified InterventionNotificationValidationData message. Does not implicitly {@link Service.Intervention.InterventionNotificationValidationData.verify|verify} messages.
             * @function encode
             * @memberof Service.Intervention.InterventionNotificationValidationData
             * @static
             * @param {Service.Intervention.IInterventionNotificationValidationData} message InterventionNotificationValidationData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InterventionNotificationValidationData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.intervention != null && Object.hasOwnProperty.call(message, "intervention"))
                    $root.Service.Intervention.InterventionNotificationValidationData.Intervention.encode(message.intervention, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.taskZone != null && Object.hasOwnProperty.call(message, "taskZone"))
                    $root.Service.Intervention.InterventionNotificationValidationData.TaskZone.encode(message.taskZone, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.operations != null && message.operations.length)
                    for (var i = 0; i < message.operations.length; ++i)
                        $root.Service.Intervention.InterventionNotificationValidationData.Operation.encode(message.operations[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.technicians != null && message.technicians.length)
                    for (var i = 0; i < message.technicians.length; ++i)
                        $root.Service.Intervention.InterventionNotificationValidationData.Technician.encode(message.technicians[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.vehicles != null && message.vehicles.length)
                    for (var i = 0; i < message.vehicles.length; ++i)
                        $root.Service.Intervention.InterventionNotificationValidationData.Vehicle.encode(message.vehicles[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified InterventionNotificationValidationData message, length delimited. Does not implicitly {@link Service.Intervention.InterventionNotificationValidationData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Service.Intervention.InterventionNotificationValidationData
             * @static
             * @param {Service.Intervention.IInterventionNotificationValidationData} message InterventionNotificationValidationData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InterventionNotificationValidationData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an InterventionNotificationValidationData message from the specified reader or buffer.
             * @function decode
             * @memberof Service.Intervention.InterventionNotificationValidationData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Service.Intervention.InterventionNotificationValidationData} InterventionNotificationValidationData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InterventionNotificationValidationData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.InterventionNotificationValidationData();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.intervention = $root.Service.Intervention.InterventionNotificationValidationData.Intervention.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.taskZone = $root.Service.Intervention.InterventionNotificationValidationData.TaskZone.decode(reader, reader.uint32());
                        break;
                    case 3:
                        if (!(message.operations && message.operations.length))
                            message.operations = [];
                        message.operations.push($root.Service.Intervention.InterventionNotificationValidationData.Operation.decode(reader, reader.uint32()));
                        break;
                    case 4:
                        if (!(message.technicians && message.technicians.length))
                            message.technicians = [];
                        message.technicians.push($root.Service.Intervention.InterventionNotificationValidationData.Technician.decode(reader, reader.uint32()));
                        break;
                    case 5:
                        if (!(message.vehicles && message.vehicles.length))
                            message.vehicles = [];
                        message.vehicles.push($root.Service.Intervention.InterventionNotificationValidationData.Vehicle.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an InterventionNotificationValidationData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Service.Intervention.InterventionNotificationValidationData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Service.Intervention.InterventionNotificationValidationData} InterventionNotificationValidationData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InterventionNotificationValidationData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an InterventionNotificationValidationData message.
             * @function verify
             * @memberof Service.Intervention.InterventionNotificationValidationData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            InterventionNotificationValidationData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.intervention != null && message.hasOwnProperty("intervention")) {
                    var error = $root.Service.Intervention.InterventionNotificationValidationData.Intervention.verify(message.intervention);
                    if (error)
                        return "intervention." + error;
                }
                if (message.taskZone != null && message.hasOwnProperty("taskZone")) {
                    var error = $root.Service.Intervention.InterventionNotificationValidationData.TaskZone.verify(message.taskZone);
                    if (error)
                        return "taskZone." + error;
                }
                if (message.operations != null && message.hasOwnProperty("operations")) {
                    if (!Array.isArray(message.operations))
                        return "operations: array expected";
                    for (var i = 0; i < message.operations.length; ++i) {
                        var error = $root.Service.Intervention.InterventionNotificationValidationData.Operation.verify(message.operations[i]);
                        if (error)
                            return "operations." + error;
                    }
                }
                if (message.technicians != null && message.hasOwnProperty("technicians")) {
                    if (!Array.isArray(message.technicians))
                        return "technicians: array expected";
                    for (var i = 0; i < message.technicians.length; ++i) {
                        var error = $root.Service.Intervention.InterventionNotificationValidationData.Technician.verify(message.technicians[i]);
                        if (error)
                            return "technicians." + error;
                    }
                }
                if (message.vehicles != null && message.hasOwnProperty("vehicles")) {
                    if (!Array.isArray(message.vehicles))
                        return "vehicles: array expected";
                    for (var i = 0; i < message.vehicles.length; ++i) {
                        var error = $root.Service.Intervention.InterventionNotificationValidationData.Vehicle.verify(message.vehicles[i]);
                        if (error)
                            return "vehicles." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an InterventionNotificationValidationData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Service.Intervention.InterventionNotificationValidationData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Service.Intervention.InterventionNotificationValidationData} InterventionNotificationValidationData
             */
            InterventionNotificationValidationData.fromObject = function fromObject(object) {
                if (object instanceof $root.Service.Intervention.InterventionNotificationValidationData)
                    return object;
                var message = new $root.Service.Intervention.InterventionNotificationValidationData();
                if (object.intervention != null) {
                    if (typeof object.intervention !== "object")
                        throw TypeError(".Service.Intervention.InterventionNotificationValidationData.intervention: object expected");
                    message.intervention = $root.Service.Intervention.InterventionNotificationValidationData.Intervention.fromObject(object.intervention);
                }
                if (object.taskZone != null) {
                    if (typeof object.taskZone !== "object")
                        throw TypeError(".Service.Intervention.InterventionNotificationValidationData.taskZone: object expected");
                    message.taskZone = $root.Service.Intervention.InterventionNotificationValidationData.TaskZone.fromObject(object.taskZone);
                }
                if (object.operations) {
                    if (!Array.isArray(object.operations))
                        throw TypeError(".Service.Intervention.InterventionNotificationValidationData.operations: array expected");
                    message.operations = [];
                    for (var i = 0; i < object.operations.length; ++i) {
                        if (typeof object.operations[i] !== "object")
                            throw TypeError(".Service.Intervention.InterventionNotificationValidationData.operations: object expected");
                        message.operations[i] = $root.Service.Intervention.InterventionNotificationValidationData.Operation.fromObject(object.operations[i]);
                    }
                }
                if (object.technicians) {
                    if (!Array.isArray(object.technicians))
                        throw TypeError(".Service.Intervention.InterventionNotificationValidationData.technicians: array expected");
                    message.technicians = [];
                    for (var i = 0; i < object.technicians.length; ++i) {
                        if (typeof object.technicians[i] !== "object")
                            throw TypeError(".Service.Intervention.InterventionNotificationValidationData.technicians: object expected");
                        message.technicians[i] = $root.Service.Intervention.InterventionNotificationValidationData.Technician.fromObject(object.technicians[i]);
                    }
                }
                if (object.vehicles) {
                    if (!Array.isArray(object.vehicles))
                        throw TypeError(".Service.Intervention.InterventionNotificationValidationData.vehicles: array expected");
                    message.vehicles = [];
                    for (var i = 0; i < object.vehicles.length; ++i) {
                        if (typeof object.vehicles[i] !== "object")
                            throw TypeError(".Service.Intervention.InterventionNotificationValidationData.vehicles: object expected");
                        message.vehicles[i] = $root.Service.Intervention.InterventionNotificationValidationData.Vehicle.fromObject(object.vehicles[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an InterventionNotificationValidationData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Service.Intervention.InterventionNotificationValidationData
             * @static
             * @param {Service.Intervention.InterventionNotificationValidationData} message InterventionNotificationValidationData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            InterventionNotificationValidationData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.operations = [];
                    object.technicians = [];
                    object.vehicles = [];
                }
                if (options.defaults) {
                    object.intervention = null;
                    object.taskZone = null;
                }
                if (message.intervention != null && message.hasOwnProperty("intervention"))
                    object.intervention = $root.Service.Intervention.InterventionNotificationValidationData.Intervention.toObject(message.intervention, options);
                if (message.taskZone != null && message.hasOwnProperty("taskZone"))
                    object.taskZone = $root.Service.Intervention.InterventionNotificationValidationData.TaskZone.toObject(message.taskZone, options);
                if (message.operations && message.operations.length) {
                    object.operations = [];
                    for (var j = 0; j < message.operations.length; ++j)
                        object.operations[j] = $root.Service.Intervention.InterventionNotificationValidationData.Operation.toObject(message.operations[j], options);
                }
                if (message.technicians && message.technicians.length) {
                    object.technicians = [];
                    for (var j = 0; j < message.technicians.length; ++j)
                        object.technicians[j] = $root.Service.Intervention.InterventionNotificationValidationData.Technician.toObject(message.technicians[j], options);
                }
                if (message.vehicles && message.vehicles.length) {
                    object.vehicles = [];
                    for (var j = 0; j < message.vehicles.length; ++j)
                        object.vehicles[j] = $root.Service.Intervention.InterventionNotificationValidationData.Vehicle.toObject(message.vehicles[j], options);
                }
                return object;
            };

            /**
             * Converts this InterventionNotificationValidationData to JSON.
             * @function toJSON
             * @memberof Service.Intervention.InterventionNotificationValidationData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            InterventionNotificationValidationData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            InterventionNotificationValidationData.Intervention = (function() {

                /**
                 * Properties of an Intervention.
                 * @memberof Service.Intervention.InterventionNotificationValidationData
                 * @interface IIntervention
                 * @property {google.protobuf.ITimestamp|null} [scheduleStartDate] Intervention scheduleStartDate
                 * @property {google.protobuf.ITimestamp|null} [scheduleEndDate] Intervention scheduleEndDate
                 */

                /**
                 * Constructs a new Intervention.
                 * @memberof Service.Intervention.InterventionNotificationValidationData
                 * @classdesc Represents an Intervention.
                 * @implements IIntervention
                 * @constructor
                 * @param {Service.Intervention.InterventionNotificationValidationData.IIntervention=} [properties] Properties to set
                 */
                function Intervention(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Intervention scheduleStartDate.
                 * @member {google.protobuf.ITimestamp|null|undefined} scheduleStartDate
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Intervention
                 * @instance
                 */
                Intervention.prototype.scheduleStartDate = null;

                /**
                 * Intervention scheduleEndDate.
                 * @member {google.protobuf.ITimestamp|null|undefined} scheduleEndDate
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Intervention
                 * @instance
                 */
                Intervention.prototype.scheduleEndDate = null;

                /**
                 * Creates a new Intervention instance using the specified properties.
                 * @function create
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Intervention
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.IIntervention=} [properties] Properties to set
                 * @returns {Service.Intervention.InterventionNotificationValidationData.Intervention} Intervention instance
                 */
                Intervention.create = function create(properties) {
                    return new Intervention(properties);
                };

                /**
                 * Encodes the specified Intervention message. Does not implicitly {@link Service.Intervention.InterventionNotificationValidationData.Intervention.verify|verify} messages.
                 * @function encode
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Intervention
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.IIntervention} message Intervention message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Intervention.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.scheduleStartDate != null && Object.hasOwnProperty.call(message, "scheduleStartDate"))
                        $root.google.protobuf.Timestamp.encode(message.scheduleStartDate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.scheduleEndDate != null && Object.hasOwnProperty.call(message, "scheduleEndDate"))
                        $root.google.protobuf.Timestamp.encode(message.scheduleEndDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Intervention message, length delimited. Does not implicitly {@link Service.Intervention.InterventionNotificationValidationData.Intervention.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Intervention
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.IIntervention} message Intervention message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Intervention.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Intervention message from the specified reader or buffer.
                 * @function decode
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Intervention
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Service.Intervention.InterventionNotificationValidationData.Intervention} Intervention
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Intervention.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.InterventionNotificationValidationData.Intervention();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.scheduleStartDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.scheduleEndDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Intervention message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Intervention
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Service.Intervention.InterventionNotificationValidationData.Intervention} Intervention
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Intervention.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Intervention message.
                 * @function verify
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Intervention
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Intervention.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.scheduleStartDate != null && message.hasOwnProperty("scheduleStartDate")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.scheduleStartDate);
                        if (error)
                            return "scheduleStartDate." + error;
                    }
                    if (message.scheduleEndDate != null && message.hasOwnProperty("scheduleEndDate")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.scheduleEndDate);
                        if (error)
                            return "scheduleEndDate." + error;
                    }
                    return null;
                };

                /**
                 * Creates an Intervention message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Intervention
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Service.Intervention.InterventionNotificationValidationData.Intervention} Intervention
                 */
                Intervention.fromObject = function fromObject(object) {
                    if (object instanceof $root.Service.Intervention.InterventionNotificationValidationData.Intervention)
                        return object;
                    var message = new $root.Service.Intervention.InterventionNotificationValidationData.Intervention();
                    if (object.scheduleStartDate != null) {
                        if (typeof object.scheduleStartDate !== "object")
                            throw TypeError(".Service.Intervention.InterventionNotificationValidationData.Intervention.scheduleStartDate: object expected");
                        message.scheduleStartDate = $root.google.protobuf.Timestamp.fromObject(object.scheduleStartDate);
                    }
                    if (object.scheduleEndDate != null) {
                        if (typeof object.scheduleEndDate !== "object")
                            throw TypeError(".Service.Intervention.InterventionNotificationValidationData.Intervention.scheduleEndDate: object expected");
                        message.scheduleEndDate = $root.google.protobuf.Timestamp.fromObject(object.scheduleEndDate);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an Intervention message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Intervention
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.Intervention} message Intervention
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Intervention.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.scheduleStartDate = null;
                        object.scheduleEndDate = null;
                    }
                    if (message.scheduleStartDate != null && message.hasOwnProperty("scheduleStartDate"))
                        object.scheduleStartDate = $root.google.protobuf.Timestamp.toObject(message.scheduleStartDate, options);
                    if (message.scheduleEndDate != null && message.hasOwnProperty("scheduleEndDate"))
                        object.scheduleEndDate = $root.google.protobuf.Timestamp.toObject(message.scheduleEndDate, options);
                    return object;
                };

                /**
                 * Converts this Intervention to JSON.
                 * @function toJSON
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Intervention
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Intervention.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Intervention;
            })();

            InterventionNotificationValidationData.TaskZone = (function() {

                /**
                 * Properties of a TaskZone.
                 * @memberof Service.Intervention.InterventionNotificationValidationData
                 * @interface ITaskZone
                 * @property {string|null} [name] TaskZone name
                 * @property {string|null} [address] TaskZone address
                 * @property {string|null} [addressBis] TaskZone addressBis
                 * @property {string|null} [city] TaskZone city
                 * @property {string|null} [zipCode] TaskZone zipCode
                 * @property {string|null} [country] TaskZone country
                 * @property {string|null} [timeZone] TaskZone timeZone
                 */

                /**
                 * Constructs a new TaskZone.
                 * @memberof Service.Intervention.InterventionNotificationValidationData
                 * @classdesc Represents a TaskZone.
                 * @implements ITaskZone
                 * @constructor
                 * @param {Service.Intervention.InterventionNotificationValidationData.ITaskZone=} [properties] Properties to set
                 */
                function TaskZone(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TaskZone name.
                 * @member {string} name
                 * @memberof Service.Intervention.InterventionNotificationValidationData.TaskZone
                 * @instance
                 */
                TaskZone.prototype.name = "";

                /**
                 * TaskZone address.
                 * @member {string} address
                 * @memberof Service.Intervention.InterventionNotificationValidationData.TaskZone
                 * @instance
                 */
                TaskZone.prototype.address = "";

                /**
                 * TaskZone addressBis.
                 * @member {string} addressBis
                 * @memberof Service.Intervention.InterventionNotificationValidationData.TaskZone
                 * @instance
                 */
                TaskZone.prototype.addressBis = "";

                /**
                 * TaskZone city.
                 * @member {string} city
                 * @memberof Service.Intervention.InterventionNotificationValidationData.TaskZone
                 * @instance
                 */
                TaskZone.prototype.city = "";

                /**
                 * TaskZone zipCode.
                 * @member {string} zipCode
                 * @memberof Service.Intervention.InterventionNotificationValidationData.TaskZone
                 * @instance
                 */
                TaskZone.prototype.zipCode = "";

                /**
                 * TaskZone country.
                 * @member {string} country
                 * @memberof Service.Intervention.InterventionNotificationValidationData.TaskZone
                 * @instance
                 */
                TaskZone.prototype.country = "";

                /**
                 * TaskZone timeZone.
                 * @member {string} timeZone
                 * @memberof Service.Intervention.InterventionNotificationValidationData.TaskZone
                 * @instance
                 */
                TaskZone.prototype.timeZone = "";

                /**
                 * Creates a new TaskZone instance using the specified properties.
                 * @function create
                 * @memberof Service.Intervention.InterventionNotificationValidationData.TaskZone
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.ITaskZone=} [properties] Properties to set
                 * @returns {Service.Intervention.InterventionNotificationValidationData.TaskZone} TaskZone instance
                 */
                TaskZone.create = function create(properties) {
                    return new TaskZone(properties);
                };

                /**
                 * Encodes the specified TaskZone message. Does not implicitly {@link Service.Intervention.InterventionNotificationValidationData.TaskZone.verify|verify} messages.
                 * @function encode
                 * @memberof Service.Intervention.InterventionNotificationValidationData.TaskZone
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.ITaskZone} message TaskZone message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskZone.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
                    if (message.addressBis != null && Object.hasOwnProperty.call(message, "addressBis"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.addressBis);
                    if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.city);
                    if (message.zipCode != null && Object.hasOwnProperty.call(message, "zipCode"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.zipCode);
                    if (message.country != null && Object.hasOwnProperty.call(message, "country"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.country);
                    if (message.timeZone != null && Object.hasOwnProperty.call(message, "timeZone"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.timeZone);
                    return writer;
                };

                /**
                 * Encodes the specified TaskZone message, length delimited. Does not implicitly {@link Service.Intervention.InterventionNotificationValidationData.TaskZone.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Service.Intervention.InterventionNotificationValidationData.TaskZone
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.ITaskZone} message TaskZone message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskZone.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TaskZone message from the specified reader or buffer.
                 * @function decode
                 * @memberof Service.Intervention.InterventionNotificationValidationData.TaskZone
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Service.Intervention.InterventionNotificationValidationData.TaskZone} TaskZone
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskZone.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.InterventionNotificationValidationData.TaskZone();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            message.address = reader.string();
                            break;
                        case 3:
                            message.addressBis = reader.string();
                            break;
                        case 4:
                            message.city = reader.string();
                            break;
                        case 5:
                            message.zipCode = reader.string();
                            break;
                        case 6:
                            message.country = reader.string();
                            break;
                        case 7:
                            message.timeZone = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TaskZone message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Service.Intervention.InterventionNotificationValidationData.TaskZone
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Service.Intervention.InterventionNotificationValidationData.TaskZone} TaskZone
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskZone.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TaskZone message.
                 * @function verify
                 * @memberof Service.Intervention.InterventionNotificationValidationData.TaskZone
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskZone.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    if (message.addressBis != null && message.hasOwnProperty("addressBis"))
                        if (!$util.isString(message.addressBis))
                            return "addressBis: string expected";
                    if (message.city != null && message.hasOwnProperty("city"))
                        if (!$util.isString(message.city))
                            return "city: string expected";
                    if (message.zipCode != null && message.hasOwnProperty("zipCode"))
                        if (!$util.isString(message.zipCode))
                            return "zipCode: string expected";
                    if (message.country != null && message.hasOwnProperty("country"))
                        if (!$util.isString(message.country))
                            return "country: string expected";
                    if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                        if (!$util.isString(message.timeZone))
                            return "timeZone: string expected";
                    return null;
                };

                /**
                 * Creates a TaskZone message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Service.Intervention.InterventionNotificationValidationData.TaskZone
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Service.Intervention.InterventionNotificationValidationData.TaskZone} TaskZone
                 */
                TaskZone.fromObject = function fromObject(object) {
                    if (object instanceof $root.Service.Intervention.InterventionNotificationValidationData.TaskZone)
                        return object;
                    var message = new $root.Service.Intervention.InterventionNotificationValidationData.TaskZone();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.address != null)
                        message.address = String(object.address);
                    if (object.addressBis != null)
                        message.addressBis = String(object.addressBis);
                    if (object.city != null)
                        message.city = String(object.city);
                    if (object.zipCode != null)
                        message.zipCode = String(object.zipCode);
                    if (object.country != null)
                        message.country = String(object.country);
                    if (object.timeZone != null)
                        message.timeZone = String(object.timeZone);
                    return message;
                };

                /**
                 * Creates a plain object from a TaskZone message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Service.Intervention.InterventionNotificationValidationData.TaskZone
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.TaskZone} message TaskZone
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskZone.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.address = "";
                        object.addressBis = "";
                        object.city = "";
                        object.zipCode = "";
                        object.country = "";
                        object.timeZone = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    if (message.addressBis != null && message.hasOwnProperty("addressBis"))
                        object.addressBis = message.addressBis;
                    if (message.city != null && message.hasOwnProperty("city"))
                        object.city = message.city;
                    if (message.zipCode != null && message.hasOwnProperty("zipCode"))
                        object.zipCode = message.zipCode;
                    if (message.country != null && message.hasOwnProperty("country"))
                        object.country = message.country;
                    if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                        object.timeZone = message.timeZone;
                    return object;
                };

                /**
                 * Converts this TaskZone to JSON.
                 * @function toJSON
                 * @memberof Service.Intervention.InterventionNotificationValidationData.TaskZone
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskZone.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TaskZone;
            })();

            InterventionNotificationValidationData.Operation = (function() {

                /**
                 * Properties of an Operation.
                 * @memberof Service.Intervention.InterventionNotificationValidationData
                 * @interface IOperation
                 * @property {string|null} [label] Operation label
                 * @property {Service.Intervention.InterventionNotificationValidationData.Operation.IOperationType|null} [operationType] Operation operationType
                 */

                /**
                 * Constructs a new Operation.
                 * @memberof Service.Intervention.InterventionNotificationValidationData
                 * @classdesc Represents an Operation.
                 * @implements IOperation
                 * @constructor
                 * @param {Service.Intervention.InterventionNotificationValidationData.IOperation=} [properties] Properties to set
                 */
                function Operation(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Operation label.
                 * @member {string} label
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Operation
                 * @instance
                 */
                Operation.prototype.label = "";

                /**
                 * Operation operationType.
                 * @member {Service.Intervention.InterventionNotificationValidationData.Operation.IOperationType|null|undefined} operationType
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Operation
                 * @instance
                 */
                Operation.prototype.operationType = null;

                /**
                 * Creates a new Operation instance using the specified properties.
                 * @function create
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Operation
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.IOperation=} [properties] Properties to set
                 * @returns {Service.Intervention.InterventionNotificationValidationData.Operation} Operation instance
                 */
                Operation.create = function create(properties) {
                    return new Operation(properties);
                };

                /**
                 * Encodes the specified Operation message. Does not implicitly {@link Service.Intervention.InterventionNotificationValidationData.Operation.verify|verify} messages.
                 * @function encode
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Operation
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.IOperation} message Operation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Operation.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
                    if (message.operationType != null && Object.hasOwnProperty.call(message, "operationType"))
                        $root.Service.Intervention.InterventionNotificationValidationData.Operation.OperationType.encode(message.operationType, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Operation message, length delimited. Does not implicitly {@link Service.Intervention.InterventionNotificationValidationData.Operation.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Operation
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.IOperation} message Operation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Operation.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Operation message from the specified reader or buffer.
                 * @function decode
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Operation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Service.Intervention.InterventionNotificationValidationData.Operation} Operation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Operation.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.InterventionNotificationValidationData.Operation();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.label = reader.string();
                            break;
                        case 2:
                            message.operationType = $root.Service.Intervention.InterventionNotificationValidationData.Operation.OperationType.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Operation message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Operation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Service.Intervention.InterventionNotificationValidationData.Operation} Operation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Operation.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Operation message.
                 * @function verify
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Operation
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Operation.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.label != null && message.hasOwnProperty("label"))
                        if (!$util.isString(message.label))
                            return "label: string expected";
                    if (message.operationType != null && message.hasOwnProperty("operationType")) {
                        var error = $root.Service.Intervention.InterventionNotificationValidationData.Operation.OperationType.verify(message.operationType);
                        if (error)
                            return "operationType." + error;
                    }
                    return null;
                };

                /**
                 * Creates an Operation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Operation
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Service.Intervention.InterventionNotificationValidationData.Operation} Operation
                 */
                Operation.fromObject = function fromObject(object) {
                    if (object instanceof $root.Service.Intervention.InterventionNotificationValidationData.Operation)
                        return object;
                    var message = new $root.Service.Intervention.InterventionNotificationValidationData.Operation();
                    if (object.label != null)
                        message.label = String(object.label);
                    if (object.operationType != null) {
                        if (typeof object.operationType !== "object")
                            throw TypeError(".Service.Intervention.InterventionNotificationValidationData.Operation.operationType: object expected");
                        message.operationType = $root.Service.Intervention.InterventionNotificationValidationData.Operation.OperationType.fromObject(object.operationType);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an Operation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Operation
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.Operation} message Operation
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Operation.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.label = "";
                        object.operationType = null;
                    }
                    if (message.label != null && message.hasOwnProperty("label"))
                        object.label = message.label;
                    if (message.operationType != null && message.hasOwnProperty("operationType"))
                        object.operationType = $root.Service.Intervention.InterventionNotificationValidationData.Operation.OperationType.toObject(message.operationType, options);
                    return object;
                };

                /**
                 * Converts this Operation to JSON.
                 * @function toJSON
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Operation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Operation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                Operation.OperationType = (function() {

                    /**
                     * Properties of an OperationType.
                     * @memberof Service.Intervention.InterventionNotificationValidationData.Operation
                     * @interface IOperationType
                     * @property {string|null} [label] OperationType label
                     */

                    /**
                     * Constructs a new OperationType.
                     * @memberof Service.Intervention.InterventionNotificationValidationData.Operation
                     * @classdesc Represents an OperationType.
                     * @implements IOperationType
                     * @constructor
                     * @param {Service.Intervention.InterventionNotificationValidationData.Operation.IOperationType=} [properties] Properties to set
                     */
                    function OperationType(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OperationType label.
                     * @member {string} label
                     * @memberof Service.Intervention.InterventionNotificationValidationData.Operation.OperationType
                     * @instance
                     */
                    OperationType.prototype.label = "";

                    /**
                     * Creates a new OperationType instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.InterventionNotificationValidationData.Operation.OperationType
                     * @static
                     * @param {Service.Intervention.InterventionNotificationValidationData.Operation.IOperationType=} [properties] Properties to set
                     * @returns {Service.Intervention.InterventionNotificationValidationData.Operation.OperationType} OperationType instance
                     */
                    OperationType.create = function create(properties) {
                        return new OperationType(properties);
                    };

                    /**
                     * Encodes the specified OperationType message. Does not implicitly {@link Service.Intervention.InterventionNotificationValidationData.Operation.OperationType.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.InterventionNotificationValidationData.Operation.OperationType
                     * @static
                     * @param {Service.Intervention.InterventionNotificationValidationData.Operation.IOperationType} message OperationType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OperationType.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
                        return writer;
                    };

                    /**
                     * Encodes the specified OperationType message, length delimited. Does not implicitly {@link Service.Intervention.InterventionNotificationValidationData.Operation.OperationType.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.InterventionNotificationValidationData.Operation.OperationType
                     * @static
                     * @param {Service.Intervention.InterventionNotificationValidationData.Operation.IOperationType} message OperationType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OperationType.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OperationType message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.InterventionNotificationValidationData.Operation.OperationType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.InterventionNotificationValidationData.Operation.OperationType} OperationType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OperationType.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.InterventionNotificationValidationData.Operation.OperationType();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.label = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OperationType message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.InterventionNotificationValidationData.Operation.OperationType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.InterventionNotificationValidationData.Operation.OperationType} OperationType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OperationType.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OperationType message.
                     * @function verify
                     * @memberof Service.Intervention.InterventionNotificationValidationData.Operation.OperationType
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OperationType.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.label != null && message.hasOwnProperty("label"))
                            if (!$util.isString(message.label))
                                return "label: string expected";
                        return null;
                    };

                    /**
                     * Creates an OperationType message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.InterventionNotificationValidationData.Operation.OperationType
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.InterventionNotificationValidationData.Operation.OperationType} OperationType
                     */
                    OperationType.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.InterventionNotificationValidationData.Operation.OperationType)
                            return object;
                        var message = new $root.Service.Intervention.InterventionNotificationValidationData.Operation.OperationType();
                        if (object.label != null)
                            message.label = String(object.label);
                        return message;
                    };

                    /**
                     * Creates a plain object from an OperationType message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.InterventionNotificationValidationData.Operation.OperationType
                     * @static
                     * @param {Service.Intervention.InterventionNotificationValidationData.Operation.OperationType} message OperationType
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OperationType.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.label = "";
                        if (message.label != null && message.hasOwnProperty("label"))
                            object.label = message.label;
                        return object;
                    };

                    /**
                     * Converts this OperationType to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.InterventionNotificationValidationData.Operation.OperationType
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OperationType.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OperationType;
                })();

                return Operation;
            })();

            InterventionNotificationValidationData.Technician = (function() {

                /**
                 * Properties of a Technician.
                 * @memberof Service.Intervention.InterventionNotificationValidationData
                 * @interface ITechnician
                 * @property {string|null} [firstName] Technician firstName
                 * @property {string|null} [lastName] Technician lastName
                 */

                /**
                 * Constructs a new Technician.
                 * @memberof Service.Intervention.InterventionNotificationValidationData
                 * @classdesc Represents a Technician.
                 * @implements ITechnician
                 * @constructor
                 * @param {Service.Intervention.InterventionNotificationValidationData.ITechnician=} [properties] Properties to set
                 */
                function Technician(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Technician firstName.
                 * @member {string} firstName
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Technician
                 * @instance
                 */
                Technician.prototype.firstName = "";

                /**
                 * Technician lastName.
                 * @member {string} lastName
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Technician
                 * @instance
                 */
                Technician.prototype.lastName = "";

                /**
                 * Creates a new Technician instance using the specified properties.
                 * @function create
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Technician
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.ITechnician=} [properties] Properties to set
                 * @returns {Service.Intervention.InterventionNotificationValidationData.Technician} Technician instance
                 */
                Technician.create = function create(properties) {
                    return new Technician(properties);
                };

                /**
                 * Encodes the specified Technician message. Does not implicitly {@link Service.Intervention.InterventionNotificationValidationData.Technician.verify|verify} messages.
                 * @function encode
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Technician
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.ITechnician} message Technician message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Technician.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.firstName);
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
                    return writer;
                };

                /**
                 * Encodes the specified Technician message, length delimited. Does not implicitly {@link Service.Intervention.InterventionNotificationValidationData.Technician.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Technician
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.ITechnician} message Technician message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Technician.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Technician message from the specified reader or buffer.
                 * @function decode
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Technician
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Service.Intervention.InterventionNotificationValidationData.Technician} Technician
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Technician.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.InterventionNotificationValidationData.Technician();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.firstName = reader.string();
                            break;
                        case 2:
                            message.lastName = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Technician message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Technician
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Service.Intervention.InterventionNotificationValidationData.Technician} Technician
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Technician.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Technician message.
                 * @function verify
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Technician
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Technician.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    return null;
                };

                /**
                 * Creates a Technician message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Technician
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Service.Intervention.InterventionNotificationValidationData.Technician} Technician
                 */
                Technician.fromObject = function fromObject(object) {
                    if (object instanceof $root.Service.Intervention.InterventionNotificationValidationData.Technician)
                        return object;
                    var message = new $root.Service.Intervention.InterventionNotificationValidationData.Technician();
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    return message;
                };

                /**
                 * Creates a plain object from a Technician message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Technician
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.Technician} message Technician
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Technician.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.firstName = "";
                        object.lastName = "";
                    }
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    return object;
                };

                /**
                 * Converts this Technician to JSON.
                 * @function toJSON
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Technician
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Technician.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Technician;
            })();

            InterventionNotificationValidationData.Vehicle = (function() {

                /**
                 * Properties of a Vehicle.
                 * @memberof Service.Intervention.InterventionNotificationValidationData
                 * @interface IVehicle
                 * @property {string|null} [name] Vehicle name
                 * @property {string|null} [licensePlate] Vehicle licensePlate
                 */

                /**
                 * Constructs a new Vehicle.
                 * @memberof Service.Intervention.InterventionNotificationValidationData
                 * @classdesc Represents a Vehicle.
                 * @implements IVehicle
                 * @constructor
                 * @param {Service.Intervention.InterventionNotificationValidationData.IVehicle=} [properties] Properties to set
                 */
                function Vehicle(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Vehicle name.
                 * @member {string} name
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Vehicle
                 * @instance
                 */
                Vehicle.prototype.name = "";

                /**
                 * Vehicle licensePlate.
                 * @member {string} licensePlate
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Vehicle
                 * @instance
                 */
                Vehicle.prototype.licensePlate = "";

                /**
                 * Creates a new Vehicle instance using the specified properties.
                 * @function create
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Vehicle
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.IVehicle=} [properties] Properties to set
                 * @returns {Service.Intervention.InterventionNotificationValidationData.Vehicle} Vehicle instance
                 */
                Vehicle.create = function create(properties) {
                    return new Vehicle(properties);
                };

                /**
                 * Encodes the specified Vehicle message. Does not implicitly {@link Service.Intervention.InterventionNotificationValidationData.Vehicle.verify|verify} messages.
                 * @function encode
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Vehicle
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.IVehicle} message Vehicle message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Vehicle.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.licensePlate != null && Object.hasOwnProperty.call(message, "licensePlate"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.licensePlate);
                    return writer;
                };

                /**
                 * Encodes the specified Vehicle message, length delimited. Does not implicitly {@link Service.Intervention.InterventionNotificationValidationData.Vehicle.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Vehicle
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.IVehicle} message Vehicle message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Vehicle.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Vehicle message from the specified reader or buffer.
                 * @function decode
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Vehicle
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Service.Intervention.InterventionNotificationValidationData.Vehicle} Vehicle
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Vehicle.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.InterventionNotificationValidationData.Vehicle();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            message.licensePlate = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Vehicle message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Vehicle
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Service.Intervention.InterventionNotificationValidationData.Vehicle} Vehicle
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Vehicle.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Vehicle message.
                 * @function verify
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Vehicle
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Vehicle.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.licensePlate != null && message.hasOwnProperty("licensePlate"))
                        if (!$util.isString(message.licensePlate))
                            return "licensePlate: string expected";
                    return null;
                };

                /**
                 * Creates a Vehicle message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Vehicle
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Service.Intervention.InterventionNotificationValidationData.Vehicle} Vehicle
                 */
                Vehicle.fromObject = function fromObject(object) {
                    if (object instanceof $root.Service.Intervention.InterventionNotificationValidationData.Vehicle)
                        return object;
                    var message = new $root.Service.Intervention.InterventionNotificationValidationData.Vehicle();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.licensePlate != null)
                        message.licensePlate = String(object.licensePlate);
                    return message;
                };

                /**
                 * Creates a plain object from a Vehicle message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Vehicle
                 * @static
                 * @param {Service.Intervention.InterventionNotificationValidationData.Vehicle} message Vehicle
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Vehicle.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.licensePlate = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.licensePlate != null && message.hasOwnProperty("licensePlate"))
                        object.licensePlate = message.licensePlate;
                    return object;
                };

                /**
                 * Converts this Vehicle to JSON.
                 * @function toJSON
                 * @memberof Service.Intervention.InterventionNotificationValidationData.Vehicle
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Vehicle.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Vehicle;
            })();

            return InterventionNotificationValidationData;
        })();

        return Intervention;
    })();

    return Service;
})();

$root.google = (function() {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    var google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        var protobuf = {};

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.seconds = reader.int64();
                        break;
                    case 2:
                        message.nanos = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                var message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Timestamp;
        })();

        return protobuf;
    })();

    return google;
})();

export default $root;
