import ConfigProxy            from 'tools/ConfigProxy';
import { LoggedApiConnector } from '../connectors/LoggedApiConnector';
import FileApiModel           from './FileApiModel';

export const fileLoggedApiConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('file', 'api_endpoint'),
});

export default class FilePrivateApiModel extends FileApiModel {
	public static get connector() {
		return fileLoggedApiConnector;
	}
}
