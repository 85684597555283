import { computed }             from 'mobx';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

export default class CompanyModel extends DirectoryPrivateApiModel {
	public filters: ModelFiltersExtended<{
		'clientPartitions': id;
		'enterprise': id;
		'name': string;
		'siretNumber': string;
	}> = {};

	static get path(): string {
		return '/companies/{?id}';
	}

	@computed
	public get headOffice(): boolean {
		return this.get('headOffice', false);
	}

	@computed
	public get name(): string {
		return this.get('name', '');
	}

	@computed
	public get siretNumber(): string {
		return this.get('siretNumber', '');
	}

	@computed
	public get enterpriseId(): id {
		return this.get('enterprise.id');
	}
}
