import { WebStorageConnector } from '@mathquis/modelx-webstorage-connector';
import { Connector, Model }    from '@mathquis/modelx';

export const localStorageConnector: any = new WebStorageConnector({
	storage: localStorage,
});

export default class LocalStorageModel extends Model {
	static get connector(): Connector {
		return localStorageConnector;
	}
}