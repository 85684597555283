import resolvable                  from 'decorators/resolvable';
import { computed }                from 'mobx';
import moment                      from 'moment';
import { getIdFromUrn }            from 'tools/UrnTools';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';
import TaskModel                   from './TaskModel';

class TaskOperationModel extends InterventionPrivateApiModel {
	public filters: ModelFiltersExtended<{
		'operation.operationCategory': id;
		'task': id;
		'task.taskMetas.reference': string;
		'task.taskMetas.value': string;
		'taskOperationStatus': id;
		'taskZone': id;
	}> = {};

	static get path(): string {
		return '/task_operations/{?id}';
	}

	@computed
	public get value(): number {
		return this.get('value', 0);
	}

	@computed
	public get taskId(): number {
		return this.get('task.id', 0);
	}

	@computed
	public get taskZoneId(): number {
		return this.get('taskZone.id', 0);
	}

	@computed
	public get operationId(): number {
		return this.get('operation.id', 0);
	}

	@computed
	public get taskOperationStatusId(): number {
		return this.get('taskOperationStatus.id', 0);
	}

	@computed
	public get contractIterationItemActivityId(): number {
		return parseInt(getIdFromUrn(this.get('sourceUrn', '')));
	}

	@computed
	public get scheduleStartDate(): string {
		return this.get('scheduleStartDate', '');
	}

	@computed
	public get scheduleEndDate(): string {
		return this.get('scheduleEndDate', '');
	}

	@computed
	public get scheduleStartDateMoment(): Moment {
		return moment(this.scheduleStartDate);
	}

	@computed
	public get scheduleEndDateMoment(): Moment {
		return moment(this.scheduleEndDate);
	}

	@computed
	public get isLate(): boolean {
		return moment(this.scheduleStartDate).isBefore();
	}
	
	@resolvable(TaskModel, { attributeName: 'task' })
	declare task: TaskModel;
}

export default TaskOperationModel;
