import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class InterventionNotificationValidationModel extends InterventionPrivateApiModel {
	static get path(): string {
		return '/intervention_notification_validations/{?id}';
	}

	@computed
	public get data(): string {
		return this.get('data');
	}

	@computed
	public get token(): string {
		return this.get('token');
	}
}
