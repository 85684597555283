import { CheckOutlined }                                      from '@ant-design/icons/lib';

import { PageHeader }                                         from '@ant-design/pro-layout';
import QuotationStatusHistoryModel                            from 'Models/sales/QuotationStatusHistoryModel';
import Button                                                 from 'antd/lib/button';
import Col                                                    from 'antd/lib/col';
import Descriptions                                           from 'antd/lib/descriptions';
import Modal                                                  from 'antd/lib/modal';
import Row                                                    from 'antd/lib/row';
import Spin                                                   from 'antd/lib/spin';
import {
	QUOTATION_STATUS_SENT_TO_CLIENT,
	QUOTATION_STATUS_VALIDATED_BY_CLIENT,
}                                                            from 'constants/QuotationStatuses';
import useTo                                                  from 'hooks/useTo';
import { observer }                                           from 'mobx-react';
import moment                                                 from 'moment';
import { useNavigate, useParams }                             from 'react-router-dom';
import React, { useEffect, useState }                         from 'react';
import { appStore, contractsStore, quotationDashboardStore }  from 'stores/index';
import { formatPrice }                                        from 'tools/PriceHelper';
import { notificationError, notificationSuccess }             from 'tools/notification';
import HttpResult                                             from '../../../components/HttpResult';
import QuotationActorCommercial                               from '../../../entityComponents/QuotationActor/QuotationActorCommercial';
import notificationApiError                                   from '../../../utils/notificationApiError';
import DropdownMenu                                           from './DropdownMenu';
import QuotationItemBox                                       from './QuotationItemBox';
import '../css/ContractDashboard.scss';

interface QuotationDashboardProps {
	backPath: string;
	children?: React.ReactNode;
	onReady: (id: number) => Promise<unknown>;
	title: string;
}

const QuotationDashboard: React.FC<QuotationDashboardProps> = props => {
	const { id } = useParams<{ id?: string | undefined; }>();
	const navigate = useNavigate();
	const to = useTo();
	const [error, setError] = useState(undefined);

	const quotationId = id ? parseInt(id) : 0;

	const { backPath, children, onReady, title } = props;

	const {
		quotationStatusCollection,
	} = appStore;

	const {
		quotation,
		quotationActorCollection,
		quotationFileCollection,
		staffCollection,
		staffMemberCollection,
	} = quotationDashboardStore;

	const isLoading = (
		quotation.isLoading
		|| quotationActorCollection.isLoading
		|| quotationFileCollection.isLoading
		|| staffCollection.isLoading
		|| staffMemberCollection.isLoading
	);

	useEffect(() => {
		onReady(quotationId).catch(e => setError(e));
	}, [id, onReady]);

	const quotationStatus = quotationStatusCollection.find(quotationStatus => quotationStatus.id === quotation.quotationStatusId);

	if (!quotationStatus) {
		return null;
	}

	return (
		<HttpResult error={error}>
			<div className="dashboard contract-dashboard">
				{isLoading ? <Spin /> : (
					<>
						<PageHeader
							extra={
								<>
									{(quotationStatus.reference === QUOTATION_STATUS_SENT_TO_CLIENT.reference) && (
										<Button
											onClick={() => {
												Modal.confirm({
													cancelText: `Annuler`,
													// className: 'qhs-confirm-modal',
													maskClosable: true,
													okText: `Valider`,
													onOk: () => {
														const quotationStatusValidatedByClient = quotationStatusCollection.find(quotationStatus => quotationStatus.reference === QUOTATION_STATUS_VALIDATED_BY_CLIENT.reference);
														if (!quotationStatusValidatedByClient) {
															notificationError({ message: 'Une erreur s\'est produite', });
															return;
														}
														const quotationStatusHistory = new QuotationStatusHistoryModel({
															quotation: quotation.iri,
															quotationStatus: quotationStatusValidatedByClient.iri,
														});
														quotationStatusHistory
															.save()
															.then(() => {
																notificationSuccess({ message: 'Validation réussie !', });
																quotationDashboardStore.fetch(quotation.id);
																contractsStore.fetch();
															})
															.catch(notificationApiError);
													},
													title: `Valider le devis ?`,
													width: 512,
												});
											}}
										>
											<CheckOutlined />
										</Button>
									)}
									<DropdownMenu
										quotationFileCollection={quotationFileCollection}
									/>
								</>
							}
							onBack={() => navigate(to(backPath), { replace: true })}
							title={`${title} ${quotation.number}`}
						>
							<Descriptions column={3} size="small">
								<Descriptions.Item label="État">
									{quotationStatus && quotationStatus.label}
								</Descriptions.Item>

								<Descriptions.Item label="Titre">
									{quotation.title}
								</Descriptions.Item>

								<Descriptions.Item label="Dates">
									du&nbsp;
									{moment(quotation.startDate).format('L')}
									&nbsp;au&nbsp;
									{moment(quotation.endDate).format('L')}
								</Descriptions.Item>

								<Descriptions.Item label="Montant total HT">
									{formatPrice(quotation.totalPriceExclTax)}
								</Descriptions.Item>

								<Descriptions.Item label="Montant total TTC">
									{formatPrice(quotation.totalPriceInclTax)}
								</Descriptions.Item>

								<Descriptions.Item label="Commercial">
									<QuotationActorCommercial
										quotationActorCommercialCollection={quotationActorCollection}
										staffCollection={staffCollection}
										staffMemberCollection={staffMemberCollection}
									/>
								</Descriptions.Item>

								<Descriptions.Item label="Durée">
									{quotation.duration} mois
								</Descriptions.Item>
							</Descriptions>
						</PageHeader>

						<Row gutter={20}>
							<Col md={24} xs={24}>
								<QuotationItemBox />
							</Col>

							{children}
						</Row>
					</>
				)}
			</div>
		</HttpResult>
	);
};

export default observer(QuotationDashboard);
