import Card         from 'antd/lib/card';
import Descriptions from 'antd/lib/descriptions';
import { observer } from 'mobx-react';
import React        from 'react';
import { appStore } from 'stores/index';

const DetailsBox = () => {
	const {
		selectedTaskZone,
	} = appStore;

	return (
		<Card
			loading={!selectedTaskZone}
			title="Tableau de bord du site"
		>
			<b className="sub-title">
				{selectedTaskZone?.taskZoneName}
			</b>

			<Descriptions column={1} size="small" style={{ marginTop: 10 }}>
				<Descriptions.Item label="Adresse">
					{selectedTaskZone?.address} {selectedTaskZone?.zipCode} {selectedTaskZone?.city}
				</Descriptions.Item>
			</Descriptions>
		</Card>
	);
};

export default observer(DetailsBox);
