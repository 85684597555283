import { computed }             from 'mobx';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

export default class EnterpriseModel extends DirectoryPrivateApiModel {
	public filters: ModelFiltersExtended<{
		'commercialName': string;
		'enterpriseGroup': id;
		'enterprisePartitions': id;
		'enterprisePartitions.partitionUrn': string;
		'enterpriseType': id;
		'intraCommunityVat': string;
		'name': string;
		'number': id;
		'reference': string;
		'siren': string;
	}> = {};

	static get path(): string {
		return '/enterprises/{?id}';
	}

	@computed
	public get commercialName(): string {
		return this.get('commercialName', '');
	}

	@computed
	public get siren(): string {
		return this.get('siren', '');
	}
	@computed
	public get enterprisePartitionId(): id {
		return this.get('enterprisePartitions.id');
	}

	@computed
	public get enterpriseTypeId(): id {
		return this.get('enterpriseType.id', '');
	}

	@computed
	public get enterpriseTypeIri(): string {
		return this.get('enterpriseType.@id', '');
	}

	@computed
	public get enterpriseGroupId(): id {
		return this.get('enterpriseGroup.id', '');
	}

	@computed
	public get enterpriseGroupIri(): string {
		return this.get('enterpriseGroup.@id', '');
	}

	@computed
	public get intraCommunityVat(): string {
		return this.get('intraCommunityVat', '');
	}

	@computed
	public get reference(): string {
		return this.get('reference', '');
	}

	public get name(): string {
		return this.get('name', '');
	}

	public get number(): number {
		return this.get('number', 0);
	}
}
