import Form                          from 'antd/lib/form';
import Input                         from 'antd/lib/input';
import Select                        from 'antd/lib/select';
import { observer }                  from 'mobx-react';
import React                         from 'react';
import ModalForm, { ModalFormProps } from '../components/ModalForm';
import Genders                       from '../constants/Genders';

const ContactModalForm: React.FC<ModalFormProps & { disabledFields?: string[] }> = props => {
	return (
		<ModalForm title="Ajouter un contact" {...props}>
			<Form.Item
				label="Prénom"
				name="firstName"
				rules={[
					{
						message: 'Veuillez renseigner le prénom',
						required: true,
					},
				]}
			>
				<Input disabled={props.disabledFields?.includes('firstName')} placeholder="Prénom" />
			</Form.Item>

			<Form.Item
				label="Nom"
				name="lastName"
				rules={[
					{
						message: 'Veuillez renseigner le nom',
						required: true,
					},
				]}
			>
				<Input disabled={props.disabledFields?.includes('lastName')} placeholder="Nom" />
			</Form.Item>

			<Form.Item
				label="Genre"
				name="gender"
				rules={[
					{
						message: 'Veuillez renseigner le genre',
						required: true,
					},
				]}
			>
				<Select disabled={props.disabledFields?.includes('gender')} placeholder="Genre">
					{Genders.map(gender => (
						<Select.Option
							key={gender.reference}
							value={gender.reference}
						>
							{gender.label}
						</Select.Option>
					))}
				</Select>
			</Form.Item>

			<Form.Item
				label="E-mail"
				name="email"
				rules={[
					{
						message: 'Veuillez renseigner l\'e-mail',
						required: true,
					},
					{
						message: 'Adresse email invalide',
						type: 'email',
					},
					{
						message: 'Adresse email contient des accents',
						pattern: new RegExp('^[^àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]+$'),
					},
				]}
			>
				<Input disabled={props.disabledFields?.includes('email')} placeholder="E-mail" />
			</Form.Item>

			<Form.Item
				label="Téléphone principal"
				name="phoneNumber"
				rules={[
					{
						message: 'Veuillez renseigner le téléphone principal',
						required: true,
					},
				]}
			>
				<Input disabled={props.disabledFields?.includes('phoneNumber')} placeholder="Téléphone principal" />
			</Form.Item>

			<Form.Item
				label="Téléphone secondaire"
				name="phoneNumberBis"
			>
				<Input disabled={props.disabledFields?.includes('phoneNumberBis')} placeholder="Téléphone secondaire" />
			</Form.Item>

			<Form.Item
				label="Fonction"
				name="function"
			>
				<Input placeholder="Fonction" />
			</Form.Item>
		</ModalForm>
	);
};

export default observer(ContactModalForm);