import {
	CalendarOutlined,
	CopyOutlined,
	EuroOutlined,
	MailOutlined,
	TeamOutlined,
	UserOutlined,
}                   from '@ant-design/icons';

import Menu                    from 'antd/lib/menu';
import { observer }            from 'mobx-react';
import { Link }                from 'react-router-dom';
import React                   from 'react';
import { appStore }            from 'stores/index';
import useTo                   from '../../hooks/useTo';
import '../css/PrivateLayout.scss';

const SideBarMenu = ({
	currentPath,
}: {
	currentPath: string,
}) => {
	const to = useTo();
	
	return (
		<Menu
			defaultOpenKeys={['interventions', 'contacts']}
			items={!appStore.selectedTaskZone ? [
				{
					icon: <UserOutlined />,
					key: '/',
					label: <Link to="/">Accueil</Link>,
				},
			] :	[
				{
					icon: <UserOutlined />,
					key: to('/'),
					label: <Link to={to('/')}>Accueil</Link>,
				},
				{
					icon: <CopyOutlined />,
					key: to('/contracts'),
					label: <Link to={to('/contracts')}>Mes contrats</Link>,
				},
				{
					icon: <CopyOutlined />,
					key: to('/quotations'),
					label: <Link to={to('/quotations')}>Mes devis</Link>,
				},
				{
					icon: <CopyOutlined />,
					key: to('/framework_agreements'),
					label: <Link to={to('/framework_agreements')}>Mes accords cadres</Link>,
				},
				{
					icon: <EuroOutlined />,
					key: to('/my-invoices'),
					label: <Link to={to('/my-invoices')}>Mes factures</Link>,
				},
				{
					children: [
						{
							key: to('/future-interventions'),
							label: <Link to={to('/future-interventions')}>Prochaines interventions</Link>,
						},
						{
							key: to('/completed-interventions'),
							label: <Link to={to('/completed-interventions')}>Interventions effectuées</Link>,
						},
					],
					icon: <CalendarOutlined />,
					key: 'interventions',
					label: 'Interventions',
				},
				{
					children: [
						{
							key: to('/contacts'),
							label: <Link to={to('/contacts')}>Liste des contacts</Link>,
						},
						{
							key: to('/contact-data-points'),
							label: <Link to={to('/contact-data-points')}>Fonctions</Link>,
						},
					],
					icon: <TeamOutlined />,
					key: 'contacts',
					label: 'Contacts',
				},
				{
					icon: <MailOutlined />,
					key: to('/contact'),
					label: <Link to={to('/contact')}>Contactez-nous</Link>,
				},
			]}
			mode="inline"
			selectedKeys={currentPath ? [currentPath] : undefined}
			theme="dark"
		/>
	);
};

export default observer(SideBarMenu);
