import { ApiCollection }                      from 'Collections/ApiCollection';
import FrameworkAgreementIterationModel       from 'Models/sales/FrameworkAgreementIterationModel';
import FrameworkAgreementIterationStatusModel from 'Models/sales/FrameworkAgreementIterationStatusModel';
import Tag                                    from 'antd/lib/tag';
import { observer }                           from 'mobx-react';
import React                                  from 'react';

const FrameworkAgreementIterationStatusName = observer(({
	frameworkAgreementIteration,
	frameworkAgreementIterationStatusCollection,
}: {
	frameworkAgreementIteration: FrameworkAgreementIterationModel;
	frameworkAgreementIterationStatusCollection: ApiCollection<FrameworkAgreementIterationStatusModel>;
}) => {
	const frameworkAgreementIterationStatus = frameworkAgreementIterationStatusCollection.findBy(
		'id', frameworkAgreementIteration.frameworkAgreementIterationStatusId
	);

	return <Tag color={frameworkAgreementIterationStatus?.color}>
		{frameworkAgreementIterationStatus?.label}
	</Tag>;
});

export default FrameworkAgreementIterationStatusName;
