import { ApiCollection }                from 'Collections/ApiCollection';
import { PagedCollection }              from 'Collections/PagedCollection';
import { TableProps }                   from 'antd/lib/table';
import { Empty, Table }                 from 'antd';
import { observer }                     from 'mobx-react';
import React                            from 'react';

interface ITablePaginationCollectionProps {
	collection: ApiCollection<any> | PagedCollection<any>;
	onLoad?: () => void;
}

const defaultPageSize = 10;

@observer
export default class TablePaginationCollection
	extends React.Component<ITablePaginationCollectionProps & TableProps<any>> {

	public componentDidMount(): void {
		if (this.props.onLoad) {
			this.props.onLoad();
		}
	}

	public render() {
		const {
			collection,
			columns,
		} = this.props;

		const isPagedCollection = collection instanceof PagedCollection;

		let pagination;

		if (isPagedCollection) {
			pagination = {
				pageSize: (collection as PagedCollection<any>).itemsPerPage,
				total: (collection as PagedCollection<any>).total,
			};
		} else {
			pagination = {
				pageSize: collection.models.length,
				total: collection.models.length,
			};
		}

		let defaultSortName: undefined | string = undefined;
		let defaultSortOrder: undefined | string = undefined;
		const sorts = collection.getSorts();
		const sortsNames = Object.keys(sorts);
		if (sortsNames.length) {
			defaultSortName = sortsNames[0];
			defaultSortOrder = sorts[defaultSortName];
		}

		return <Table
			dataSource={[...collection.models]}
			loading={collection.isLoading}
			locale={{
				emptyText: <Empty
					description="Aucun résultat"
					image={Empty.PRESENTED_IMAGE_SIMPLE}
				/>,
			}}
			onChange={(pagination, filters, sort) => {
				collection.setSort(sort['columnKey'], sort['order'] === 'ascend');

				if (filters['interventionOperations.taskOperation.operation.operationCategory']) {
					collection.setFilter('interventionOperations.taskOperation.operation.operationCategory', filters['interventionOperations.taskOperation.operation.operationCategory']);
				} else {
					collection.removeFilter('interventionOperations.taskOperation.operation.operationCategory');
				}

				if (filters['taskZone']) {
					collection.setFilter('taskZone', filters['taskZone']);
				} else {
					collection.removeFilter('taskZone');
				}

				if (isPagedCollection) {
					(collection as PagedCollection<any>)
						.setPage(pagination.current || 1)
						.setItemsPerPage(pagination.pageSize || defaultPageSize);
				}

				collection.list();
			}}
			pagination={pagination}
			{...this.props}
			columns={columns?.map(col => ({
				...col,
				defaultSortOrder: defaultSortName === col.key ? (
					defaultSortOrder === 'asc' ? 'ascend' : 'descend'
				) : undefined,
			}))}
		/>;
	}
}