import moment from 'moment';

export const getMondayOfWeekForDate = (d: Date = new Date()): Date => {
	d = new Date(d);
	const day = d.getDay();
	const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday

	return new Date(d.setDate(diff));
};

export const getSundayOfWeekForDate = (d: Date = new Date()): Date => {
	const date = getMondayOfWeekForDate(new Date(d));

	date.setDate(date.getDate() + 6);

	return date;
};

export const durationFormatted = (start: Moment, end: Moment, slice?: number[]): string => {

	const duration = moment.duration(end.diff(start));

	let str = '';

	let data = [
		{ label: 'an', value: duration.years() },
		{ label: 'jour', value: duration.days() },
		{ label: 'mois', value: duration.months() },
		{ label: 'heure', value: duration.hours() },
		{ label: 'minute', value: duration.minutes() },
		{ label: 'seconde', value: duration.seconds() },
	];

	if (slice) {
		data = data.slice(...slice);
	}

	data.forEach((datum, idx) => {
		if (datum.value) {
			if (data.length > 1 && data.length === idx + 1) {
				str += ' et ';
			}

			str += `${datum.value} ${datum.label}${datum.value > 1 ? 's' : ''} `;
		}
	});

	return str.trim();
};