import { PlusOutlined }             from '@ant-design/icons';
import Button                       from 'antd/lib/button';
import Col                          from 'antd/lib/col';
import notification                 from 'antd/lib/notification';
import Row                          from 'antd/lib/row';
import { observer }                 from 'mobx-react';
import React, { useState }          from 'react';
import { appStore, functionsStore } from 'stores/index';
import ClientContactModalForm       from '../../../forms/ClientContactModalForm';
import InvoicingContactModel        from '../../../modelx/models/private/invoice/InvoicingContactModel';
import SalesContactModel            from '../../../modelx/models/private/sales/SalesContactModel';
import ClientServiceContactsBox     from './ClientServiceContactsBox';

const ClientSection = () => {
	const [modalVisible, setModalVisible] = useState(false);

	const {
		selectedEnterprise,
	} = appStore;

	const {
		clientContactCollection,
		invoiceContactTypeCollection,
		invoicingContactCollection,
		salesContactCollection,
		salesContactTypeCollection,
	} = functionsStore;

	return (
		<div>
			<div
				style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}
			>
				<b style={{ fontSize: 20 }}>
					Contacts du client {selectedEnterprise?.name}
				</b>

				<Button onClick={() => setModalVisible(true)} type="primary">
					<PlusOutlined />
					Ajouter un point de contact
				</Button>
			</div>

			<ClientContactModalForm
				invoiceContactTypeCollection={functionsStore.invoiceContactTypeCollection}
				invoicingContactCollection={invoicingContactCollection}
				onCancel={() => setModalVisible(false)}
				onSubmit={async values => {
					let promise;

					if (values.type.indexOf('sales') > -1) {
						const salesContact = new SalesContactModel({
							clientUrn: appStore.selectedClient?.urn,
							contactType: values.type,
							contactUrn: values.contact,
						});
						promise = salesContact.save();
					} else if (values.type.indexOf('invoice') > -1) {
						const invoicingContact = new InvoicingContactModel({
							contactType: values.type,
							contactUrn: values.contact,
							enterprisePartitionUrn: appStore.selectedEnterprisePartition?.urn,
						});
						promise = invoicingContact.save();
					}

					await promise;

					setModalVisible(false);

					functionsStore.fetchClientContact();

					notification.success({
						description: 'Le point de contact a bien été ajouté',
						message: 'Point de contact ajouté',
					});
				}}
				open={modalVisible}
				salesContactCollection={salesContactCollection}
				salesContactTypeCollection={functionsStore.salesContactTypeCollection}
				width={650}
			/>

			<Row gutter={20}>
				{invoiceContactTypeCollection.map(contactType => {
					const serviceContacts = invoicingContactCollection.filter(invoicingContact => invoicingContact.contactTypeId === contactType.id);

					return (
						<Col
							key={contactType.iri}
							span={6}
						>
							<ClientServiceContactsBox
								contactCollection={clientContactCollection}
								invoicingContactCollection={invoicingContactCollection}
								label={contactType.label}
								salesContactCollection={salesContactCollection}
								serviceContacts={serviceContacts}
								type={contactType.iri}
							/>
						</Col>
					);
				})}
				{salesContactTypeCollection.map(salesContactType => {
					const serviceContacts = salesContactCollection.filter(salesContact => salesContact.contactTypeId === salesContactType.id);

					return (
						<Col
							key={salesContactType.iri}
							span={6}
						>
							<ClientServiceContactsBox
								contactCollection={clientContactCollection}
								invoicingContactCollection={invoicingContactCollection}
								label={salesContactType.label}
								salesContactCollection={salesContactCollection}
								serviceContacts={serviceContacts}
								type={salesContactType.iri}
							/>
						</Col>
					);
				})}
			</Row>
		</div>
	);
};

export default observer(ClientSection);
