import { ApiCollection }                                                 from 'Collections/ApiCollection';
import Card                                                              from 'antd/lib/card';
import { CardProps }                                                     from 'antd/lib/card';
import Empty                                                             from 'antd/lib/empty';
import { ColumnsType }                                                   from 'antd/lib/table/interface';
import AbstractInterventionListServiceCell                               from 'entityComponents/Intervention/AbstractInterventionListServiceCell';
import { observer }                                                      from 'mobx-react';
import React, { useEffect, useState }                                    from 'react';
import TablePaginationCollection                                         from '../../components/TablePaginationCollection';
import UrnFilter                                                         from '../../components/filters/UrnFilter';
import InterventionModel                                                 from '../../modelx/models/private/intervention/InterventionModel';
import { appStore, interventionsStore }                                  from '../../stores';
import AbstractInterventionListTechnicianCell                            from './AbstractInterventionListTechnicianCell';
import AbstractInterventionTaskZoneName                                  from './AbstractInterventionTaskZoneName';

interface AbstractInterventionListProps {
	cardProps: CardProps;
	collection: ApiCollection<InterventionModel>;
	customColumns?: (defaultColumns: ColumnsType<InterventionModel>) => ColumnsType<InterventionModel>;
}

function AbstractInterventionList(props: AbstractInterventionListProps) {
	const { cardProps, collection, customColumns } = props;

	const [filtersCollection, setFiltersCollection] = useState<ApiCollection<InterventionModel> | null>(null);

	useEffect(() => {
		if (collection.isLoaded && !filtersCollection) {
			setFiltersCollection(collection.clone());
		}
	}, [collection.isLoaded]);

	const defaultColumns: ColumnsType<InterventionModel> = [
		{
			key: 'startDate',
			render: (_v, intervention) => intervention.scheduleStartDateMoment.format('L [à] LT'),
			sorter: true,
			title: 'Date',
			width: 400,
		},
		{
			filters: interventionsStore.operationCategoryCollection.map(operationCategory => ({
				text: operationCategory.label,
				value: operationCategory.iri,
			})),
			key: 'interventionOperations.taskOperation.operation.operationCategory',
			render: (_v, intervention) => (
				<AbstractInterventionListServiceCell
					intervention={intervention}
					interventionOperationCollection={interventionsStore.interventionOperationCollection}
					operationCategoryCollection={interventionsStore.operationCategoryCollection}
					operationCollection={interventionsStore.operationCollection}
					taskOperationCollection={interventionsStore.taskOperationCollection}
				/>
			),
			title: 'Service',
			width: 400,
		},
		{
			key: 'employee',
			render: (_v, intervention) => (
				<AbstractInterventionListTechnicianCell
					intervention={intervention}
					interventionResourceCollection={interventionsStore.interventionResourceCollection}
					ownerResourceCollection={interventionsStore.ownerResourceCollection}
					resourceCollection={interventionsStore.resourceCollection}
					staffCollection={interventionsStore.staffCollection}
					staffMemberCollection={interventionsStore.staffMemberCollection}
				/>
			),
			title: 'Technicien',
			width: 400,
		},
		{
			filters: filtersCollection?.reduce((acc, intervention) => {
				if (!acc.some(val => val.value === intervention.taskZone.urn)) {
					acc.push({
						text: intervention.taskZone.taskZoneName,
						value: intervention.taskZone.urn,
					});
				}
				return acc;
			}, []),
			key: 'taskZone',
			render: (_v, intervention) => <AbstractInterventionTaskZoneName intervention={intervention} />,
			title: 'Site',
			width: 400,
		},
	];

	const columns = customColumns ? customColumns(defaultColumns) : defaultColumns;

	if (
		!appStore.contractIterationCollection.length
		&& !appStore.quotationCollection.length
	) {
		return (
			<Empty
				description="Aucun contrat"
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					justifyContent: 'center',
				}}
			/>
		);
	}

	const isLoading = collection.isLoading;

	return (
		<div>
			<UrnFilter />

			<Card
				className="card-with-table"
				{...cardProps}
			>
				<TablePaginationCollection
					collection={collection}
					columns={columns}
					loading={isLoading}
					rowKey={intervention => intervention.id}
				/>
			</Card>
		</div>
	);
}

export default observer(AbstractInterventionList);
