import { ApiCollection }                           from 'Collections/ApiCollection';
import InterventionNotificationValidationModel     from 'Models/intervention/InterventionNotificationValidationModel';
import Alert                                       from 'antd/lib/alert';
import Button                                      from 'antd/lib/button';
import Card                                        from 'antd/lib/card';
import Col                                         from 'antd/lib/col';
import Row                                         from 'antd/lib/row';
import Space                                       from 'antd/lib/space';
import { Buffer }                                  from 'buffer';
import LogoComponent                               from 'components/LogoComponent';
import { Page }                                    from 'components/Page';
import { PUBLIC_PARTITION_META_MAIL_FROM_ADDRESS } from 'constants/InterventionPublicPartitionMetaReferences';
import { observer }                                from 'mobx-react';
import moment                                      from 'moment';
import Proto                                       from 'proto/proto';
import { useParams }                               from 'react-router-dom';
import React                                       from 'react';
import ConfigProxy                                 from 'tools/ConfigProxy';
import notificationApiError                        from 'tools/notificationApiError';
import { notificationSuccess }                     from 'tools/notification';
import PublicPartitionMetaModel                    from '../../modelx/models/public/intervention/PublicPartitionMetaModel';
import './InterventionValidationPage.scss';

const { InterventionNotificationValidationData } = Proto.Service.Intervention;

const InterventionValidationPage = () => {
	const { token } = useParams() as { token: string; };

	// const interventionNotificationValidation = new InterventionNotificationValidationModel();
	const interventionNotificationValidation = React.useMemo(() => new InterventionNotificationValidationModel(), []);

	// const publicPartitionMetaCollection = new ApiCollection(PublicPartitionMetaModel);
	const publicPartitionMetaCollection = React.useMemo(() => new ApiCollection(PublicPartitionMetaModel), []);

	const [confirmed, setConfirmed] = React.useState(false);

	React.useEffect(() => {
		interventionNotificationValidation.setId(token);

		interventionNotificationValidation
			.fetch()
			.then(() => {
				// const data = InterventionNotificationValidationData.decode(
				// 	Buffer.from(interventionNotificationValidation.data, 'base64'),
				// );
				// console.log('data', data);

				publicPartitionMetaCollection.setFilters({
					partitionUrn: ConfigProxy.get('PARTITION_URN'),
					reference: PUBLIC_PARTITION_META_MAIL_FROM_ADDRESS,
				});
				publicPartitionMetaCollection.list();
			})
			.catch(() => {
				// we do nothing
			});
	}, []);

	const _renderCardContent = React.useCallback(() => {
		const data = InterventionNotificationValidationData.decode(
			Buffer.from(interventionNotificationValidation.data, 'base64'),
		);

		const scheduleStartDateSeconds = data.intervention?.scheduleStartDate?.seconds
			? data.intervention?.scheduleStartDate?.seconds as number
			: null;

		const firstPublicPartitionMeta = publicPartitionMetaCollection.first();

		return (
			<Space
				direction="vertical"
				size={24}
				style={{ width: '50%' }}
			>
				<div className="InterventionValidationPage__title">
					Confirmer le rendez-vous
					{!!scheduleStartDateSeconds && (
						<>
							<br />
							Le {moment.unix(scheduleStartDateSeconds).format('DD/MM/YYYY')} à {moment.unix(scheduleStartDateSeconds).format('HH[h]mm')}
						</>
					)}
				</div>
				<div>
					<div className="label">
						Prestations
					</div>
					<div>
						{data.operations.map(operation => operation.label).join(', ')}
					</div>
				</div>
				<div>
					<div className="label">
						{`Lieu d'intervention`}
					</div>
					<div>
						{data.taskZone?.name}<br />
						{data.taskZone?.address}<br />
						{data.taskZone?.zipCode} {data.taskZone?.city}
					</div>
				</div>
				<div>
					<div className="label">
						Intervenants
					</div>
					<div>
						{data.technicians.map(technician => (
							<div key={technician.lastName}>
								{technician.lastName?.toUpperCase()} {technician.firstName}
							</div>
						))}
					</div>
				</div>
				<div>
					<div className="label">
						Véhicules
					</div>
					<div>
						{data.vehicles.map(vehicle => (
							<div key={vehicle.name}>
								{vehicle.licensePlate}
							</div>
						))}
					</div>
				</div>
				<div>
					{`Merci de bien vouloir confirmer votre accord et de faciliter l'accès aux sites aux intervenants.`}
				</div>
				<div>
					{confirmed ? (
						<Alert
							message="Intervention confirmée"
							type="success"
						/>
					) : (
						<Button
							block
							onClick={() => {
								const interventionNotificationValidation = new InterventionNotificationValidationModel({
									token,
								});

								interventionNotificationValidation
									.save()
									.then(() => {
										notificationSuccess({ message: 'Confirmation enregistrée !' });
										setConfirmed(true);
									})
									.catch(notificationApiError);
							}}
							size="large"
							type="primary"
						>
							Confirmer le rendez-vous
						</Button>
					)}
				</div>
				{firstPublicPartitionMeta && (
					<div>
						{`En cas d'empêchement, veuillez nous contacter au plus tôt sur `}
						<strong><a href={`mailto:${firstPublicPartitionMeta.value}`}>{firstPublicPartitionMeta.value}</a></strong>
					</div>
				)}

			</Space>
		);
	}, [confirmed]);

	return (
		<Page
			title="Confirmation du rendez-vous"
		>
			<Row
				className="InterventionValidationPage"
				gutter={20}
			>
				<Col
					span={24}
					style={{ textAlign: 'center' }}
				>
					<LogoComponent />
				</Col>

				<Col
					md={{
						offset: 4,
						span: 16,
					}}
					xs={{
						offset: 2,
						span: 20,
					}}
				>
					<Card
						loading={interventionNotificationValidation.isLoading}
					>
						{!interventionNotificationValidation.isLoaded ? (
							<div>
								{`Le rendez-vous n'existe pas ou a déjà été validé.`}
							</div>
						) : _renderCardContent()}
					</Card>
				</Col>
			</Row>
		</Page>
	);
};

export default observer(InterventionValidationPage);
