import FilePrivateApiModel from '../../abstracts/FilePrivateApiModel';

export default class FileSourceModel extends FilePrivateApiModel {
	public filters: ModelFiltersExtended<{
		'entityUrn': id;
	}> = {};

	// public sorts: ModelSortsExtended<{
	// 	'id': string;
	// 	'updatedAt': string;
	// }> = {};

	static get path(): string {
		return '/stonecode_file_sources/{?id}';
	}

	public get entityUrn() {
		return this.get('entityUrn');
	}
}
