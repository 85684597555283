import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export default class InvoiceExportZipModel extends InvoicePrivateApiModel {
	public filters: ModelFiltersExtended<{
		'billingPeriod': id;
		'invoiceInvoiceTags.invoiceTag.reference': string
	}> = {};

	static get path(): string {
		return '/invoices/export/zip';
	}
}
