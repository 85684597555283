import { ApiCollection }         from 'Collections/ApiCollection';
import OwnerResourceModel        from 'Models/intervention/OwnerResourceModel';
import ResourceModel             from 'Models/intervention/ResourceModel';
import Skeleton                  from 'antd/lib/skeleton';
import { observer }              from 'mobx-react';
import React                     from 'react';
import InterventionModel         from '../../modelx/models/private/intervention/InterventionModel';
import InterventionResourceModel from '../../modelx/models/private/intervention/InterventionResourceModel';
import StaffMemberModel          from '../../modelx/models/private/rh/StaffMemberModel';
import StaffModel                from '../../modelx/models/private/rh/StaffModel';

const AbstractInterventionListTechnicianCell = ({
	intervention,
	interventionResourceCollection,
	ownerResourceCollection,
	resourceCollection,
	staffCollection,
	staffMemberCollection,
}: {
	intervention: InterventionModel;
	interventionResourceCollection: ApiCollection<InterventionResourceModel>;
	ownerResourceCollection: ApiCollection<OwnerResourceModel>;
	resourceCollection: ApiCollection<ResourceModel>;
	staffCollection: ApiCollection<StaffModel>;
	staffMemberCollection: ApiCollection<StaffMemberModel>;
}) => {
	if (
		interventionResourceCollection.isLoading
		|| resourceCollection.isLoading
		|| ownerResourceCollection.isLoading
		|| staffMemberCollection.isLoading
		|| staffCollection.isLoading
	) {
		return <Skeleton active paragraph={false} />;
	}

	const interventionResources = interventionResourceCollection.filter(ir => ir.interventionId === intervention.id);

	const resources = resourceCollection.filter(r => !!interventionResources.find(ir => ir.resourceId === r.id));

	const ownerResources = ownerResourceCollection.filter(or => !!resources.find(r => r.ownerResourceId === or.id));

	const staffMembers = staffMemberCollection.filter(sm => !!ownerResources.find(or => or.entityId === sm.id));

	const staffs = staffCollection.filter(s => !!staffMembers.find(sm => sm.staffId === s.id));

	return <>{staffs.map(s => s.fullName).join(', ')}</>;
};

export default observer(AbstractInterventionListTechnicianCell);
