import Form              from 'antd/lib/form';
import Input             from 'antd/lib/input';
import _debounce         from 'lodash/debounce';
import React             from 'react';
import { contactsStore } from 'stores/index';

interface SearchFilterProps {
	name: string;
}

const onSubmit = async (name: string, value: string): Promise<void> => {
	await contactsStore.setContactSearchFilterAndFetch(value);
};

const ContactSearchFilter: React.FC<SearchFilterProps> = props => {
	const { name } = props;

	const onChange = _debounce(value => onSubmit(name, value), 250);

	return (
		<Form.Item label="Recherche">
			<Input.Search
				onChange={e => onChange(e.target.value)}
				placeholder="Recherche"
			/>
		</Form.Item>
	);
};

export default ContactSearchFilter;