import { PlusOutlined }                          from '@ant-design/icons';
import ContactWithGlobalContactModel             from 'Models/directory/ContactWithGlobalContactModel';
// import { ApiCollection }                      from 'Collections/ApiCollection';
import { ColumnsType }                           from 'antd/lib/table/interface';
import { Button, Card, notification }            from 'antd';
import { Page }                                  from 'components/Page';
import { observer }                              from 'mobx-react';
import AbstractApiModel                          from 'modelx/models/abstracts/AbstractApiModel';
import React, { useState }                       from 'react';
import { functionsStore }                        from 'stores/index';
import { appStore, contactsStore }               from 'stores/index';
import TablePaginationCollection                 from '../../../components/TablePaginationCollection';
import ContactModalForm                          from '../../../forms/ContactModalForm';
import ContactModel                              from '../../../modelx/models/private/directory/ContactModel';
import ContactDropDown                           from './ContactDropDown';
import ContactSearchFilter                       from './ContactSearchFilter';
import EnabledComp                               from './EnabledComp';

const ObserverLabel = observer(({ model, property }: {model:AbstractApiModel, property: string }) => {
	return <span>{model[property]}</span>;
});

const columns: ColumnsType<ContactModel> = [
	{
		key: 'lastName',
		render: (_v, contact) => <ObserverLabel model={contact} property="lastName" />,
		sorter: true,
		title: 'Nom',
	},
	{
		key: 'firstName',
		render: (_v, contact) => <ObserverLabel model={contact} property="firstName" />,
		sorter: true,
		title: 'Prénom',
	},
	{
		key: 'hasUser',
		render: (_v, contact) => <EnabledComp contact={contact} />,
		title: 'Accès à l\'interface',
	},
	{
		align: 'right',
		fixed: 'right',
		render: (_v, contact) => <ContactDropDown contact={contact} />,
		title: '',
	},

];

function ContactsListPage() {
	const [addContactVisible, setAddContactVisible] = useState(false);

	const {
		contactCollection,
	} = contactsStore;

	return (
		<Page title="Liste des contacts">
			<div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
				<ContactSearchFilter name="contactSearchFilter" />
			</div>

			<Card
				className="card-with-table"
				extra={(
					<Button
						onClick={() => setAddContactVisible(true)}
						type="primary"
					>
						<PlusOutlined />
						Ajouter un contact
					</Button>
				)}
				title="Contacts de l'entreprise"
			>
				<TablePaginationCollection
					collection={contactCollection}
					columns={columns}
					rowKey={contact => contact.id}
				/>
			</Card>

			{/*Ajouter un contact*/}
			<ContactModalForm
				onCancel={() => {
					setAddContactVisible(false);
				}}
				onSubmit={async (values) => {
					const contact = new ContactWithGlobalContactModel();
					const { email, firstName, gender, lastName, phoneNumber, phoneNumberBis, ...contactValues } = values;

					contact.set({
						enabled: true,
						globalContactDto: {
							email, firstName, gender, lastName, phoneNumber, phoneNumberBis,
						},
						...contactValues,
						enterprisePartition: appStore.selectedEnterprisePartition?.urn,
					});

					await contact.save();

					contactCollection.list();

					functionsStore.fetchClientContact();

					notification.success({
						description: 'Le contact a bien été ajouté à l\'entreprise',
						message: 'Contact ajouté',
					});

					setAddContactVisible(false);
				}}
				open={addContactVisible}
			/>
		</Page>
	);
}

export default observer(ContactsListPage);
