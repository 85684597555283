import { computed, override }         from 'mobx';
import SalesPrivateApiModel           from '../../abstracts/SalesPrivateApiModel';

export default class ContractIterationStatusHistoryModel extends SalesPrivateApiModel {
	public filters: ModelFiltersExtended<{
		contractIteration: id;
	}> = {};

	static get path(): string {
		return '/contract_iteration_status_histories/{?id}';
	}

	@computed
	public get comment(): string {
		return this.get('comment');
	}

	@override
	public get createdAt(): string {
		return this.get('createdAt');
	}

	@computed
	public get createdBy(): string {
		return this.get('createdBy');
	}

	@computed
	public get contractIterationStatusId(): number {
		return this.get('contractIterationStatus.id');
	}

	@computed
	public get rejectionReason(): string {
		return this.get('rejectionReason', '');
	}
}
