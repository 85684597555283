import { computed }         from 'mobx';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

export default class FrameworkAgreementIterationQuotationOptionModel extends SalesPrivateApiModel {
	static get path(): string {
		return '/framework_agreement_iteration_quotation_options/{?id}';
	}

	public filters: ModelFiltersExtended<{
		'frameworkAgreementIteration': id;
		'quotationOption.quotationOptionEntity.entityMask': id;
	}> = {};

	// public _sorts: ModelSortsExtended<{
	// 	'id': string;
	// 	'quotationOption.label': string;
	// 	'updatedAt': string;
	// }> = {};

	@computed
	public get frameworkAgreementIterationId(): number {
		return this.get('frameworkAgreementIteration.id');
	}

	@computed
	public get quotationOptionId(): number {
		return this.get('quotationOption.id');
	}
}
