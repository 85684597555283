import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class TaskZoneGroupModel extends InterventionPrivateApiModel {
	public filters: ModelFiltersExtended<{
		'taskZone.owner.clientUrn': string;
		'taskZones': id;
		'taskZones.contractIterationItemActivities': string;
	}> = {};

	static get path(): string {
		return '/task_zone_groups/{?id}';
	}
	
	@computed
	public get name(): string {
		return this.get('name');
	}
}
