import { observer }                                                from 'mobx-react';
import { useMatch }                                                from 'react-router-dom';
import React                                                       from 'react';
import { getUrlParams }                                            from 'tools/urlParamsTools';
import ExportPageInterventionSubComponent                          from './ExportPageInterventionSubComponent';
import ExportPageSalesSubComponent                                 from './ExportPageSalesSubComponent';
import ExportPageSubComponent                                      from './ExportPageSubComponent';

const ExportPage = () => {
	const match = useMatch('/exports/:exportId');
	const urlParams = getUrlParams();

	if (urlParams.service && urlParams.service === 'intervention') {
		return (
			<ExportPageInterventionSubComponent
				match={match}
			/>
		);
	}

	if (urlParams.service && urlParams.service === 'sales') {
		return (
			<ExportPageSalesSubComponent
				match={match}
			/>
		);
	}

	return (
		<ExportPageSubComponent
			match={match}
		/>
	);
};

export default observer(ExportPage);
