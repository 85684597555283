import { resolvable }                from '@mathquis/modelx-resolvables';
import TaskModel                     from 'Models/intervention/TaskModel';
import QuotationItemModel            from 'Models/sales/QuotationItemModel';
import { computed }                  from 'mobx';
import moment                        from 'moment';
import SalesPrivateApiModel          from '../../abstracts/SalesPrivateApiModel';

export default class QuotationItemEventModel extends SalesPrivateApiModel {

	static get path(): string {
		return '/quotation_item_events/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'quotationItem': id;
		'quotationItem.quotation': id;
		'quotationItemEventStatus.reference': string;
	}> = {};

	@resolvable(QuotationItemModel, { attributeName: 'quotationItem' })
	declare quotationItem: QuotationItemModel;

	@computed
	public get scheduleEndDate(): Moment {
		return moment(this.get('scheduleEndDate', moment()));
	}

	@computed
	public get scheduleStartDate(): Moment {
		return moment(this.get('scheduleStartDate', moment()));
	}

	@resolvable(TaskModel, { attributeName: 'taskUrn' })
	declare task: TaskModel;
}
