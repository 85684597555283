import { DeleteOutlined, EllipsisOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ApiCollection }                                                             from 'Collections/ApiCollection';
import Card                                                                          from 'antd/lib/card';
import Dropdown                                                                      from 'antd/lib/dropdown';
import Empty                                                                         from 'antd/lib/empty';
import List                                                                          from 'antd/lib/list';
import Modal                                                                         from 'antd/lib/modal';
import notification                                                                  from 'antd/lib/notification';
import { observer }                                                                  from 'mobx-react';
import React, { useState }                                                           from 'react';
import { appStore, functionsStore }                                                  from 'stores/index';
import ClientContactModalForm                                                        from '../../../forms/ClientContactModalForm';
import ContactModel                                                                  from '../../../modelx/models/private/directory/ContactModel';
import InvoicingContactModel                                                         from '../../../modelx/models/private/invoice/InvoicingContactModel';
import SalesContactModel                                                             from '../../../modelx/models/private/sales/SalesContactModel';
import notificationApiError                                                          from '../../../utils/notificationApiError';

function removeServiceContact(serviceContact: InvoicingContactModel|SalesContactModel) {
	Modal.confirm({
		cancelText: 'Annuler',
		icon: <ExclamationCircleOutlined />,
		okText: 'Supprimer',
		onOk: async () => {
			try {
				await serviceContact.destroy();

				functionsStore.fetchClientContact();

				notification.success({
					message: 'Point de contact supprimé'
				});
			} catch (err) {
				notificationApiError(err);
			}
		},
		title: 'Voulez-vous vraiment supprimer cet élément ?',
		width: 450,
	});
}

// function removeServiceContacts(serviceContact: InvoicingContactModel|SalesContactModel) {
// 	Modal.confirm({
// 		title: 'Voulez-vous vraiment supprimer ce point de contact ?',
// 		icon: <ExclamationCircleOutlined/>,
// 		cancelText: 'Annuler',
// 		okText: 'Supprimer',
// 		onOk: async () => {
// 			try {
// 				await serviceContact.destroy();
//
// 				notification.success({
// 					message: 'Point de contact supprimé',
// 					description: 'Le point de contact a bien été supprimé',
// 				});
// 			} catch (err) {
// 				notificationApiError(err);
// 			}
// 		}
// 	});
// }

const ClientServiceContactsBox = ({
	contactCollection,
	invoicingContactCollection,
	label,
	salesContactCollection,
	serviceContacts,
	type,
}: {
	contactCollection: ApiCollection<ContactModel>;
	invoicingContactCollection: ApiCollection<InvoicingContactModel>;
	label: string;
	salesContactCollection: ApiCollection<SalesContactModel>;
	serviceContacts: (InvoicingContactModel|SalesContactModel)[];
	type: string;
}) => {
	const [modalVisible, setModalVisible] = useState(false);

	// const services = appStore.serviceCollection.filter(s => dataPoint.serviceIds.includes(s.id));
	// const services = [];

	return (
		<Card
			extra={(
				<Dropdown
					menu={{
						items: [
							{
								key: 'add',
								label: (
									<><PlusOutlined /> Ajouter</>
								),
								onClick: () => setModalVisible(true)
							}
						]
					}}
					trigger={['click']}
				>
					<EllipsisOutlined
						style={{ fontSize: 20, verticalAlign: 'top' }}
					/>
				</Dropdown>
			)}
			style={{ height: 'calc(100% - 20px)' }}
			// loading={!functionsStore.isLoaded}
			title={label}
		>
			{/*{!!services.length && <div className="services">*/}
			{/*	Service&nbsp;*/}
			{/*	/!*{services.map((s, i) => (*!/*/}
			{/*	/!*    <span key={s.id}>*!/*/}
			{/*	/!*        {s.label.toLowerCase()}{services.length !== i + 1 ? ', ' : ''}*!/*/}
			{/*	/!*    </span>*!/*/}
			{/*	/!*))}*!/*/}
			{/*</div>}*/}

			{serviceContacts.length ? (
				<List>
					{serviceContacts.map(serviceContact => {
						const contact = contactCollection.find(contact => contact.id === serviceContact.contactId);

						return (
							<List.Item extra={(
								<Dropdown
									menu={{
										items: [
											{
												key: 'delete',
												label: <><DeleteOutlined /> Supprimer</>,
												onClick: () => {
													removeServiceContact(serviceContact);
												},
											}
										]
									}}
									trigger={['click']}
								>
									<EllipsisOutlined
										style={{ fontSize: 20, verticalAlign: 'top' }}
									/>
								</Dropdown>
							)} key={serviceContact?.id}>
								<List.Item.Meta
									description={contact?.email}
									title={contact?.fullName}
								/>
							</List.Item>
						);
					})}
				</List>
			) : (
				<Empty
					description="Aucun contact"
					image={Empty.PRESENTED_IMAGE_SIMPLE}
				/>
			)}

			<ClientContactModalForm
				formProps={{
					initialValues: {
						type,
					}
				}}
				invoiceContactTypeCollection={functionsStore.invoiceContactTypeCollection}
				invoicingContactCollection={invoicingContactCollection}
				onCancel={() => setModalVisible(false)}
				onSubmit={async values => {
					let promise;

					if (values.type.indexOf('sales') > -1) {
						const salesContact = new SalesContactModel({
							clientUrn: appStore.selectedClient?.urn,
							contactType: values.type,
							contactUrn: values.contact,
						});
						promise = salesContact.save();
					} else if (values.type.indexOf('invoice') > -1) {
						const invoicingContact = new InvoicingContactModel({
							contactType: values.type,
							contactUrn: values.contact,
							enterprisePartitionUrn: appStore.selectedEnterprisePartition?.urn,
						});
						promise = invoicingContact.save();
					}

					await promise;

					setModalVisible(false);

					functionsStore.fetchClientContact();

					notification.success({
						description: 'Le point de contact a bien été ajouté',
						message: 'Point de contact ajouté',
					});
				}}
				open={modalVisible}
				salesContactCollection={salesContactCollection}
				salesContactTypeCollection={functionsStore.salesContactTypeCollection}
				type={type}
			/>
		</Card>
	);
};

export default observer(ClientServiceContactsBox);
