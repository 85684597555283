import { ApiCollection }                               from 'Collections/ApiCollection';
import FrameworkAgreementIterationModel                from 'Models/sales/FrameworkAgreementIterationModel';
import FrameworkAgreementIterationQuotationOptionModel from 'Models/sales/FrameworkAgreementIterationQuotationOptionModel';
import FrameworkAgreementModel                         from 'Models/sales/FrameworkAgreementModel';
import QuotationOptionModel                            from 'Models/sales/QuotationOptionModel';
import Card                                            from 'antd/lib/card';
import { ColumnsType }                                 from 'antd/lib/table/interface';
import { Page }                                        from 'components/Page';
import TablePaginationCollection                       from 'components/TablePaginationCollection';
import FrameworkAgreementIterationStatusName           from 'entityComponents/FrameworkAgreementIterationStatusName';
import useTo                                           from 'hooks/useTo';
import { observer }                                    from 'mobx-react';
import { Link }                                        from 'react-router-dom';
import React                                           from 'react';
import { frameworkAgreementIterationsStore }           from 'stores/index';

const FrameworkAgreementIterationName = observer(({
	frameworkAgreementCollection,
	frameworkAgreementIteration,
}: {
	frameworkAgreementCollection: ApiCollection<FrameworkAgreementModel>;
	frameworkAgreementIteration: FrameworkAgreementIterationModel;
}) => {
	const to = useTo();
	const frameworkAgreement = frameworkAgreementCollection.findBy('id', frameworkAgreementIteration.frameworkAgreementId);

	let frameworkAgreementName = '';

	if (frameworkAgreement) {
		frameworkAgreementName += frameworkAgreement.label;

		if (frameworkAgreement.oldNumber) {
			frameworkAgreementName += ` (${frameworkAgreement.oldNumber})`;
		}
	}

	return (
		<Link to={to(`/framework_agreements/${frameworkAgreementIteration.id}`)}>
			{frameworkAgreementName}{!!frameworkAgreementName && ` - `}
			<span>version {frameworkAgreementIteration.number}</span>
		</Link>
	);
});

const FrameworkAgreementIterationFrameworkQuotationOptions = observer(({
	frameworkAgreementIteration,
	frameworkAgreementIterationQuotationOptionCollection,
	quotationOptionCollection,
}: {
	frameworkAgreementIteration: FrameworkAgreementIterationModel;
	frameworkAgreementIterationQuotationOptionCollection: ApiCollection<FrameworkAgreementIterationQuotationOptionModel>;
	quotationOptionCollection: ApiCollection<QuotationOptionModel>;
}) => {
	const FAIQOs = frameworkAgreementIterationQuotationOptionCollection.filter(faiqo => faiqo.frameworkAgreementIterationId === frameworkAgreementIteration.id);

	return <div>
		{FAIQOs.map(faiqo => {
			const quotationOption = quotationOptionCollection.find(qo => qo.id === faiqo.quotationOptionId);
			return quotationOption?.label || '';
		}).join(', ')}
	</div>;
});

const columns: ColumnsType<FrameworkAgreementIterationModel> = [
	{
		key: 'number',
		render: (_v, frameworkAgreementIteration) => <FrameworkAgreementIterationName
			frameworkAgreementCollection={frameworkAgreementIterationsStore.frameworkAgreementCollection}
			frameworkAgreementIteration={frameworkAgreementIteration}
		/>,
		sorter: true,
		title: 'Numéro',
	},
	{
		key: 'status',
		render: (_v, frameworkAgreementIteration) => <FrameworkAgreementIterationStatusName
			frameworkAgreementIteration={frameworkAgreementIteration}
			frameworkAgreementIterationStatusCollection={frameworkAgreementIterationsStore.frameworkAgreementIterationStatusCollection}
		/>,
		sorter: true,
		title: 'Etat',
	},
	{
		key: 'startDate',
		render: (_v, frameworkAgreementIteration) => frameworkAgreementIteration.startDate.format('L'),
		sorter: true,
		title: 'Début',
	},
	{
		key: 'endDate',
		render: (_v, frameworkAgreementIteration) => frameworkAgreementIteration.endDate.format('L'),
		sorter: true,
		title: 'Fin',
	},
	{
		key: 'options',
		render: (_v, frameworkAgreementIteration) => <FrameworkAgreementIterationFrameworkQuotationOptions
			frameworkAgreementIteration={frameworkAgreementIteration}
			frameworkAgreementIterationQuotationOptionCollection={frameworkAgreementIterationsStore.frameworkAgreementIterationQuotationOptionCollection}
			quotationOptionCollection={frameworkAgreementIterationsStore.quotationOptionCollection}
		/>,
		title: 'Options',
	},
];

@observer
class FrameworkAgreementIterationListPage extends React.Component {
	public componentDidMount() {
		frameworkAgreementIterationsStore.fetch();
	}

	public render() {
		return (
			<Page title="Mes accords cadres">
				<div>
					<Card className="card-with-table" title="Mes accords cadres">
						<TablePaginationCollection
							collection={frameworkAgreementIterationsStore.frameworkAgreementIterationCollection}
							columns={columns}
							rowKey={frameworkAgreementIteration => frameworkAgreementIteration.id}
						/>
					</Card>
				</div>
			</Page>
		);
	}
}

export default FrameworkAgreementIterationListPage;
