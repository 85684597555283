import { computed }             from 'mobx';
import DirectoryPrivateApiModel from 'modelx/models/abstracts/DirectoryPrivateApiModel';

export default class ContactLoginModel extends DirectoryPrivateApiModel {
	static get path(): string {
		return '/contacts/_login';
	}

	@computed
	public get token(): string {
		return this.get('token', '');
	}

	@computed
	public get refreshToken(): string {
		return this.get('refresh_token', '');
	}
}
