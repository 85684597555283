import Col                from 'antd/lib/col';

import Row                from 'antd/lib/row';
import Skeleton           from 'antd/lib/skeleton';
import classNames         from 'classnames';
import GridInfoEmptyValue from 'components/GridInfo/GridInfoEmptyValue';
import React, {
	CSSProperties,
	ReactNode,
} from 'react';

import { formatPrice }    from 'tools/PriceHelper';

import formatPhoneNumber  from 'tools/formatPhoneNumber';
import ColorValue         from '../ColorValue/ColorValue';
import GridInfoArrayValue from './GridInfoArrayValue';
import GridInfoDateValue  from './GridInfoDateValue';
import './GridInfo.scss';

export type GridInfoItemType = 'default' | 'color' | 'date' | 'dateTime' | 'phone' | 'array' | 'boolean' | 'html' |
	'price';

export type IGridInfoItem = {
	label?: ReactNode;
	loading?: boolean;
	type?: GridInfoItemType;
	value: ReactNode;
};

export interface IGridInfoProps {
	data: IGridInfoItem[][];
	labelVertical?: boolean;
	spaceVertical?: number;
	style?: CSSProperties;
}

class GridInfo extends React.Component<IGridInfoProps> {
	public render() {
		const { data, labelVertical, style } = this.props;

		const columns = data.map((column, colKey) => {
			return (
				<Col flex={1} key={colKey}>
					<div className="GridInfo__table">
						{column.map((item, itemKey) => (
							<div key={itemKey}>
								{!!item.label && (
									<div>
										<span className="GridInfo__label">{item.label}</span>
									</div>
								)}

								<div>
									{this._renderValue(item)}
								</div>
							</div>
						))}
					</div>
				</Col>
			);
		});

		return (
			<div className={classNames('GridInfo', { labelVertical })} style={style}>
				<Row gutter={[20, 20]} style={{ flex: 1 }}>
					{columns}
				</Row>
			</div>
		);
	}

	private _renderEmptyValue = () => <GridInfoEmptyValue />;

	private _renderValue = (item: IGridInfoItem): ReactNode => {
		if (item.loading) {
			return <Skeleton active={true} paragraph={false} />;
		}

		if (!item.value && item.type !== 'boolean' && item.type !== 'price') {
			return this._renderEmptyValue();
		}

		return (
			<span className="GridInfo__value">
				{this._renderValueFromItem(item)}
			</span>
		);
	};

	private _renderValueFromItem(item: IGridInfoItem): ReactNode {
		switch (item.type) {
			case 'array':
				return (item.value as []).length ? (
					<GridInfoArrayValue items={item.value as []} />
				) : this._renderEmptyValue();

			case 'color':
				return <ColorValue color={item.value?.toString()} />;

			case 'price':
				return <span>{formatPrice(item.value as number || 0)}</span>;

			case 'date':
				return <GridInfoDateValue value={item.value as string} />;

			case 'dateTime':
				return <GridInfoDateValue dateTime={true} value={item.value as string} />;

			case 'phone':
				return (
					<a href={`tel:${item.value}`}>
						{formatPhoneNumber(item.value as string)}
					</a>
				);

			case 'boolean':
				return item.value ? 'Oui' : 'Non';

			case 'html':
				return (
					<div className="typeHtml" dangerouslySetInnerHTML={{ __html: item.value?.toString() || '' }}>
					</div>
				);

			default:
				return item.value;
		}
	}
}

export default GridInfo;