import ContractIterationStatusModel from '../modelx/models/private/sales/ContractIterationStatusModel';

export const CONTRACT_ITERATION_STATUS_BEING_CREATED = {
	label: 'En cours de création',
	reference: 'being_created',
};

export const CONTRACT_ITERATION_STATUS_CANCELED = {
	label: 'Annulé',
	reference: 'canceled',
};

export const CONTRACT_ITERATION_STATUS_IN_VALIDATION = {
	label: 'En validation',
	reference: 'in_validation',
};

export const CONTRACT_ITERATION_STATUS_VALIDATED = {
	label: 'Validé',
	reference: 'validated',
};

export const CONTRACT_ITERATION_STATUS_SENT_TO_CLIENT = {
	label: 'Envoyé au client',
	reference: 'sent_to_client',
};

export const CONTRACT_ITERATION_STATUS_VALIDATED_BY_CLIENT = {
	label: 'Validé par le client',
	reference: 'validated_by_client',
};

export const CONTRACT_ITERATION_STATUS_PRE_ACTIVATED = {
	label: 'Pré-activé',
	reference: 'pre_activated',
};

export const CONTRACT_ITERATION_STATUS_ACTIVATED = {
	label: 'Activé',
	reference: 'activated',
};

export const CONTRACT_ITERATION_STATUS_SUSPENDED = {
	label: 'Suspendu',
	reference: 'suspended',
};

export const CONTRACT_ITERATION_STATUS_CLOSED = {
	label: 'Clôturé',
	reference: 'closed',
};

export const CONTRACT_ITERATION_STATUS_TERMINATED = {
	label: 'Terminé',
	reference: 'terminated',
};

export const CONTRACT_ITERATION_STATUS_BLOCKED = {
	label: 'Bloqué',
	reference: 'blocked',
};


export const CONTRACT_ITERATION_STATUS_TO_REGULARIZE = {
	label: 'À régulariser',
	reference: 'to_regularize',
};

export const CONTRACT_ITERATION_STATUS_INVOICING = {
	label: 'En cours d\'envoi pour paiement',
	reference: 'invoicing',
};

export const CONTRACT_ITERATION_STATUS_INVOICED = {
	label: 'Envoyé pour paiement',
	reference: 'invoiced',
};

export const CONTRACT_ITERATION_STATUS_PAID = {
	label: 'Payé',
	reference: 'paid',
};

export const contractIterationPreviewableStatuses = [
	CONTRACT_ITERATION_STATUS_BEING_CREATED,
	CONTRACT_ITERATION_STATUS_IN_VALIDATION,
	CONTRACT_ITERATION_STATUS_VALIDATED,
];

export const getContractIterationStatusColor = (contractIterationStatus: ContractIterationStatusModel) => {
	switch (contractIterationStatus.reference) {
		case CONTRACT_ITERATION_STATUS_BEING_CREATED.reference:
		case CONTRACT_ITERATION_STATUS_CANCELED.reference:
		case CONTRACT_ITERATION_STATUS_IN_VALIDATION.reference:
		case CONTRACT_ITERATION_STATUS_VALIDATED.reference:
		case CONTRACT_ITERATION_STATUS_VALIDATED_BY_CLIENT.reference:
		case CONTRACT_ITERATION_STATUS_PRE_ACTIVATED.reference:
		case CONTRACT_ITERATION_STATUS_BLOCKED.reference:
			return 'grey';
		case CONTRACT_ITERATION_STATUS_CLOSED.reference:
			return 'red';
		case CONTRACT_ITERATION_STATUS_SENT_TO_CLIENT.reference:
			return 'blue';
		case CONTRACT_ITERATION_STATUS_SUSPENDED.reference:
			return 'orange';
		case CONTRACT_ITERATION_STATUS_ACTIVATED.reference:
			return 'green';
		case CONTRACT_ITERATION_STATUS_TERMINATED.reference:
			return 'purple';
	}
};


export const groupedStatuses = {
	blocked: {
		label: 'Bloqué',
		statuses: [
			CONTRACT_ITERATION_STATUS_SUSPENDED,
			CONTRACT_ITERATION_STATUS_BLOCKED,
		],
	},
	closed: {
		label: 'Clôturé',
		statuses: [
			CONTRACT_ITERATION_STATUS_CLOSED,
			CONTRACT_ITERATION_STATUS_TERMINATED,
		],
	},
	enabled: {
		label: 'Activé',
		statuses: [
			CONTRACT_ITERATION_STATUS_ACTIVATED,
			CONTRACT_ITERATION_STATUS_TO_REGULARIZE,
			CONTRACT_ITERATION_STATUS_INVOICING,
			CONTRACT_ITERATION_STATUS_INVOICED,
			CONTRACT_ITERATION_STATUS_PAID,
		],
	},
	sent_to_client: {
		label: 'Envoyé au client',
		statuses: [
			CONTRACT_ITERATION_STATUS_SENT_TO_CLIENT,
			CONTRACT_ITERATION_STATUS_VALIDATED_BY_CLIENT,
			CONTRACT_ITERATION_STATUS_PRE_ACTIVATED,
		],
	},
};

export const defaultContractGroupNames: (keyof typeof groupedStatuses)[] = ['enabled', 'blocked', 'sent_to_client'];
export const defaultContractReferences = defaultContractGroupNames
	.map(name => groupedStatuses[name].statuses)
	.flat()
	.map(item => item.reference);

export default {
	CONTRACT_ITERATION_STATUS_ACTIVATED,
	CONTRACT_ITERATION_STATUS_BEING_CREATED,
	CONTRACT_ITERATION_STATUS_BLOCKED,
	CONTRACT_ITERATION_STATUS_CANCELED,
	CONTRACT_ITERATION_STATUS_CLOSED,
	CONTRACT_ITERATION_STATUS_IN_VALIDATION,
	CONTRACT_ITERATION_STATUS_PRE_ACTIVATED,
	CONTRACT_ITERATION_STATUS_SENT_TO_CLIENT,
	CONTRACT_ITERATION_STATUS_SUSPENDED,
	CONTRACT_ITERATION_STATUS_TERMINATED,
	CONTRACT_ITERATION_STATUS_VALIDATED,
	CONTRACT_ITERATION_STATUS_VALIDATED_BY_CLIENT,
};