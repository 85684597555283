import FileModel    from 'Models/file/FileModel';

const InvoiceMenu = ({
	invoicePdfFile,
}: {
	invoicePdfFile: FileModel | null;
}) => {
	return { 
		items: [
			{
				disabled: !invoicePdfFile,
				key: 'download',
				label: 'Télécharger',
				onClick: () => invoicePdfFile?.download(),
			}
		]
	};
};

export default InvoiceMenu;
