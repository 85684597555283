import { computed }         from 'mobx';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

export default class ActivityTypeModel extends SalesPrivateApiModel {
	static get path(): string {
		return '/activity_types/{?id}';
	}

	public filters: ModelFiltersExtended<{
		'accountingAnalyticCodeUrn': string;
		'accountingCodeUrn': string;
		'activities': id;
		'activities.partitionUrn': string;
		'archived': '0' | '1';
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	// public _sorts: ModelSortsExtended<{
	// 	'id': string;
	// 	'label': string;
	// 	'updatedAt': string;
	// }> = {};

	@computed
	public get archived(): boolean {
		return this.get('archived');
	}

	@computed
	public get description(): string {
		return this.get('description');
	}

	@computed
	public get label(): boolean {
		return this.get('label');
	}
}
