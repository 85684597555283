import Card          from 'antd/lib/card';
import Col           from 'antd/lib/col';
import Row           from 'antd/lib/row';
import Statistic     from 'antd/lib/statistic';
import { observer }  from 'mobx-react';
import React         from 'react';
import { homeStore } from 'stores/index';

const StatisticBoxItem = observer(({ collection, title }) => {
	return (
		<Statistic
			loading={collection.isLoading}
			title={title}
			value={collection.length}
		/>
	);
});

const StatisticBox = () => {
	return (
		<Card
			className="home-statistics"
			// loading={homeStore.isLoading}
			title="Résumé"
		>
			<Row gutter={16} style={{ textAlign: 'center' }}>
				<Col span={12}>
					<StatisticBoxItem
						collection={homeStore.activatedContractIterationCollection}
						title="Contrats actifs"
					/>
				</Col>
				<Col span={12}>
					<StatisticBoxItem
						collection={homeStore.activatedQuotationCollection}
						title="Devis actifs"
					/>
				</Col>
				{/*<Col span={12}>*/}
				{/*	<Statistic*/}
				{/*		title="Avenants"*/}
				{/*		// value={homeStore.amendmentCollection.total}*/}
				{/*		value="@todo"*/}
				{/*	/>*/}
				{/*</Col>*/}
				<Col span={12}>
					<StatisticBoxItem
						collection={homeStore.doneInterventionCollection}
						title="Interventions effectuées"
					/>
				</Col>
			</Row>
		</Card>
	);
};

export default StatisticBox;
