import Button          from 'antd/lib/button';
import Result          from 'antd/lib/result';
import { useNavigate } from 'react-router-dom';
import React           from 'react';

export default function NoMatchPage() {
	const navigate = useNavigate();

	return (
		<Result
			extra={
				<Button
					onClick={() => navigate('/', { replace: true })}
					type="primary"
				>
					{`Retour à l'accueil`}
				</Button>
			}
			status="404"
			subTitle="Désolé, la page que vous recherchez semble introuvable."
			title="404"
		/>
	);
}
