import { ApiCollection }                   from 'Collections/ApiCollection';
import Tooltip                             from 'antd/lib/tooltip';
import { getContractIterationStatusColor } from 'constants/ContractIterationStatuses';
import { observer }                        from 'mobx-react';
import React                               from 'react';
import ContractIterationModel              from '../../modelx/models/private/sales/ContractIterationModel';
import ContractIterationStatusModel        from '../../modelx/models/private/sales/ContractIterationStatusModel';

interface IContractIterationStatusCircleProps {
	contractIteration: ContractIterationModel;
	contractIterationStatusCollection: ApiCollection<ContractIterationStatusModel>;
}

const ContractIterationStatusCircle = ({
	contractIteration,
	contractIterationStatusCollection,
}: IContractIterationStatusCircleProps) => {
	const contractIterationStatus = contractIterationStatusCollection.find(contractIterationStatus => contractIterationStatus.id === contractIteration.contractIterationStatusId);

	if (!contractIterationStatus) {
		return null;
	}

	return (
		<Tooltip
			placement="right"
			title={contractIterationStatus.label}
		>
			<div
				style={{
					// @todo
					backgroundColor: getContractIterationStatusColor(contractIterationStatus),
					borderRadius: 10,
					height: 20,
					width: 20,

				}}
			/>
		</Tooltip>
	);
};

export default observer(ContractIterationStatusCircle);
