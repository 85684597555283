import { computed, override }         from 'mobx';
import SalesPrivateApiModel           from '../../abstracts/SalesPrivateApiModel';

export default class QuotationStatusHistoryModel extends SalesPrivateApiModel {
	public filters: ModelFiltersExtended<{ quotation: id }> = {};

	static get path(): string {
		return '/quotation_status_histories/{?id}';
	}

	@computed
	public get comment(): string {
		return this.get('comment');
	}

	@override
	public get createdAt(): string {
		return this.get('createdAt');
	}

	@computed
	public get createdBy(): string {
		return this.get('createdBy');
	}

	@computed
	public get quotationStatusId(): number {
		return this.get('quotationStatus.id');
	}

	@computed
	public get rejectionReason(): string {
		return this.get('rejectionReason', '');
	}
}
