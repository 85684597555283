import { DownloadOutlined }                                                                              from '@ant-design/icons';
import { EllipsisOutlined }                                                                              from '@ant-design/icons';
import { LinkOutlined }                                                                                  from '@ant-design/icons';
import FileModel                                                                                         from 'Models/file/FileModel';
import Button                                                                                            from 'antd/lib/button';
import Card                                                                                              from 'antd/lib/card';
import Dropdown                                                                                          from 'antd/lib/dropdown';
import Skeleton                                                                                          from 'antd/lib/skeleton';
import { Page }                                                                                          from 'components/Page';
import TablePaginationCollection                                                                         from 'components/TablePaginationCollection';
import InterventionFilter                                                                                from 'components/filters/InterventionFilter';
import { observer }                                                                                      from 'mobx-react';
import React                                                                                             from 'react';
import { useEffect }                                                                                     from 'react';
import { appStore }                                                                                      from 'stores/index';
import { interventionsStore }                                                                            from 'stores/index';
import { notificationError, notificationSuccess }                                                        from 'tools/notification';

export const FileType = observer(({ model }: { model: FileModel }) => {
	if (!model.typeSource.type.isLoaded) {
		return <Skeleton active paragraph={false} />;
	}
	return <>{model.typeSource.type.reference === 'intervention_client_attachment' ? 'Pièce jointe' : model.typeSource.type.label}</>;
});

export default observer(function FilesListPage() {
	const fileCollection = interventionsStore.interventionFileCollection;
	const reportInterventionCollection = interventionsStore.completedInterventionReportInterventionCollection;

	useEffect(() => {
		(async () => {
			if (appStore.interventionUrnFilter) {
				reportInterventionCollection.setRequiredFilter('intervention', appStore.interventionUrnFilter);
				await reportInterventionCollection.list();
				fileCollection.setRequiredFilter('typeSource.type.reference', [
					'intervention_client_attachment',	
					'report_report',
					'report_certificate',
					'report_sheet',		
				]);
				fileCollection.setRequiredFilter('typeSource.source.entityUrn', [appStore.interventionUrnFilter, ...reportInterventionCollection.map(ri => ri.reportUrn)]);
				fileCollection.setSort('updatedAt', 'desc');
				fileCollection.list();
			}
			else {
				fileCollection.clear();
			}
		})();
	}, [appStore.interventionUrnFilter]);

	return (
		<Page title="Documents">
			<div>
				<InterventionFilter />

				<Card
					className="card-with-table"
					loading={fileCollection.isLoading}
					title="Documents"
				>
					<TablePaginationCollection
						collection={fileCollection}
						columns={[
							{
								render: m => <Button onClick={() => m.download(m.name)} type='link'>{m.name}</Button>,
								title: 'Nom',
							},
							{
								render: m => <FileType model={m} />,
								title: 'Type',
							},
							{
								align: 'right',
								fixed: 'right',
								render: m => (
									<Dropdown menu={{
										items: [
											{
												icon: <LinkOutlined />,
												key: 'details',
												label: 'Copier le lien',
												onClick: () => {
													navigator.clipboard.writeText(window.location.protocol + '//' + window.location.host + window.location.pathname + '/' + m.id)
														.then(() => {
															notificationSuccess({ message: 'Le lien vers le document a bien été copié.' });
														})
														.catch((err) => {
															notificationError({ message: `Impossible de copier le lien vers le document.` });
															console.error(err);
														});
												},
												style: { color: 'var(--primary-color)' },
											},
											{
												icon: <DownloadOutlined />,
												key: 'files',
												label: 'Télécharger',
												onClick: () => m.download(m.name),
											},
										]
									}} trigger={['click']}>
										<Button type="text">
											<EllipsisOutlined style={{ fontSize: 20 }} />
										</Button>
									</Dropdown>
								),
								title: '',
							},
						]}
						loading={fileCollection.isLoading}
						rowKey={item => item.id}
					/>
				</Card>
			</div>
		</Page>
	);
});
