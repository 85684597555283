import { computed }             from 'mobx';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

export default class CompanyContactModel extends DirectoryPrivateApiModel {
	public filters: ModelFiltersExtended<{
		company: id;
	}> = {};

	static get path(): string {
		return '/company_contacts/{?id}';
	}

	@computed
	public get companyId(): number {
		return this.get('company.id', 0);
	}

	@computed
	public get phoneNumber(): string {
		return this.get('phoneNumber', '');
	}

	@computed
	public get email(): string {
		return this.get('email', '');
	}

	@computed
	public get faxNumber(): string {
		return this.get('faxNumber', '');
	}
}
