// import broomSvg                    from 'icons/broom.svg';
// import sinkSvg                     from 'icons/sink.svg';
// import spraySvg                    from 'icons/spray.svg';
// import { ReactNode }               from 'react';

import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class OperationCategoryModel extends InterventionPrivateApiModel {
	public filters: ModelFiltersExtended<{
		partitionUrn: string;
		properties: id;
	}> = {};

	static get path(): string {
		return '/operation_categories/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	// @computed
	// public get icon(): ReactNode { // TODO trouver une autre façon de récupérer les icons
	// 	switch (this.label) {
	// 		case 'Assainissement':
	// 			return sinkSvg;
	// 		case 'Dégraissage':
	// 			return spraySvg;
	// 		case 'Ramonage':
	// 			return broomSvg;
	// 	}
	//
	// 	return null;
	// }
}
