import { LockOutlined }           from '@ant-design/icons';
import Button                     from 'antd/lib/button';
import Card                       from 'antd/lib/card';
import Form                       from 'antd/lib/form';
import Input                      from 'antd/lib/input';
import Result                     from 'antd/lib/result';
import { Page }                   from 'components/Page';
import { Link }                   from 'react-router-dom';
import React, { useState }        from 'react';
import ChangePasswordRequestModel from '../../modelx/models/public/directory/ChangePasswordRequestModel';
import PublicLayout               from '../../projectComponents/PublicLayout';
import notificationApiError       from '../../utils/notificationApiError';

const ResetPasswordForm = () => {
	const [loading, setLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	const url = new URL(window.location.href);
	const t = url.searchParams.get('t');

	const onFinish = async values => {
		setLoading(true);

		try {
			const changePasswordRequest = new ChangePasswordRequestModel({
				plainPassword: values.passwordFirst,
				token: t,
			});

			await changePasswordRequest.save();

			setIsSuccess(true);
		} catch (err) {
			notificationApiError(err);
		} finally {
			setLoading(false);
		}
	};

	if (isSuccess) {
		return (
			<Result
				status="success"
				style={{ padding: '20px 0' }}
				title="Votre mot de passe a bien été modifié!"
			/>
		);
	}

	return (
		<Form name="reset_password_form" onFinish={onFinish}>
			<Form.Item
				name="passwordFirst"
				rules={[{
					message: 'Veuillez renseigner un mot de passe',
					required: true,
				}]}
			>
				<Input.Password
					placeholder="Mot de passe"
					prefix={<LockOutlined />}
				/>
			</Form.Item>

			<Form.Item
				name="passwordSecond"
				rules={[
					{
						message: 'Veuillez confirmer le mot de passe',
						required: true,
					},
					({ getFieldValue }) => ({
						validator(rule, value) {
							if (!value || getFieldValue('passwordFirst') === value) {
								return Promise.resolve();
							}
							return Promise.reject('Les mots de passe ne sont pas identiques');
						},
					})]}
			>
				<Input.Password
					placeholder="Confirmation du mot de passe"
					prefix={<LockOutlined />}
				/>
			</Form.Item>

			<Button
				block={true}
				disabled={loading}
				htmlType="submit"
				loading={loading}
				type="primary"
			>
				Envoyer
			</Button>
		</Form>
	);
};

export default function ResetPasswordPage() {
	return (
		<PublicLayout title="Réinitialiser votre mot de passe">
			<Page title="Réinitialiser votre mot de passe">
				<Card>
					<ResetPasswordForm />
				</Card>

				<Link style={{ marginTop: 30, textAlign: 'center' }} to="/">
					{`Retour à la page d'accueil`}
				</Link>
			</Page>
		</PublicLayout>
	);
}
