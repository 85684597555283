import useSelectTaskZone       from 'hooks/useSelectTaskZone';
import { observer }            from 'mobx-react';
import React, { useEffect }    from 'react';
import { authenticationStore } from 'stores/index';

interface PageProps {
	children?: React.ReactNode;
	title: string;
}

export const Page: React.FC<PageProps> = observer(props => {
	useEffect(() => {
		document.title = props.title;
	}, [props.title]);

	const { isAuthenticated } = authenticationStore;	
	useSelectTaskZone(isAuthenticated);

	return (
		<>
			{props.children}
		</>
	);
});