/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Collection,
	Connector,
	ConnectorResult,
	ConnectorResults,
	Model,
}                   from '@mathquis/modelx';
import axios, {
	AxiosError,
	AxiosInstance,
}                   from 'axios';
import qs           from 'qs';
import GroupStorage from '../../../tools/GroupStorage';

export type ListOptions = {
	filters?: any;
	limit?: number;
	offset?: number;
	sort?: { o: string; w: string };
}

const forceCacheDuration = 0;

export const apiConnectorCache = new GroupStorage('api_connector_cache');

export class ApiConnector extends Connector {
	protected client: AxiosInstance;

	private _on401: () => void;

	private _on403: () => void;

	constructor(options: any) {
		super(options);

		this._on401 = () => null;

		this._on403 = () => null;

		this.client = axios.create({
			...this.options,
			headers: {
				Accept: 'application/ld+json',
				'Content-Type': 'application/ld+json',
				...this.options.headers,
			},
			responseType: 'json',
		});
	}

	public async destroy(model: Model, options: Record<string, unknown> = {}) {
		const response = await this.request(model.path, {
			method: 'delete',
			...options,
		});

		return new ConnectorResult(model.attributes, response);
	}

	public async fetch(model: Model, options: Record<string, unknown> = {}) {
		const response: any = await this.request(model.path, {
			method: 'get',
			...options,
		});

		return new ConnectorResult(response?.data || {}, response);
	}

	// Collection methods
	public async list<T extends Model>(
		collection: Collection<T>,
		options: ListOptions = {},
	) {
		const response = (await this.request(collection.path, {
			...options,
			method: 'get',
		})) as any;

		return new ConnectorResults(response?.data?.['hydra:member'], response);
	}

	public on401(callback: () => void): this {
		this._on401 = callback;

		return this;
	}

	public on403(callback: () => void): this {
		this._on403 = callback;

		return this;
	}

	public async save(model: Model, options: any = {}) {
		const data = options.patchAttributes || model.untransformedAttributes;

		const response: any = await this.request(model.path, {
			data: options.stringifyAttributes ? qs.stringify(data) : data,
			method: model.id ? 'put' : 'post',
			...options,
		});

		return new ConnectorResult(response?.data || {}, response);
	}

	protected onRequestError(err: AxiosError) {
		if (this._on401 && err?.request?.status === 401) {
			this._on401();
		}

		if (this._on403 && err?.request?.status === 403) {
			this._on403();
		}

		throw err;
	}

	protected async request(path: string, options: Record<string, unknown> = {}) {
		if (options.method === 'get' && (options.cache || forceCacheDuration)) {
			return this._requestGetWithCache(path, options);
		}

		try {
			return await this.client(path.replace(/\/$/, ''), { ...options });
		} catch (err) {
			return this.onRequestError(err as AxiosError<any>);
		}
	}

	private async _requestGetWithCache(path: string, options: Record<string, unknown> = {}) {
		const duration = forceCacheDuration || options.cache as number;
		const optionParams: Filters = options.params as Filters || {};
		const cacheKey = path + '?' + JSON.stringify(optionParams, Object.keys(optionParams).sort());
		const data = apiConnectorCache.getData(cacheKey);

		if (data) {
			return { data };
		}

		try {
			const response = await this.client(path.replace(/\/$/, ''), { ...options });

			apiConnectorCache.setItem(cacheKey, response?.data, duration).save();

			return response;
		} catch (err) {
			return this.onRequestError(err as AxiosError<any>);
		}
	}
}
