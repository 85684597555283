import InterventionModel from 'Models/intervention/InterventionModel';
import Skeleton          from 'antd/lib/skeleton';
import { observer }      from 'mobx-react';
import React             from 'react';

function AbstractInterventionTaskZoneName(props: {
	intervention: InterventionModel;
}) {
	return props.intervention.isLoading ? <Skeleton active paragraph={false} /> : <>{props.intervention.taskZone.taskZoneName}</>;
}

export default observer(AbstractInterventionTaskZoneName);