import ContactLoginModel                  from 'Models/directory/ContactLoginModel';
import ImpersonateUserModel               from 'Models/directory/ImpersonateUserModel';
import {
	action,
	computed,
	makeObservable,
	observable,
}                                         from 'mobx';
import DirectoryLoginCheckModel           from 'modelx/models/public/directory/LoginCheckModel';
import RhLoginCheckModel                  from 'modelx/models/public/rh/LoginCheckModel';
import ConfigProxy                        from 'tools/ConfigProxy';
import notificationApiError               from 'tools/notificationApiError';
import { notificationError }              from 'tools/notification';
import SessionModel                       from '../modelx/models/SessionModel';
import { directoryLoggedApiConnector }    from '../modelx/models/abstracts/DirectoryPrivateApiModel';
import { fileLoggedApiConnector }         from '../modelx/models/abstracts/FilePrivateApiModel';
import { interventionLoggedApiConnector } from '../modelx/models/abstracts/InterventionPrivateApiModel';
import { invoiceLoggedApiConnector }      from '../modelx/models/abstracts/InvoicePrivateApiModel';
import { rhLoggedConnector }              from '../modelx/models/abstracts/RhPrivateApiModel';
import { salesLoggedConnector }           from '../modelx/models/abstracts/SalesPrivateApiModel';
import DirectoryTokenRefreshModel         from '../modelx/models/public/directory/TokenRefreshModel';
import RhTokenRefreshModel                from '../modelx/models/public/rh/TokenRefreshModel';

export const loggedConnectors = [
	directoryLoggedApiConnector,
	fileLoggedApiConnector,
	interventionLoggedApiConnector,
	invoiceLoggedApiConnector,
	rhLoggedConnector,
	salesLoggedConnector,
];

export default class AuthenticationStore implements Store {

	public session = new SessionModel();

	@observable
	private _isAuthenticated: boolean;

	@observable
	private _isImpersonate: boolean;

	@observable
	private _isReady: boolean;

	constructor() {
		this._isAuthenticated = false;
		this._isImpersonate = false;
		this._isReady = false;

		makeObservable(this);
	}

	public clear = async () => {
		this._isAuthenticated = false;
		this._isImpersonate = false;

		// this.session.clear();
		await this.session.destroy();
	};

	public contactLogin = async (
		contactUrn: string,
	) => {
		try {
			const contactLogin = new ContactLoginModel();

			contactLogin.set({
				contactUrn: contactUrn,
			});

			await contactLogin.save();

			await this.onLoginSuccess(contactLogin);

			this.setIsAuthenticated(true);
		} catch (e) {
			notificationApiError(e, { message: 'Erreur lors de la connexion' });
		}
	};

	public impersonateLogin = async (
		username: string,
		password: string,
		userUrn: string,
	) => {
		try {
			const rhLoginCheckModel = new RhLoginCheckModel();

			await this.login(rhLoginCheckModel, username, password);

			await this.onLoginSuccess(rhLoginCheckModel);

			const impersonateUserModel = new ImpersonateUserModel();

			impersonateUserModel.set({
				userUrn: userUrn,
			});

			await impersonateUserModel.save();

			this.session.set({
				parentToken: {
					refreshToken: impersonateUserModel.refreshToken,
					token: impersonateUserModel.token,
				},
			});

			await this.session.save();

			await this.onLoginSuccess(impersonateUserModel);

			this.setIsAuthenticated(true);
			this.setIsImpersonate(true);
		} catch (e) {
			notificationApiError(e, { message: 'Erreur lors de la connexion' });
		}
	};

	public login = async (
		model: DirectoryLoginCheckModel | RhLoginCheckModel,
		username: string,
		password: string,
	): Promise<void> => {
		try {
			model.set({
				partitionUrn: ConfigProxy.get('PARTITION_URN'),
				password,
				username,
			});

			await model.save();

			this.session.set({
				parentToken: {
					refreshToken: model.refreshToken,
					token: model.token,
				},
			});

			await this.session.save();
		} catch (e) {
			notificationApiError(e, { message: 'Erreur lors de la connexion' });
		}
	};

	public logout = (): void => {
		this.setIsAuthenticated(false);
	};

	public onLoginSuccess = async (
		model: (
			ContactLoginModel
			| DirectoryLoginCheckModel
			| RhLoginCheckModel
			| DirectoryTokenRefreshModel
			| RhTokenRefreshModel
			)
	) => {
		await this.session
			.set({
				refreshToken: model.refreshToken,
				token: model.token,
			})
			.save();

		loggedConnectors.forEach(connector => {
			connector
				.setToken(model.token)
				.setExpiration(this.session.exp)
				.onExpired(this.refresh);
		});
	};

	public refresh = async (): Promise<void> => {
		let refreshModel: DirectoryTokenRefreshModel | RhTokenRefreshModel;

		switch (true) {
			case this.session.isContact:
			case this.session.isGlobalContact:
				refreshModel = new DirectoryTokenRefreshModel();
				break;
			case this.session.isStaffMember:
				refreshModel = new RhTokenRefreshModel();
				break;
			default:
				throw new Error('Refresh model not found');
		}

		try {
			refreshModel.set({
				refresh_token: this.session.refreshToken,
			});

			await refreshModel.save();

			await this.onLoginSuccess(refreshModel);
		} catch (err) {
			if (err instanceof Error) {
				notificationError({ message: err.message });
			}
		}
	};

	@computed
	public get isImpersonate(): boolean {
		return this._isImpersonate;
	}

	@computed
	public get isReady(): boolean {
		return this._isReady;
	}

	@computed
	public get isAuthenticated(): boolean {
		return this._isAuthenticated;
	}

	@action
	public setIsAuthenticated(value: boolean): void {
		this._isAuthenticated = value;
	}

	@action
	public setIsImpersonate(value: boolean): void {
		this._isImpersonate = value;
	}

	@action
	public setIsReady(value: boolean): void {
		this._isReady = value;
	}
}
