import { EditOutlined, EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Button                                                        from 'antd/lib/button';
import Dropdown                                                      from 'antd/lib/dropdown';
import Modal                                                         from 'antd/lib/modal';
import notification                                                  from 'antd/lib/notification';
import { observer }                                                  from 'mobx-react';
import React, { useState }                                           from 'react';
import { appStore, contactsStore }                                   from 'stores/index';
import ContactModalForm                                              from '../../../forms/ContactModalForm';
import ContactModel                                                  from '../../../modelx/models/private/directory/ContactModel';
import notificationApiError                                          from '../../../utils/notificationApiError';

function toggleContactEnabled(contact: ContactModel) {
	Modal.confirm({
		cancelText: 'Annuler',
		icon: <ExclamationCircleOutlined />,
		okText: !contact.enabled ? 'Activer' : 'Désactiver',
		onOk: async () => {
			try {
				await contact.patch({ enabled: !contact.enabled });

				notification.success({
					message: `Le contact a bien été ${contact.enabled ? 'activé' : 'désactivé'}`,
				});
			} catch (err) {
				notificationApiError(err);
			}
		},
		title: `Voulez-vous vraiment ${!contact.enabled ? 'activer' : 'désactiver'} ce contact ?`,
		width: 450,
	});
}

const ContactDropDown: React.FC<{ contact: ContactModel }> = props => {
	const { contact } = props;

	const [editContactVisible, setEditContactVisible] = useState(false);

	return (
		<div>
			<Dropdown
				menu={{
					items: [
						{
							key: 'update',
							label:(
								<>	
									<EditOutlined /> Modifier les informations
								</>
							),
							onClick: () => setEditContactVisible(true),
						},
						{
							disabled: !contact.id,
							key: 'enable',
							label: (
								<>
									<EditOutlined /> {!contact.enabled ? 'Activer les accès' : 'Désactiver les accès'}
								</>
							),
							onClick: () => {
								toggleContactEnabled(contact);
							},
						}
					]
				}}
				trigger={['click']}
			>
				<Button style={{ border: 'none', padding: 0 }}>
					<EllipsisOutlined style={{ fontSize: 20 }} />
				</Button>
			</Dropdown>

			{/*Modifier un contact*/}
			<ContactModalForm
				disabledFields={['email', 'firstName', 'gender', 'lastName', 'phoneNumber', 'phoneNumberBis']}
				formProps={{
					initialValues: {
						email: contact.email,
						firstName: contact.firstName,
						function: contact.contactFunction,
						gender: contact.gender,
						lastName: contact.lastName,
						phoneNumber: contact.phoneNumber,
						phoneNumberBis: contact.phoneNumberBis,
					},
				}}
				onCancel={() => setEditContactVisible(false)}
				onSubmit={async values => {
					try {
						await contact.patch(values);

						notification.success({
							description: 'Les informations du contact ont bien été modifiées',
							message: 'Contact modifié',
						});

						setEditContactVisible(false);

						await Promise.all([
							appStore.contactCollection
								.list(),
							contactsStore.fetch(),
						]);
					} catch (err) {
						notificationApiError(err);
					}
				}}
				open={editContactVisible}
				title="Modifier un contact"
			/>
		</div>
	);
};

export default observer(ContactDropDown);
