import { FormInstance, FormProps }                from 'antd/lib/form/Form';
import Form                                       from 'antd/lib/form';
import { ModalProps }                             from 'antd/lib/modal';
import Modal                                      from 'antd/lib/modal';
import Spin                                       from 'antd/lib/spin';
import { uniqueId }                               from 'lodash';
import React, { RefObject, forwardRef, useState } from 'react';
import formManageApiError                         from '../utils/formManageApiError';
import notificationApiError                       from '../utils/notificationApiError';

export type Ref = FormInstance;

export interface ModalFormProps extends ModalProps {
    children?: any;
    formProps?: FormProps;
    isReady?: boolean;
    onSubmit: (values: any, form: FormInstance) => void;
    ref?: RefObject<FormInstance>;
}

const ModalForm = forwardRef<Ref, ModalFormProps>((props, ref) => {
	const { children, formProps, isReady, onSubmit } = props;

	const [form] = Form.useForm();

	const [isLoading, setIsLoading] = useState(false);

	const id = uniqueId();

	return (
		<Modal
			cancelText="Annuler"
			okButtonProps={{
				disabled: isLoading,
				loading: isLoading,
			}}
			okText="Enregistrer"
			onOk={async () => {
				try {
					const values = await form.validateFields();

					setIsLoading(true);

					await onSubmit(values, form);

					form.resetFields();
				} catch (e) {
					notificationApiError(e);
					formManageApiError(e, form);
				} finally {
					setTimeout(() => setIsLoading(false), 1000);
				}
			}}
			{...props}
		>
			{typeof isReady === 'undefined' || isReady ? (
				<Form
					form={form}
					layout="vertical"
					name={id}
					ref={ref}
					{...formProps}
				>
					{children}
				</Form>
			) : (
				<Spin
					style={{
						alignItems: 'center',
						display: 'flex',
						justifyContent: 'center',
					}}
				/>
			)}
		</Modal>
	);
});

export default ModalForm;