export type GenderReference = 'MALE' | 'FEMALE';

export const GENDER_MALE = {
	civility: 'M.',
	label: 'Homme',
	reference: 'MALE',
};

export const GENDER_FEMALE = {
	civility: 'Mme.',
	label: 'Femme',
	reference: 'FEMALE',
};

const Genders = [
	GENDER_MALE,
	GENDER_FEMALE,
];

export const getGenderLabel = reference => {
	const gender = Genders.find(g => g.reference === reference);

	if (!gender) {
		return reference;
	}

	return gender.label;
};

export default Genders;
