import { useParams }                             from 'react-router-dom';
import { useEffect }                             from 'react';
import { appStore }                              from 'stores/index';
import { getUrnFromId }                          from 'tools/UrnTools';

export default (isAuthenticated: boolean) => {
	const {
		setSelectedClientPartition,
		setSelectedEnterprise,
		setSelectedTaskZone,
	} = appStore;

	const { clientPartitionId, enterpriseId, taskZoneId } = useParams();
	useEffect(() => {
		if (isAuthenticated) {
			if (enterpriseId) {
				setSelectedEnterprise(getUrnFromId('enterprise', enterpriseId));
			}
			if (clientPartitionId) {
				setSelectedClientPartition(getUrnFromId('clientPartition', clientPartitionId));
			}
			if (taskZoneId) {
				setSelectedTaskZone(getUrnFromId('taskZone', taskZoneId));
			}
		}
	}, [isAuthenticated]);
};