import { computed }                from 'mobx';
import moment                      from 'moment';
import { getIdFromUrn }            from 'tools/UrnTools';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class TaskModel extends InterventionPrivateApiModel {
	public filters: ModelFiltersExtended<{
		'owner.clientUrn': string;
		'owner.enterpriseUrn': string;
		'owner.partitionUrn': string;
		'quotationUrns': string;
		'taskOperations.taskZone': id;
	}> = {};

	static get path(): string {
		return '/tasks/{?id}';
	}

	@computed
	public get taskOperationId(): number {
		return this.get('taskOperations.id', 0);
	}

	@computed
	public get scheduleStartDate(): string {
		return this.get('scheduleStartDate', '');
	}

	@computed
	public get scheduleStartDateMoment(): Moment {
		return moment(this.scheduleStartDate);
	}

	@computed
	public get scheduleEndDate(): string {
		return this.get('scheduleEndDate', '');
	}

	@computed
	public get scheduleEndDateMoment(): Moment {
		return moment(this.scheduleEndDate);
	}

	@computed
	public get ownerId(): number {
		return this.get('owner.id', 0);
	}

	@computed
	public get taskStatusId(): number {
		return this.get('taskStatus.id', 0);
	}

	@computed
	public get title(): string {
		return this.get('title', '');
	}

	@computed
	public get sourceUrn(): string {
		return this.get('sourceUrn', '');
	}

	@computed
	public get sourceId(): number {
		return parseInt(getIdFromUrn(this.sourceUrn)) || 0;
	}

	@computed
	public get description(): string {
		return this.get('description', '');
	}
}
