import { computed }         from 'mobx';
import { getIdFromUrn }     from '../../../../tools/UrnTools';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export default class ContractActorModel extends SalesPrivateApiModel {
	public filters: ModelFiltersExtended<{
		contract: id;
		'contract.owner.clientUrn': string;
		'contractActorGroup.reference': string;
	}> = {};

	static get path(): string {
		return '/contract_actors/{?id}';
	}

	@computed
	public get actorId(): number {
		return parseInt(getIdFromUrn(this.get('actorUrn')));
	}

	@computed
	public get actorUrn(): string {
		return this.get('actorUrn');
	}

	@computed
	public get position(): number {
		return this.get('position');
	}

	@computed
	public get contractActorGroupId(): number {
		return this.get('contractActorGroup.id');
	}
}
