import { ApiCollection }   from 'Collections/ApiCollection';
import { observer }        from 'mobx-react';
import React               from 'react';
import StaffMemberModel    from '../../modelx/models/private/rh/StaffMemberModel';
import StaffModel          from '../../modelx/models/private/rh/StaffModel';
import QuotationActorModel from '../../modelx/models/private/sales/QuotationActorModel';

const QuotationActorCommercial = ({
	quotationActorCommercialCollection,
	staffCollection,
	staffMemberCollection,
}: {
	quotationActorCommercialCollection: ApiCollection<QuotationActorModel>;
	staffCollection: ApiCollection<StaffModel>;
	staffMemberCollection: ApiCollection<StaffMemberModel>;
}) => {
	const quotationActorCommercial = quotationActorCommercialCollection.first();

	if (!quotationActorCommercial) {
		return null;
	}

	const staffMember = staffMemberCollection.find(staffMember => staffMember.id === quotationActorCommercial.actorId);

	if (!staffMember) {
		return null;
	}

	const staff = staffCollection.find(staff => staff.id === staffMember.staffId);

	if (!staff) {
		return null;
	}

	return (
		<>
			{staff.fullName}
		</>
	);
};

export default observer(QuotationActorCommercial);
