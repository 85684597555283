import Card          from 'antd/lib/card';
import Descriptions  from 'antd/lib/descriptions';
import Empty         from 'antd/lib/empty';
import Space         from 'antd/lib/space';
import Tag           from 'antd/lib/tag';
import { observer }  from 'mobx-react';
import React         from 'react';
import { homeStore } from 'stores/index';

const ManagerBox = () => {
	const {
		contactCollection,
		operationCategoryCollection,
		responsibleZoneContactCollection,
	} = homeStore;

	const isLoading = (
		contactCollection.isLoading ||
		responsibleZoneContactCollection.isLoading
	);

	const responsibleZoneContactByContactUrn = responsibleZoneContactCollection.groupBy('contactUrn');

	return (
		<Card
			className="managers-box"
			loading={isLoading}
			title="Responsables du site"
		>
			{!responsibleZoneContactCollection.length && <Empty description="Aucun résultat" />}

			{Object.keys(responsibleZoneContactByContactUrn).map(contactUrn => {
				const contact = contactCollection.find(contact => contact.urn === contactUrn);

				if (!contact) {
					return null;
				}

				return (
					<div key={contactUrn}>
						<Space direction="vertical">
							<b className="sub-title">{contact.fullName}</b>

							<div>
								{responsibleZoneContactByContactUrn[contactUrn].map(responsibleZoneContact => {
									const operationCategory = operationCategoryCollection.find(operationCategory => operationCategory.id === responsibleZoneContact.operationCategoryId);

									if (!operationCategory) {
										return null;
									}

									return (
										<Tag key={`${contactUrn}_${operationCategory.id}`}>
											{operationCategory.label}
										</Tag>
									);
								})}
							</div>

							<Descriptions column={1} size="small">
								<Descriptions.Item label="Adresse email">
									{contact.email}
								</Descriptions.Item>

								<Descriptions.Item label="Téléphone principal">
									{contact.phoneNumber}
								</Descriptions.Item>

								<Descriptions.Item label="Téléphone secondaire">
									{contact.phoneNumberBis}
								</Descriptions.Item>
							</Descriptions>
						</Space>
					</div>
				);
			})}
		</Card>
	);
};

export default observer(ManagerBox);
