import { computed, override }      from 'mobx';
import Genders                     from '../../../../constants/Genders';
import RhPrivateApiModel           from '../../abstracts/RhPrivateApiModel';

export default class StaffModel extends RhPrivateApiModel {
	public filters: ModelFiltersExtended<{
		staffMembers: id;
	}> = {};

	static get path(): string {
		return '/staff/{?id}';
	}

	@computed
	public get enabled(): boolean {
		return this.get('enabled', false);
	}

	@computed
	public get email(): string {
		return this.get('email', '');
	}

	@computed
	public get gender(): string {
		return this.get('gender', '');
	}

	@computed
	public get civilityLabel(): string {
		return Genders.find(g => g.reference === this.gender)?.civility || '';
	}

	@computed
	public get firstName(): string {
		return this.get('firstName', '');
	}

	@computed
	public get lastName(): string {
		return this.get('lastName', '');
	}

	@computed
	public get phoneNumber(): string {
		return this.get('phoneNumber', '');
	}

	@computed
	public get nationality(): string {
		return this.get('nationality', '');
	}

	@computed
	public get birthdate(): string {
		return this.get('birthdate', '');
	}

	@computed
	public get address(): ApiEntity {
		return this.get('address', '');
	}

	@override
	public get createdAt(): string {
		return this.get('createdAt', '');
	}

	@override
	public get updatedAt(): string {
		return this.get('updatedAt', '');
	}

	@computed
	public get driverLicenseNumber(): string {
		return this.get('driverLicenseNumber', '');
	}

	@computed
	public get socialSecurityNumber(): string {
		return this.get('socialSecurityNumber', '');
	}

	@computed
	public get fullName(): string {
		return this.lastName.toUpperCase() + ' ' + this.firstName;
	}
}
