import { ApiCollection }                      from 'Collections/ApiCollection';
import FileModel                              from 'Models/file/FileModel';
import FrameworkAgreementIterationStatusModel from 'Models/sales/FrameworkAgreementIterationStatusModel';
import FrameworkAgreementModel                from 'Models/sales/FrameworkAgreementModel';
import FrameworkAgreementIterationModel       from '../modelx/models/private/sales/FrameworkAgreementIterationModel';

export default class FrameworkAgreementIterationDashboardStore implements Store {

	public frameworkAgreement = new FrameworkAgreementModel();
	public frameworkAgreementIteration = new FrameworkAgreementIterationModel();
	public frameworkAgreementIterationFileCollection = new ApiCollection(FileModel);
	public frameworkAgreementIterationStatusCollection = new ApiCollection(FrameworkAgreementIterationStatusModel);

	public clear(): void {
		this.frameworkAgreement.clear();
		this.frameworkAgreementIteration.clear();
	}

	public async fetch(id: id): Promise<void> {
		this.frameworkAgreementIteration.setId(id);

		await Promise.all([
			this.frameworkAgreementIteration.fetch().then(() => {
				this.frameworkAgreement.setId(this.frameworkAgreementIteration.frameworkAgreementId);
				this.frameworkAgreement.fetch();

				this.frameworkAgreementIterationFileCollection.setFilters({
					'typeSource.source.entityUrn': this.frameworkAgreementIteration.urn,
					'typeSource.type.reference': 'framework_agreement_iteration_signed_document',
				});
				this.frameworkAgreementIterationFileCollection.list();
			}),

			this.frameworkAgreementIterationStatusCollection.list({ cache: 3600, }),
		]);
	}
}
