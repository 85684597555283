import notification, { ArgsProps } from 'antd/lib/notification';

export const notificationSuccess = (options: ArgsProps) => {
	notification['success']({
		className: 'qhs-notification success',
		placement: 'bottomRight',
		...options
	});
};

export const notificationError = (options: ArgsProps) => {
	notification['error']({
		className: 'qhs-notification error',
		placement: 'bottomRight',
		...options
	});
};