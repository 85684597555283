import { ApiCollection }               from 'Collections/ApiCollection';
import { AutoloadedPagedCollection }   from 'Collections/AutoloadedPagedCollection';
import ClientPartitionModel            from 'Models/directory/ClientPartitionModel';
import GlobalContactModel              from 'Models/directory/GlobalContactModel';
import InterventionOperationModel      from 'Models/intervention/InterventionOperationModel';
import ContractIterationItemEventModel from 'Models/sales/ContractIterationItemEventModel';
import PurchaseOrderModel              from 'Models/sales/PurchaseOrderModel';
import QuotationItemEventModel         from 'Models/sales/QuotationItemEventModel';
import { defaultContractReferences }   from 'constants/ContractIterationStatuses';
import { defaultQuotationReferences }  from 'constants/QuotationStatuses';
import { action }                      from 'mobx';
import { computed }                    from 'mobx';
import { makeObservable }              from 'mobx';
import { observable }                  from 'mobx';
import { getIdFromUrn }                from 'tools/UrnTools';
import { whenAsync }                   from 'tools/modelxTools';
import { notificationError }           from 'tools/notification';
import ClientModel                     from '../modelx/models/private/directory/ClientModel';
import CompanyContactModel             from '../modelx/models/private/directory/CompanyContactModel';
import CompanyModel                    from '../modelx/models/private/directory/CompanyModel';
import ContactModel                    from '../modelx/models/private/directory/ContactModel';
import EnterpriseModel                 from '../modelx/models/private/directory/EnterpriseModel';
import EnterprisePartitionModel        from '../modelx/models/private/directory/EnterprisePartitionModel';
import OwnerModel                      from '../modelx/models/private/intervention/OwnerModel';
import TaskZoneGroupModel              from '../modelx/models/private/intervention/TaskZoneGroupModel';
import TaskZoneModel                   from '../modelx/models/private/intervention/TaskZoneModel';
import ContractIterationModel          from '../modelx/models/private/sales/ContractIterationModel';
import ContractIterationStatusModel    from '../modelx/models/private/sales/ContractIterationStatusModel';
import ContractModel                   from '../modelx/models/private/sales/ContractModel';
import QuotationModel                  from '../modelx/models/private/sales/QuotationModel';
import QuotationStatusModel            from '../modelx/models/private/sales/QuotationStatusModel';
import { authenticationStore }         from './index';
import { globalFiltersStore }          from './index';

export default class AppStore implements Store {

	public _contactOwnCollection = new ApiCollection(ContactModel);
	public clientCollection = new ApiCollection(ClientModel);
	public clientPartitionCollection = new ApiCollection(ClientPartitionModel);
	public companyCollection = new ApiCollection(CompanyModel);
	public companyContactCollection = new ApiCollection(CompanyContactModel);
	public contact = new ContactModel();
	public contactCollection = new ApiCollection(ContactModel);
	public contractCollection = new ApiCollection(ContractModel);
	public contractIterationCollection = new ApiCollection(ContractIterationModel);
	public contractIterationStatusCollection = new ApiCollection(ContractIterationStatusModel);
	public enterpriseCollection = new ApiCollection(EnterpriseModel);
	public enterprisePartitionCollection = new ApiCollection(EnterprisePartitionModel);
	public globalContact = new GlobalContactModel();
	public purchaseOrderCollection = new ApiCollection(PurchaseOrderModel);
	public quotationCollection = new ApiCollection(QuotationModel);
	public quotationStatusCollection = new ApiCollection(QuotationStatusModel);
	public taskZoneAutoloadedCollection = new AutoloadedPagedCollection(TaskZoneModel);
	public taskZoneGroupCollection = new ApiCollection(TaskZoneGroupModel);
	public taskZoneOwnerCollection = new ApiCollection(OwnerModel);

	@observable
	private _clientPartitionUrn: string | null | undefined = undefined;

	@observable
	private _contactSearchFilter: string | null | undefined = undefined;

	@observable
	private _contractIterationStatusUrnFilter: null | string[] = null;

	@observable
	private _contractUrnFilter: string | null | undefined = undefined;

	@observable
	private _enterpriseUrn: string | null | undefined = undefined;

	@observable
	private _interventionUrnFilter: string | null | undefined = undefined;

	@observable
	private _purchaseOrderUrnFilter: string | null | undefined = undefined;

	@observable
	private _quotationStatusUrnFilter: null | string[] = null;

	@observable
	private _taskZone: null | TaskZoneModel = null;

	@observable
	private _taskZoneUrn: string | null | undefined = undefined;

	constructor() {
		makeObservable(this);

		EnterprisePartitionModel.connector.on403(() => {
			notificationError({ message: `Vous n'avez pas les droits pour accéder aux établissements de cette entreprise.` });
			this._clientPartitionUrn = null;
			this._taskZoneUrn = null;
			this._enterpriseUrn = null;
		});
	}

	public clear() {
		this._contractUrnFilter = null;
		this._contractIterationStatusUrnFilter = null;
		this._quotationStatusUrnFilter = null;
		this._interventionUrnFilter = null;
		this._enterpriseUrn = null;
		this._clientPartitionUrn = null;
		this._taskZoneUrn = null;

		this.clientCollection.clear();
		this.clientPartitionCollection.clear();
		this.companyCollection.clear();
		this.companyContactCollection.clear();
		this.contact.clear();
		this.contactCollection.clear();
		this.contractCollection.clear();
		this.contractIterationCollection.clear();
		this.enterpriseCollection.clear();
		this.enterprisePartitionCollection.clear();
		this.globalContact.clear();
		this.purchaseOrderCollection.clear();
		this.quotationCollection.clear();
		this.taskZoneAutoloadedCollection.clear();
		this.taskZoneGroupCollection.clear();
		this.taskZoneOwnerCollection.clear();
		this._contactOwnCollection.clear();

		this.contractIterationStatusCollection.clear();
		this.quotationStatusCollection.clear();
	}

	public load = async () => {
		try {
			await this.globalContact.fetch();
		} catch (err) {
			notificationError({ message: `Impossible d'accéder à l'interface.`, });
			authenticationStore.logout();
			return;
		}

		await this._contactOwnCollection
			.setFilter('globalContact', this.globalContact.id)
			.list();

		await this.enterprisePartitionCollection
			.listByFromCollection(this._contactOwnCollection, 'id', 'contacts');

		await this.enterpriseCollection
			.listByFromCollection(this.enterprisePartitionCollection, 'id', 'enterprisePartitions');

		if (this.enterpriseUrn) {
			this.loadClients();
		}
	};

	public loadClients = async () => {
		if (!this.selectedEnterprisePartition) {
			console.warn('no selectedEnterprisePartition');
			return;
		}

		const contact = this._contactOwnCollection.findBy('enterprisePartitionId', this.selectedEnterprisePartition.id);

		if (!contact) {
			console.warn('no contact');
			return;
		}

		await authenticationStore.contactLogin(contact.urn);

		await Promise.all([
			this.clientCollection
				.listByFromCollection(this.enterprisePartitionCollection, 'id', 'clientPartition.enterprisePartition'),

			this.clientPartitionCollection
				.listByFromCollection(this.enterprisePartitionCollection, 'id', 'enterprisePartition'),
		]);

		await Promise.all([
			this.companyCollection
				.listByFromCollection(this.clientPartitionCollection, 'companyId', 'id')
				.then(async () => {
					await this.companyContactCollection
						.listByFromCollection(this.companyCollection, 'id', 'company');
				}),

			this.contactCollection
				.setSort('lastName')
				.listByFromCollection(this.enterprisePartitionCollection, 'id', 'enterprisePartition'),

			this.taskZoneAutoloadedCollection
				.listByFromCollection(this.clientCollection, 'urn', 'owner.clientUrn')
				.then(async () => {
				}),

			this.taskZoneGroupCollection
				.listByFromCollection(this.clientCollection, 'urn', 'taskZone.owner.clientUrn'),

			this.taskZoneOwnerCollection
				.listByFromCollection(this.clientCollection, 'urn', 'clientUrn'),

			this.contractIterationStatusCollection.list({ cache: 3600 }),
			this.quotationStatusCollection.list({ cache: 3600 }),
			this.purchaseOrderCollection.list(),
		]);
	};

	@action
	public setContactSearchFilter = async (value: string | null) => {
			await globalFiltersStore.filters
				.setContactSearch(value)
				.save();

			this._contactSearchFilter = value;
		};


	@action
	public async setContractByInterventionUrn(urn: string) {
		this.setInterventionUrnFilter(urn);
		const interventionOperationCollection = new ApiCollection(InterventionOperationModel);
		await interventionOperationCollection.listBy([urn], 'intervention');
		const interventionOperation = interventionOperationCollection.first();
		if (!interventionOperation) {
			return;
		}
		await whenAsync(() => interventionOperation.taskOperation.isLoaded);
		await whenAsync(() => interventionOperation.taskOperation.task.isLoaded);
		if (interventionOperation.taskOperation.task.sourceUrn.includes('quotation')) {
			const quotationItemEvent = new QuotationItemEventModel({ id: getIdFromUrn(interventionOperation.taskOperation.task.sourceUrn) });
			await quotationItemEvent.fetch();
			await whenAsync(() => quotationItemEvent.quotationItem.isLoaded);
			this.setContractUrnFilter(quotationItemEvent.quotationItem.quotationUrn);
		} else {
			const contractIterationItemEvent = new ContractIterationItemEventModel({ id: getIdFromUrn(interventionOperation.taskOperation.task.sourceUrn) });
			await contractIterationItemEvent.fetch();
			await whenAsync(() => contractIterationItemEvent.contractIterationItem.isLoaded);
			this.setContractUrnFilter(contractIterationItemEvent.contractIterationItem.contractIterationUrn);
		}
	}

	@action
	public setContractIterationStatusUrnFilter (urns: string[]) {
		globalFiltersStore.filters.setContractIterationStatusUrn(urns).save();
		this._contractIterationStatusUrnFilter = urns;
	}

	@action
	public setContractUrnFilter = async (urn: string | null) => {
			await globalFiltersStore.filters
				.setContractUrn(urn)
				.save();

			this._contractUrnFilter = urn;
		};

	@action
	public async setInterventionUrnFilter(urn: string | null) {
		await globalFiltersStore.filters
			.setInterventionUrn(urn)
			.save();

		this._interventionUrnFilter = urn;
	}


	@action
	public async setPurchaseOrderUrnFilter(urn: string | null) {
		await globalFiltersStore.filters
			.setPurchaseOrderUrn(urn)
			.save();

		this._purchaseOrderUrnFilter = urn;
	}

	@action
	public async setSelectedClientPartition(clientPartitionUrn: string | undefined) {
		await globalFiltersStore.filters
			.setClientPartitionUrn(clientPartitionUrn)
			.save();

		this._clientPartitionUrn = clientPartitionUrn;
	}

	@action
	public setQuotationStatusUrnFilter(urns: string[]) {
		globalFiltersStore.filters.setQuotationStatusUrn(urns).save();
		this._quotationStatusUrnFilter = urns;
	}

	@action
	public async setSelectedEnterprise(enterpriseUrn: string | undefined) {
		await globalFiltersStore.filters
			.setEnterpriseUrn(enterpriseUrn)
			.save();

		this._enterpriseUrn = enterpriseUrn;
	}

	@action
	public async setSelectedTaskZone (taskZoneUrn: string | undefined) {
		await globalFiltersStore.filters
			.setTaskZoneUrn(taskZoneUrn)
			.save();

		this._taskZoneUrn = taskZoneUrn;
	}

	@action
	public setTaskZoneFilterAndFetch(taskZone: TaskZoneModel) {
		this.contractIterationCollection
			.setSort('createdAt', false)
			.setFilters({
				archived: false,
				'contractIterationStatus.reference': defaultContractReferences,
				'items.itemActivities.locationUrn': taskZone.urn,
			});

		this.quotationCollection
			.setSort('createdAt', false)
			.setFilters({
				archived: false,
				'items.itemActivities.locationUrn': taskZone.urn,
				'quotationStatus.reference': defaultQuotationReferences,
			});

		const contractPromise = this.contractIterationCollection.list().then(() => {
			return this.contractCollection.listByFromCollection(this.contractIterationCollection, 'contractId', 'id');
		});

		const quotationPromise = this.quotationCollection.list();

		return Promise
			.all([
				contractPromise,
				quotationPromise,
			])
			.then(() => {
				this.updateContractUrnFilter();
				return true;
			});
	}

	public updateContractUrnFilter() {
		const contractIteration = this.contractIterationCollection.find(contractIteration => {
			return contractIteration.urn === globalFiltersStore.filters.contractUrn;
		});

		const quotation = this.quotationCollection.find(quotation => {
			return quotation.urn === globalFiltersStore.filters.contractUrn;
		});

		if (contractIteration) {
			this.setContractUrnFilter(contractIteration.urn);
		} else if (quotation) {
			this.setContractUrnFilter(quotation.urn);
		} else {
			const firstContractIteration = this.contractIterationCollection.first();
			const firstQuotation = this.quotationCollection.first();
			if (firstContractIteration) {
				globalFiltersStore.filters.setContractUrn(firstContractIteration.urn);
				globalFiltersStore.filters.save();
				this.setContractUrnFilter(firstContractIteration.urn);
			} else if (firstQuotation) {
				globalFiltersStore.filters.setContractUrn(firstQuotation.urn);
				globalFiltersStore.filters.save();
				this.setContractUrnFilter(firstQuotation.urn);
			}
		}
	}

	@computed
	public get isLoading(): boolean {
		return (
			this.contact.isLoading ||
			this.contractCollection.isLoading ||
			this.contractIterationCollection.isLoading ||
			this.enterprisePartitionCollection.isLoading ||
			this.globalContact.isLoading ||
			this.quotationCollection.isLoading ||
			this.taskZoneAutoloadedCollection.realIsLoading ||
			this._contactOwnCollection.isLoading
		);
	}

	@computed
	public get contactSearchFilter() {
		return this._contactSearchFilter;
	}

	@computed
	public get contractIterationStatusUrnFilter() {
		return this._contractIterationStatusUrnFilter;
	}

	@computed
	public get contractUrnFilter() {
		return this._contractUrnFilter;
	}

	@computed
	public get interventionUrnFilter() {
		return this._interventionUrnFilter;
	}

	@computed
	public get clientPartitionUrn() {
		return this._clientPartitionUrn;
	}

	@computed
	public get enterpriseUrn() {
		return this._enterpriseUrn;
	}

	@computed
	public get taskZoneUrn() {
		return this._taskZoneUrn;
	}

	@computed
	public get purchaseOrderUrnFilter() {
		return this._purchaseOrderUrnFilter as Urn;
	}

	@computed
	public get quotationStatusUrnFilter() {
		return this._quotationStatusUrnFilter;
	}

	@computed
	public get selectedClient() {
		return this.clientCollection.find(c => c.clientPartitionId === this.selectedClientPartition?.id);
	}

	@computed
	public get selectedClientPartition() {
		return this.clientPartitionCollection.find(cp => cp.urn === this.clientPartitionUrn);
	}

	@computed
	public get selectedEnterprise() {
		return this.enterpriseCollection.find(e => e.urn === this.enterpriseUrn);
	}

	@computed
	public get selectedEnterprisePartition() {
		return this.enterprisePartitionCollection.find(ep => ep.get('enterprise.@urn') === this.enterpriseUrn);
	}

	@computed
	public get selectedTaskZone() {
		return this.taskZoneAutoloadedCollection.find(tz => tz.urn === this.taskZoneUrn);
	}
}
