import { computed }         from 'mobx';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';
import { getIdFromUrn }     from 'tools/UrnTools';

export default class FrameworkAgreementIterationActivityModel extends SalesPrivateApiModel {
	static get path(): string {
		return '/framework_agreement_iteration_activities/{?id}';
	}

	public filters: ModelFiltersExtended<{
		'activity': id;
		'frameworkAgreementIteration': id;
	}> = {};

	// public _sorts: ModelSortsExtended<{
	// 	'id': string;
	// }> = {};

	@computed
	public get activityId(): number {
		return this.get('activity.id');
	}

	@computed
	public get frameworkAgreementIterationId(): number {
		return this.get('frameworkAgreementIteration.id');
	}

	@computed
	public get label(): number {
		return this.get('label');
	}

	@computed
	public get priceExclTax(): number {
		return this.get('priceExclTax');
	}

	@computed
	public get vatId(): number {
		return parseInt(getIdFromUrn(this.get('vatUrn')));
	}
}
