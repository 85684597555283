import Card                        from 'antd/lib/card';
import Descriptions                from 'antd/lib/descriptions';
import Empty                       from 'antd/lib/empty';
import List                        from 'antd/lib/list';
import parse                       from 'html-react-parser';
import { observer }                from 'mobx-react';
import React                       from 'react';
import { quotationDashboardStore } from 'stores/index';

const QuotationItemBox = () => {
	const {
		activityCollection,
		quotationItemActivityCollection,
		quotationItemCollection,
	} = quotationDashboardStore;

	const isLoading = (
		activityCollection.isLoading
		|| quotationItemCollection.isLoading
		|| quotationItemActivityCollection.isLoading
	);

	return (
		<Card
			className="benefits-box"
			loading={isLoading}
			title="Prestations"
		>
			{!quotationItemCollection.length
				? <Empty description="Aucune prestation" /> :
				quotationItemCollection.map((quotationItem) => (
					<div key={quotationItem.id}>
						<b className="sub-title" style={{ fontSize: 14 }}>{quotationItem.title}</b>
						<div dangerouslySetInnerHTML={{ __html: quotationItem.description }} />
						<div>
							{quotationItem.publicComment}
						</div>

						<List>
							{quotationItemActivityCollection.map(quotationItemActivity => {
								if (quotationItemActivity.quotationItemId !== quotationItem.id) {
									return null;
								}

								const activity = activityCollection.find(activity => activity.id === quotationItemActivity.activityId);

								if (!activity) {
									return null;
								}

								return (
									<List.Item key={quotationItemActivity.id}>
										<div>
											<b>
												{activity.label?.toUpperCase()}
											</b>
											<div>
												{activity.description && parse(activity.description)}
											</div>
											<div>
												<Descriptions column={2} size="small">
													<Descriptions.Item label="Fréquence">
														{quotationItem.quantity} fois
													</Descriptions.Item>

													{/*<Descriptions.Item label="Qté matériel">*/}
													{/*	{quotationItemActivity.quantity}*/}
													{/*</Descriptions.Item>*/}
												</Descriptions>
											</div>
										</div>
									</List.Item>
								);
							})}
						</List>
					</div>
				))}
		</Card>
	);
};

export default observer(QuotationItemBox);
