import resolvable                  from 'decorators/resolvable';
import { computed }                from 'mobx';
import moment                      from 'moment';
import { durationFormatted }       from 'tools/DateTools';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';
import TaskModel                   from './TaskModel';
import TaskZoneModel               from './TaskZoneModel';

export default class InterventionModel extends InterventionPrivateApiModel {
	public filters: ModelFiltersExtended<{
		'interventionOperations.taskOperation.operation.operationCategory': id;
		'interventionOperations.taskOperation.task.taskMetas.value': string,
		'interventionStatus': id;
		'interventionStatus.reference': string;
		'scheduleStartDate[after]': string | Date;
		'scheduleStartDate[before]': string | Date;
		taskZone: id;
	}> = {};

	static get path(): string {
		return '/interventions/{?id}';
	}

	@computed
	public get displayDate(): string {
		const startDate = moment(this.scheduleStartDate);
		const endDate = moment(this.scheduleEndDate);

		return `${startDate.format('L')} de ${startDate.format('LT')} à ${endDate.format('LT')}`;
	}

	@computed
	public get scheduleStartDate(): string {
		return this.get('scheduleStartDate', '');
	}

	@computed
	public get scheduleStartDateMoment(): Moment {
		return moment(this.scheduleStartDate);
	}

	@computed
	public get scheduleEndDate(): string {
		return this.get('scheduleEndDate', '');
	}

	@computed
	public get scheduleEndDateMoment(): Moment {
		return moment(this.scheduleEndDate);
	}

	@computed
	public get durationFormatted(): string {
		return durationFormatted(this.scheduleStartDateMoment, this.scheduleEndDateMoment, [0, 5]);
	}

	@computed
	public get number(): string {
		return this.get('number', '');
	}

	@computed
	public get statusId(): number {
		return this.get('interventionStatus.id', 0);
	}

	@computed
	public get taskZoneId(): number {
		return this.get('taskZone.id', 0);
	}

	@computed
	public get taskId(): number {
		return this.get('task.id', 0);
	}
	
	@resolvable(TaskModel, { attributeName: 'task' })
	declare task: TaskModel;

	@resolvable(TaskZoneModel, { attributeName: 'taskZone' })
	declare taskZone: TaskZoneModel;

	
}
