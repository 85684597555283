import { ApiCollection }  from 'Collections/ApiCollection';
import { observer }       from 'mobx-react';
import React              from 'react';
import StaffMemberModel   from '../../modelx/models/private/rh/StaffMemberModel';
import StaffModel         from '../../modelx/models/private/rh/StaffModel';
import ContractActorModel from '../../modelx/models/private/sales/ContractActorModel';

const ContractActorCommercial = ({
	contractActorCommercialCollection,
	staffCollection,
	staffMemberCollection,
}: {
	contractActorCommercialCollection: ApiCollection<ContractActorModel>;
	staffCollection: ApiCollection<StaffModel>;
	staffMemberCollection: ApiCollection<StaffMemberModel>;
}) => {
	const contractActorCommercial = contractActorCommercialCollection.first();

	if (!contractActorCommercial) {
		return null;
	}

	const staffMember = staffMemberCollection.find(staffMember => staffMember.id === contractActorCommercial.actorId);

	if (!staffMember) {
		return null;
	}

	const staff = staffCollection.find(staff => staff.id === staffMember.staffId);

	if (!staff) {
		return null;
	}

	return (
		<>
			{staff.fullName}
		</>
	);
};

export default observer(ContractActorCommercial);
