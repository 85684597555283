import {
	// formatPhoneNumber,
	formatPhoneNumberIntl,
	isValidPhoneNumber,
	// parsePhoneNumber,
} from 'react-phone-number-input';

const formatPhoneNumber = (number: number | string) => {
	if (isValidPhoneNumber(String(number))) {
		// return formatPhoneNumber(parsePhoneNumber(number), 'International');
		return formatPhoneNumberIntl(String(number));
	} else {
		return number;
	}
};

export default formatPhoneNumber;
