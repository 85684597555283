import { DownloadOutlined } from '@ant-design/icons';
import { ApiCollection }    from 'Collections/ApiCollection';
import ExportModel          from 'Models/sales/ExportModel';
import ExportReadModel      from 'Models/sales/ExportReadModel';
import ExportStatusModel    from 'Models/sales/ExportStatusModel';
import Button               from 'antd/lib/button/button';
import Card                 from 'antd/lib/card';
import Empty                from 'antd/lib/empty';
import Space                from 'antd/lib/space';
import GridInfo             from 'components/GridInfo/GridInfo';
import { observer }         from 'mobx-react';
import { PathMatch }        from 'react-router';
import React                from 'react';
import { downloadBlob }     from 'tools/blobTools';
import notificationApiError from 'tools/notificationApiError';
import withBlob             from '../../../modelx/models/mixins/withBlob';

const ExportPageSalesSubComponent = observer(({ match }: { match: PathMatch<'exportId'> | null}) => {

	const [downloading, setDownloading] = React.useState(false);

	const _export = new ExportModel();
	const _exportStatusCollection = new ApiCollection(ExportStatusModel);

	React.useEffect(() => {
		(async () => {
			const id = match?.params.exportId;

			await Promise.all([
				_export.set({ id }).fetch(),
				_exportStatusCollection.list({ cache: 3600 }),
			]);
		})();
	}, []);

	const status = _exportStatusCollection.getById(_export.exportStatusId);
	const statusSent = _exportStatusCollection.findBy('reference', 'sent');

	return (
		<div
			style={{
				alignItems: 'center',
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
			}}
		>
			{_export.createdAt ? (
				<Card>
					<Space direction="vertical" size={20} style={{ width: '100%' }}>
						<GridInfo data={[
							[
								{
									label: 'Fichier',
									value: _export.fileOriginalName,
								},
								{
									label: 'Date de création',
									value: _export.createdAtMoment.format('L à LT'),
								},
								{
									label: 'État',
									value: <span style={{ color: status?.color }}>{status?.label}</span>,
								},
								{
									label: 'Date d\'expiration',
									value: `${_export.expiredAtMoment.format('L à LT')} (${
										_export.expiredAtMoment.fromNow()})`,
								},
							],
						]} />

						<Button
							block={true}
							disabled={downloading || _export.exportStatusId !== statusSent?.id}
							icon={<DownloadOutlined />}
							loading={downloading}
							onClick={async () => {
								try {
									setDownloading(true);

									const read = new (withBlob(ExportReadModel))({ id: _export.id });

									await read.fetch();

									downloadBlob(read.blob, _export.fileOriginalName);

								} catch (err) {
									notificationApiError(err);
								} finally {
									setDownloading(false);
								}
							}}
							type="primary"
						>
							Télécharger
						</Button>
					</Space>
				</Card>
			) : (
				<Empty description="Aucun résultat" />
			)}
		</div>
	);
});

export default ExportPageSalesSubComponent;
