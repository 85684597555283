import PurchaseOrderModel               from 'Models/sales/PurchaseOrderModel';
import resolvable                       from 'decorators/resolvable';
import { computed }                     from 'mobx';
import SalesPrivateApiModel             from 'modelx/models/abstracts/SalesPrivateApiModel';

export default class PurchaseOrderTargetModel extends SalesPrivateApiModel {
	public filters: ModelFiltersExtended<{
		'purchaseOrder': Urn;
		'targetUrn': Urn;
	}> = {};

	static get path(): string {
		return '/purchase_order_targets/{?id}';
	}

	@resolvable(PurchaseOrderModel, { attributeName: 'purchaseOrder' })
	declare purchaseOrder: PurchaseOrderModel;

	@computed
	public get targetUrn(): string {
		return this.get('targetUrn', '');
	}
}
