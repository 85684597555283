import Form                          from 'antd/lib/form';
import Input                         from 'antd/lib/input';
import React                         from 'react';
import ModalForm, { ModalFormProps } from '../components/ModalForm';

const ContactAccessModalForm: React.FC<ModalFormProps> = props => {

	return (
		<ModalForm title="Accès à l'interface" {...props}>
			<Form.Item
				label="E-mail"
				name="email"
				rules={[{
					message: 'Veuillez renseigner l\'e-mail',
					required: true,
				}]}
			>
				<Input placeholder="E-mail" />
			</Form.Item>

			<Form.Item
				label="Mot de passe"
				name="plainPassword"
				rules={[{
					message: 'Veuillez renseigner un mot de passe',
					required: true,
				}]}
			>
				<Input.Password placeholder="Mot de passe" />
			</Form.Item>

			<Form.Item
				label="Confirmation du mot de passe"
				name="confirmPassword"
				rules={[
					{
						message: 'Veuillez confirmer le mot de passe',
						required: true,
					},
					({ getFieldValue }) => ({
						validator(rule, value) {
							if (!value || getFieldValue('plainPassword') === value) {
								return Promise.resolve();
							}
							return Promise.reject('Les mots de passe ne sont pas identiques');
						},
					})]}
			>
				<Input.Password placeholder="Confirmation du mot de passe" />
			</Form.Item>
		</ModalForm>
	);
};

export default ContactAccessModalForm;