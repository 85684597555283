import { ArgsProps } from 'antd/lib/notification';
import notification  from 'antd/lib/notification';

export default (err: any, notificationOptions?: ArgsProps) => {
	const errorCode: string = err?.connectorError?.request?.status
		? ` (code erreur ${err?.connectorError?.request?.status})`
		: '';

	let description: string | undefined =
		(err?.connectorError?.response?.data?.['hydra:description'] ||
			err?.connectorError?.response?.data?.message ||
			err?.connectorError?.message ||
			err.message) + errorCode;

	if (description === 'undefined') {
		description = undefined;
	}

	notification.error({
		description,
		message: `Erreur`,
		...notificationOptions,
	});
};
