import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class OperationModel extends InterventionPrivateApiModel {
	public filters: ModelFiltersExtended<{
		'operationCategory.partitionUrn': string;
		'partitionUrn': string;
	}> = {};

	static get path(): string {
		return '/operations/{?id}';
	}

	@computed
	public get operationCategoryId(): number {
		return this.get('operationCategory.id', '');
	}

	@computed
	public get operationCategoryIri(): string {
		return this.get('operationCategory.@id', '');
	}

	@computed
	public get duration(): number {
		return this.get('duration');
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}


	@computed
	public get internalLabel(): string {
		return this.get('internalLabel', '');
	}

	@computed
	public get reference(): string {
		return this.get('reference', '');
	}

	@computed
	public get nbPhotos(): number {
		return this.get('nbPhotos', 0);
	}

	@computed
	public get description(): string {
		return this.get('description', '');
	}
}
