/* eslint-disable @typescript-eslint/no-explicit-any */
import Collection                     from '@mathquis/modelx/lib/types/collection';
import { ConnectorResults }           from '@mathquis/modelx';
import { CollectionError }            from '@mathquis/modelx';
import debug                          from 'debug';
import { action, override }           from 'mobx';
import { computed }                   from 'mobx';
import AbstractApiModel               from '../models/abstracts/AbstractApiModel';
import { PagedCollection }            from './PagedCollection';

const _debug = debug('modelx.collection');

type IAutoloadedPagedCollectionListOptions<T extends AbstractApiModel> = ApiConnectorOptions<T> & {
	useFilter?: ModelFilterName<T>;
};

export class AutoloadedPagedCollection<T extends AbstractApiModel> extends PagedCollection<T> {

	private _allResults: ConnectorResults[] = [];

	private _defaultItemsPerPage = 500;

	private _internalPendingRequestCount = 0;

	private _useFilterCurrentBlock = 0;
	private _useFilterFullValues: any[] = [];
	private _useFilterSubPage = 1;
	private _useFilterTotalBlocks = 0;

	@action
	public internalListOnFilter = async (options: IAutoloadedPagedCollectionListOptions<T> = {}) => {
			const finalResults = new ConnectorResults([]);

			this._allResults = [];
			this._page = 0;

			this.setItemsPerPage(this._defaultItemsPerPage);

			const filterValues = this._filters[options.useFilter as ModelFilterName<T>];

			if (!Array.isArray(filterValues)) {
				throw new Error('Filter is not an array');
			}

			this._useFilterCurrentBlock = 1;
			this._useFilterFullValues = filterValues;
			this._useFilterSubPage = 1;
			this._useFilterTotalBlocks = Math.ceil(filterValues.length / this._defaultItemsPerPage);

			do {
				const partialFilterValues = this._useFilterFullValues.slice(((this._useFilterCurrentBlock - 1) * this._defaultItemsPerPage), this._useFilterCurrentBlock * this._defaultItemsPerPage) as unknown as ModelFilters<T>[keyof ModelFilters<T>];
				this.setFilter(options.useFilter as ModelFilterName<T>, partialFilterValues);

				const currentOptions = {
					...options,
					params: {
						...options.params,
						itemsPerPage: this._defaultItemsPerPage,
						page: this._useFilterSubPage,
					}
				};

				this._internalPendingRequestCount++;

				await this.model.connector.list<T>(this as Collection<T>, currentOptions)
					.then(
						async (results: ConnectorResults) => {
						/* istanbul ignore next */
							_debug('Listed', results);

							this.total = results.res.data['hydra:totalItems'];

							this._allResults.push(results);

							finalResults.items = [
								...finalResults.items,
								...results.items,
							];

							finalResults.res = results.res;

							this.onSuccess(results);
						},
					// (err: Error) => {
					// 	this.onListError(err);
					// }
					);

				if (this.total > this._useFilterSubPage * this._defaultItemsPerPage) {
					this._useFilterSubPage = this._useFilterSubPage + 1;
				} else if (this._useFilterCurrentBlock < this._useFilterTotalBlocks) {
					this._useFilterCurrentBlock = this._useFilterCurrentBlock + 1;
					this._useFilterSubPage = 1;
				}
			} while (this.hasNextPageOnFilter);

			// reset initals values
			this.setFilter(
			options.useFilter as ModelFilterName<T>,
			// @ts-ignore
			filterValues
			// this._useFilterFullValues as ModelFilters<T>[keyof ModelFilters<T>]
			);

			return finalResults;
		};

	@action
	public internalList = async (options: IAutoloadedPagedCollectionListOptions<T> = {}) => {
			const finalResults = new ConnectorResults([]);

			this._allResults = [];
			this._page = 0;

			this.setItemsPerPage(this._defaultItemsPerPage);

			do {
				++this._page;

				const currentOptions = {
					...options,
					params: {
						...options.params,
						itemsPerPage: this._defaultItemsPerPage,
						page: this._page,
					}
				};

				this._internalPendingRequestCount++;

				await this.model.connector.list<T>(this as Collection<T>, currentOptions)
					.then(
						async (results: ConnectorResults) => {
						/* istanbul ignore next */
							_debug('Listed', results);

							this.total = results.res.data['hydra:totalItems'];

							this._allResults.push(results);

							finalResults.items = [
								...finalResults.items,
								...results.items,
							];

							finalResults.res = results.res;

							this.onSuccess(results);
						},
					// (err: Error) => {
					// 	this.onListError(err);
					// }
					);
			} while (this.hasNextPage);

			return finalResults;
		};

	@override
	public list(options: IAutoloadedPagedCollectionListOptions<T> = {}): Promise<any> {
		return new Promise((resolve, reject) => {
			this.throwIfVirtualCollection();

			const listOptions = this.prepareListOptions(options);

			/* istanbul ignore next */
			_debug('Listing collection models [%s]', this.model.constructor.name, listOptions);

			let promise;

			if (options.useFilter) {
				promise = this.internalListOnFilter(listOptions);
			} else {
				promise = this.internalList(listOptions);
			}

			return promise
				.then(
					async (result: ConnectorResults) => {
						/* istanbul ignore next */
						_debug('Listed', result);

						this.onListSuccess(result, listOptions);

						resolve( this );
					},
					(err: Error) => {
						this.onListError(err);
					}
				)
				.catch(
					(err: CollectionError) => {
						reject(err);
					}
				);
		});
	}

	@override
	protected onSuccess(results: ConnectorResults): void {
		this._internalPendingRequestCount--;
	}

	@computed
	public get hasNextPageOnFilter(): boolean {
		return this.total > this._useFilterSubPage * this._defaultItemsPerPage || this._useFilterCurrentBlock < this._useFilterTotalBlocks;
	}

	@computed
	public get realIsLoading(): boolean {
		return this._internalPendingRequestCount > 0;
	}
}
