import { computed }         from 'mobx';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export default class ActivityCategoryModel extends SalesPrivateApiModel {
	static get path(): string {
		return '/activity_categories/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label');
	}

	@computed
	public get reference(): string {
		return this.get('reference');
	}
}
