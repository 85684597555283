import { computed }         from 'mobx';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

export default class PurchaseOrderModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'purchaseOrderTargets': id;
		'purchaseOrderTargets.targetUrn': Urn;
	}> = {};

	static get path(): string {
		return '/purchase_orders/{?id}';
	}
	
	@computed
	public get date(): Moment {
		return this.get('date');
	}

	@computed
	public get amount(): number {
		return this.get('amount');
	}

	@computed
	public get reference(): string {
		return this.get('reference');
	}
}
