import Form                                                  from 'antd/lib/form';
import Select                                                from 'antd/lib/select';
import usePutUrlParams                                       from 'hooks/usePutUrlParams';
import useUrlParam                                           from 'hooks/useUrlParam';
import { observer }                                          from 'mobx-react';
import moment                                                from 'moment';
import React, { useEffect }                                  from 'react';
import { appStore }                                          from '../../stores';

function UrnFilter() {
	const {
		contractCollection,
		contractIterationCollection,
		contractUrnFilter,
		quotationCollection,
	} = appStore;

	const contractIterationCollectionClone = React.useMemo(() => contractIterationCollection.clone(), []);
	const quotationCollectionClone = React.useMemo(() => quotationCollection.clone(), []);

	const putUrlParams = usePutUrlParams();
	const defaultUrn = useUrlParam('filterUrn');

	const isLoading = (
		contractCollection.isLoading
		|| contractIterationCollectionClone.isLoading
		|| quotationCollectionClone.isLoading
	);

	useEffect(() => {
		if (defaultUrn) {
			appStore.setContractUrnFilter(defaultUrn);
		}
		else if(!isLoading) {
			if (contractIterationCollectionClone.first()) {
				putUrlParams({ 'filterUrn': contractIterationCollectionClone.first()!.urn });
			}
			else if (quotationCollectionClone.first()) {
				putUrlParams({ 'filterUrn': quotationCollectionClone.first()!.urn });
			}
		}
	}, [isLoading]);

	return (
		<Form
			initialValues={{ urn: contractUrnFilter }}
			key={contractUrnFilter}
		>
			<Form.Item
				label="Devis / Contrat"
				name="urn"
				style={{ width: 512 }}
			>
				<Select
					filterOption={false}
					loading={isLoading}
					onChange={urn => {
						appStore.setContractUrnFilter(urn.toString());
						putUrlParams({ filterUrn: urn.toString() });
					}}
					onSearch={(searchValue) => {
						if (searchValue) {
							contractIterationCollectionClone.setFilters(contractIterationCollection.getFilters());
							quotationCollectionClone.setFilters(quotationCollection.getFilters());
							contractIterationCollectionClone.replaceSorts(contractIterationCollection.getSorts());
							quotationCollectionClone.replaceSorts(quotationCollection.getSorts());
							contractIterationCollectionClone.setFilter('search', searchValue);
							quotationCollectionClone.setFilter('search', searchValue);
						}
						else {
							contractIterationCollectionClone.removeFilter('search');
							quotationCollectionClone.removeFilter('search');
						}
						contractIterationCollectionClone.list();
						quotationCollectionClone.list();
					}}
					showSearch
				>
					{contractIterationCollectionClone.length && (
						<Select.OptGroup label="Contrats">
							{contractIterationCollectionClone.map(contractIteration => {
								const contract = contractCollection.find(contract => contract.id === contractIteration.contractId);

								if (!contract) {
									return null;
								}

								return (
									<Select.Option
										key={contractIteration.urn}
										value={contractIteration.urn}
									>
										Contrat {contract.number} du {moment(contractIteration.startDate).format('L')} au {moment(contractIteration.endDate).format('L')}
									</Select.Option>
								);
							})}
						</Select.OptGroup>
					)}

					{quotationCollectionClone.length && (
						<Select.OptGroup label="Devis">
							{quotationCollectionClone.map(quotation => (
								<Select.Option
									key={quotation.urn}
									value={quotation.urn}
								>
									{quotation.number} du {moment(quotation.startDate).format('L')} au {moment(quotation.endDate).format('L')}
								</Select.Option>
							))}
						</Select.OptGroup>
					)}

				</Select>
			</Form.Item>
		</Form>
	);
}

export default observer(UrnFilter);
