export const INTERVENTION_STATUS_IN_CREATION = {
	label: 'En création',
	reference: 'in_creation',
};

export const INTERVENTION_STATUS_SCHEDULED = {
	label: 'Planifié',
	reference: 'scheduled',
};

export const INTERVENTION_STATUS_WAITING_PRE_VALIDATION = {
	label: 'En attente de pré-validation',
	reference: 'waiting_pre_validation',
};

export const INTERVENTION_STATUS_PRE_VALIDATED = {
	label: 'Pré-validation (auto/client/responsable)',
	reference: 'pre_validated',
};

export const INTERVENTION_STATUS_IN_PROGRESS = {
	label: 'Technicien démarre l\'inter',
	reference: 'in_progress',
};

export const INTERVENTION_STATUS_DONE = {
	label: 'Technicien termine l\'inter',
	reference: 'done',
};

export const INTERVENTION_STATUS_VALIDATED = {
	label: 'Validé par le client ou un responsable',
	reference: 'validated',
};

export const INTERVENTION_STATUS_POINTED = {
	label: 'Pointée',
	reference: 'pointed',
};

export const INTERVENTION_STATUS_CANCELLED = {
	label: 'Annulée',
	reference: 'cancelled',
};
