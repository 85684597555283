import { ApiCollection }      from 'Collections/ApiCollection';
import ActivityCategoryModel  from 'Models/sales/ActivityCategoryModel';
import QuotationModel         from 'Models/sales/QuotationModel';
import { observer }           from 'mobx-react';
import React                  from 'react';

const QuotationActivityCategory = ({
	activityCategoryCollection,
	quotation,
}: {
	activityCategoryCollection: ApiCollection<ActivityCategoryModel>;
	quotation: QuotationModel;
}) => {
	const activityCategory = activityCategoryCollection.find(
		activityCategory => activityCategory.id === quotation.activityCategoryId);

	if (!activityCategory) {
		return null;
	}

	return (
		<>
			{activityCategory.label}
		</>
	);
};

export default observer(QuotationActivityCategory);
