import resolvable                        from 'decorators/resolvable';
import { computed, override }            from 'mobx';
import moment                            from 'moment/moment';
import { getIdFromUrn }                  from 'tools/UrnTools';
import StaffGenders, { GenderReference } from '../../../../constants/Genders';
import PrivateDirectoryApiModel          from '../../abstracts/DirectoryPrivateApiModel';
import EnterprisePartitionModel          from './EnterprisePartitionModel';
import GlobalContactModel                from './GlobalContactModel';

export default class ContactModel extends PrivateDirectoryApiModel {

	@resolvable(EnterprisePartitionModel, { attributeName: 'enterprisePartition' })
	declare enterprisePartition: EnterprisePartitionModel;

	public filters: ModelFiltersExtended<{
		'archived': string;
		'contactContactGroups': id;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'dateOut[after]': string;
		'dateOut[before]': string;
		'dateOut[strictly_after]': string;
		'dateOut[strictly_before]': string;
		'enabled': string;
		'enabledForConnection': string;
		'enterprisePartition': id;
		'enterprisePartition.clientPartitions': id;
		'enterprisePartition.clientPartitions.clients': id;
		'enterprisePartition.clientPartitions.clients.subPartitionUrn': string;
		'enterprisePartition.clientPartitions.company': id;
		'enterprisePartition.enterprise': id;
		'globalContact': id;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	@resolvable(GlobalContactModel, { attributeName: 'globalContact' })
	declare globalContact: GlobalContactModel;

	static get path(): string {
		return '/contacts/{?id}';
	}


	@computed
	public get enabled(): boolean {
		return this.get('enabled');
	}

	@computed
	public get clientId(): number {
		return this.get('client.id', 0);
	}

	@computed
	public get firstName(): string {
		return this.globalContact.firstName;
	}

	@computed
	public get lastName(): string {
		return this.globalContact.lastName;
	}

	@computed
	public get fullName(): string {
		return `${this.firstName} ${this.lastName}`;
	}

	@computed
	public get fullNameReversed(): string {
		return `${this.lastName} ${this.firstName}`;
	}

	@computed
	public get civilityLabel(): string {
		return StaffGenders.find(g => g.reference === this.gender)?.civility || '';
	}

	@override
	public get createdAt(): string {
		return this.get('createdAt', '');
	}

	@computed
	public get faxNumber(): string {
		return this.globalContact.faxNumber;
	}

	@computed
	public get phoneNumber(): string {
		return this.globalContact.phoneNumber;
	}

	@computed
	public get phoneNumberBis(): string {
		return this.globalContact.phoneNumberBis;
	}

	@computed
	public get note(): string {
		return this.get('note', '');
	}

	@computed
	public get gender(): GenderReference {
		return this.globalContact.gender;
	}

	@computed
	public get genderName(): string {
		return this.globalContact.genderName;
	}

	@computed
	public get contactFunction(): string {
		return this.get('function', '');
	}

	@computed
	public get email(): string {
		return this.globalContact.email;
	}

	@computed
	public get dateOut(): string {
		return this.get('dateOut', '');
	}

	@computed
	public get isOut(): boolean {
		return !!this.dateOut && moment(this.dateOut).valueOf() <= moment().valueOf();
	}

	@override
	public get createdById(): number {
		return getIdFromUrn(this.get('createdBy', '')) as unknown as number || 0;
	}

	@computed
	public get initials(): string {
		return this.globalContact.initials;
	}

	@computed
	public get enterprisePartitionId(): id {
		return this.enterprisePartition.id;
	}
}
