import Tooltip from 'antd/lib/tooltip';
import React   from 'react';

interface UserEnabledCircleProps {
	enabled: boolean | undefined;
}

export default function UserEnabledCircle(props: UserEnabledCircleProps) {
	const { enabled } = props;

	const color = typeof enabled === 'undefined' ? 'black' : (
		enabled ? 'green' : 'red'
	);

	const title = typeof enabled === 'undefined' ? 'Aucun accès' : (
		enabled ? 'Activé' : 'Désactivé'
	);

	return (
		<Tooltip placement="right" title={title}>
			<div style={{
				backgroundColor: color,
				borderRadius: 5,
				height: 10,
				width: 10,
			}} />
		</Tooltip>
	);
}