import { Model } from '@mathquis/modelx';

const defaultUrnRegex = /\$:([a-z]+):([a-z_]+):(\d+)/;

export const splitUrn = (urn: string): string[] => {
	if (!urn) {
		return [];
	}

	const regex = new RegExp(defaultUrnRegex);
	const matches = urn.match(regex);

	if (!matches || matches.length !== 4) {
		console.warn('Urn "%s" does not match defaultUrnRegex', urn);

		return [];
	}

	return matches;
};

export const getIdFromUrn = (urn: string): string => splitUrn(urn)[3] || '';

export const getEntityFromUrn = (urn: string): string => splitUrn(urn)[2] || '';

export const getIdFromIri = (iri: string): string => {
	const index = iri.lastIndexOf('/');

	if (index < 0) {
		return '';
	}

	return iri.substr(index + 1);
};

export const getUrnFromModel = (model: Model) => {
	switch (model.constructor.name) {
		case 'GlobalContactModel': {
			return `$:directory:global_contact:${model.id}`;
		}
		case 'ContractIterationModel': {
			return `$:sales:contract_iteration:${model.id}`;
		}
		case 'ContractIterationItemModel': {
			return `$:sales:contract_iteration_item:${model.id}`;
		}
		case 'QuotationItemModel': {
			return `$:sales:quotation_item:${model.id}`;
		}
		default: {
			console.warn('Impossible to get urn from model');
			return '';
		}
	}
};

type UrnType = 'clientPartition' | 'enterprise' | 'taskZone' | 'contract_iteration' | 'quotation';

export const getUrnFromId = (type: UrnType, id: id) => {
	switch (type) {
		case 'enterprise':
			return '$:directory:enterprise:' + id;
		case 'clientPartition':
			return '$:directory:client_partition:' + id;
		case 'taskZone':
			return '$:intervention:task_zone:' + id;
		case 'contract_iteration':
			return '$:sales:contract_iteration:' + id;
		case 'quotation':
			return '$:sales:quotation:' + id;
		default :
			return '';
	}
};
