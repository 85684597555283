import { observer }                     from 'mobx-react';
import LoginPage                        from 'pages/public/LoginPage';
import React                            from 'react';
import PrivateLayout                    from '../projectComponents/PrivateLayout/PrivateLayout';
import { authenticationStore }          from '../stores';

export default observer(({ children }) => {
	return authenticationStore.isAuthenticated ? (
		<PrivateLayout>
			{children}
		</PrivateLayout>
	) : (
		<LoginPage />
	);
});