import { ApiCollection }                           from 'Collections/ApiCollection';
import { PagedCollection }                         from 'Collections/PagedCollection';
import OperationCategoryModel                      from 'Models/intervention/OperationCategoryModel';
import { CONTRACT_ITERATION_STATUS_ACTIVATED }     from 'constants/ContractIterationStatuses';
import { CONTRACT_ITERATION_STATUS_PRE_ACTIVATED } from 'constants/ContractIterationStatuses';
import { INTERVENTION_CONTACT_TYPE_RESPONSIBLE }   from 'constants/InterventionContactTypes';
import { INTERVENTION_STATUS_DONE }                from 'constants/InterventionStatuses';
import { QUOTATION_STATUS_ACTIVATED }              from 'constants/QuotationStatuses';
import { QUOTATION_STATUS_PRE_ACTIVATED }          from 'constants/QuotationStatuses';
import { computed }                                from 'mobx';
import ContactModel                                from '../modelx/models/private/directory/ContactModel';
import InterventionModel                           from '../modelx/models/private/intervention/InterventionModel';
import TaskZoneModel                               from '../modelx/models/private/intervention/TaskZoneModel';
import ZoneContactModel                            from '../modelx/models/private/intervention/ZoneContactModel';
import ContractIterationModel                      from '../modelx/models/private/sales/ContractIterationModel';
import ContractModel                               from '../modelx/models/private/sales/ContractModel';
import QuotationModel                              from '../modelx/models/private/sales/QuotationModel';

export default class HomeStore implements Store {

	public activatedContractIterationCollection = new ApiCollection(ContractIterationModel);
	public activatedQuotationCollection = new PagedCollection(QuotationModel);
	public contactCollection = new ApiCollection(ContactModel);
	public contractCollection = new ApiCollection(ContractModel);
	public doneInterventionCollection = new PagedCollection(InterventionModel);
	public operationCategoryCollection = new ApiCollection(OperationCategoryModel);
	public responsibleZoneContactCollection = new ApiCollection(ZoneContactModel);

	public clear() {
		this.activatedContractIterationCollection.clear();
		this.activatedQuotationCollection.clear();
		this.contractCollection.clear();
		this.doneInterventionCollection.clear();
	}

	public async fetch(): Promise<void> {
		this.activatedContractIterationCollection
			.setFilter('contractIterationStatus.reference', [
				CONTRACT_ITERATION_STATUS_PRE_ACTIVATED.reference,
				CONTRACT_ITERATION_STATUS_ACTIVATED.reference,
			]);

		this.activatedQuotationCollection
			.setFilter('quotationStatus.reference', [
				QUOTATION_STATUS_PRE_ACTIVATED.reference,
				QUOTATION_STATUS_ACTIVATED.reference,
			]);

		this.doneInterventionCollection
			.setFilter('interventionStatus.reference', INTERVENTION_STATUS_DONE.reference);

		this.operationCategoryCollection.list();

		await Promise.all([
			this.activatedContractIterationCollection.list().then(() => {
				return this.contractCollection.listByFromCollection(this.activatedContractIterationCollection, 'contractId', 'id');
			}),

			this.activatedQuotationCollection.list(),

			this.responsibleZoneContactCollection.list().then(() => {
				return this.contactCollection.listByFromCollection(this.responsibleZoneContactCollection, 'contactId', 'id');
			}),
		]);

		this.doneInterventionCollection.setFilter(
			'interventionOperations.taskOperation.task.taskMetas.value',
			[
				...this.activatedContractIterationCollection.map(contractIteration => String(contractIteration.id)),
				...this.activatedQuotationCollection.map(quotation => String(quotation.id)),
			]
		);

		await this.doneInterventionCollection.list();
	}

	public setTaskZoneFilterAndFetch = (taskZone: TaskZoneModel) => {
		this.activatedContractIterationCollection.setFilter('items.itemActivities.locationUrn', taskZone.urn);
		this.activatedQuotationCollection.setFilter('items.itemActivities.locationUrn', taskZone.urn);
		this.doneInterventionCollection.setFilter('taskZone', taskZone.id);
		this.responsibleZoneContactCollection.setFilters({
			'contactType.reference': INTERVENTION_CONTACT_TYPE_RESPONSIBLE,
			taskZone: taskZone.id,
		});

		this.fetch();
	};

	@computed
	public get isLoading(): boolean {
		return (
			this.activatedContractIterationCollection.isLoading ||
			this.activatedQuotationCollection.isLoading ||
			this.contractCollection.isLoading ||
			this.doneInterventionCollection.isLoading
		);
	}
}
