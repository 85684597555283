import { SalesFrameworkAgreementIterationStatusReference } from 'Models/sales/FrameworkAgreementIterationStatusModel';
import { SalesQuotationOptionReference }                   from 'Models/sales/QuotationOptionModel';
import { computed }                                        from 'mobx';
import SalesPrivateApiModel                                from 'modelx/models/abstracts/SalesPrivateApiModel';
import moment                                              from 'moment';
import { getIdFromUrn }                                    from 'tools/UrnTools';

type SalesFrameworkAgreementIterationReference = string; // TODO

export default class FrameworkAgreementIterationModel extends SalesPrivateApiModel {
	static get path(): string {
		return '/framework_agreement_iterations/{?id}';
	}

	public filters: ModelFiltersExtended<{
		'endDate[after]': string | Date;
		'endDate[before]': string | Date;
		'endDate[strictly_after]': string | Date;
		'endDate[strictly_before]': string | Date;
		'frameworkAgreement': id;
		'frameworkAgreement.frameworkAgreementEnterprises.enterpriseUrn': string;
		'frameworkAgreement.frameworkAgreementSubPartitions.ownerSubPartition': id;
		'frameworkAgreement.frameworkAgreementSubPartitions.ownerSubPartition.subPartitionUrn': string;
		'frameworkAgreementIterationActivities': id;
		'frameworkAgreementIterationQuotationOptions.quotationOption': id;
		'frameworkAgreementIterationQuotationOptions.quotationOption.quotationOptionEntity': id;
		'frameworkAgreementIterationQuotationOptions.quotationOption.reference': SalesQuotationOptionReference;
		'frameworkAgreementIterationStatus': id;
		'frameworkAgreementIterationStatus.reference': SalesFrameworkAgreementIterationStatusReference;
		'frameworkAgreementIterationStatusHistories': id;
		'startDate[after]': string | Date;
		'startDate[before]': string | Date;
		'startDate[strictly_after]': string | Date;
		'startDate[strictly_before]': string | Date;
	}> = {};

	@computed
	public get defaultBillingDays(): string {
		return this.get('defaultBillingDays');
	}

	@computed
	public get endDate(): Moment {
		return moment(this.get('endDate'));
	}

	@computed
	public get frameworkAgreementId(): number {
		return this.get('frameworkAgreement.id');
	}

	@computed
	public get frameworkAgreementIterationStatusId(): number {
		return this.get('frameworkAgreementIterationStatus.id');
	}

	@computed
	public get number(): number {
		return this.get('number');
	}

	@computed
	public get reference(): SalesFrameworkAgreementIterationReference {
		return this.get('number');
	}

	@computed
	public get startDate(): Moment {
		return moment(this.get('startDate'));
	}

	@computed
	public get defaultBillingFrequencyId(): number {
		return parseInt(getIdFromUrn(this.get('defaultBillingFrequencyUrn')));
	}

	@computed
	public get defaultInvoicingGroupId(): number {
		return parseInt(getIdFromUrn(this.get('defaultInvoicingGroupUrn')));
	}

	@computed
	public get defaultPaymentModeId(): number {
		return parseInt(getIdFromUrn(this.get('defaultPaymentModeUrn')));
	}

	public get defaultBillingDaysArr() {
		return this.defaultBillingDays as unknown as number[] || [];
	}
}
