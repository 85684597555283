import { Page }                                      from 'components/Page';
import React                                         from 'react';
import { frameworkAgreementIterationDashboardStore } from 'stores/index';
import FrameworkAgreementIterationDashboard          from './FrameworkAgreementIterationDashboard';

export default function FrameworkAgreementIterationDashboardPage() {
	return (
		<Page title="Tableau de bord du contract cadre">
			<FrameworkAgreementIterationDashboard
				backPath="/framework_agreements"
				onReady={id => frameworkAgreementIterationDashboardStore.fetch(id)}
				title="Tableau de bord du contrat cadre"
			/>
		</Page>
	);
}
