import { computed }           from 'mobx';
import moment                 from 'moment';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export default class ExportModel extends InvoicePrivateApiModel {
	static get path(): string {
		return '/exports/{?id}';
	}

	@computed
	public get exportStatusId(): number {
		return this.get('exportStatus.id', 0);
	}

	@computed
	public get fileOriginalName(): string {
		return this.get('fileOriginalName', '');
	}

	@computed
	public get expiredAt(): string {
		return this.get('expiredAt', '');
	}

	@computed
	public get expiredAtMoment(): Moment {
		return moment(this.expiredAt);
	}
}
