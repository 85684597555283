import { PageHeader }                        from '@ant-design/pro-layout';
import FileModel                             from 'Models/file/FileModel';
import Button                                from 'antd/lib/button';
import Descriptions                          from 'antd/lib/descriptions';
import Spin                                  from 'antd/lib/spin';
import { Page }                              from 'components/Page';
import { useParams }                         from 'react-router-dom';
import React                                 from 'react';
import { FileType }                          from '../FilesListPage/FilesListPage';

export default function FileDashboardPage() {
	const { fileId } = useParams();
	const [file, setFile] = React.useState<FileModel>();
	React.useEffect(() => {
		(async () => {
			const file = new FileModel();
			file.setId(fileId!);
			await file.fetch();
			setFile(file);
		})();
	}, [fileId]);

	if (!file || !fileId) {
		return <Spin />;
	}

	const title = 'Document ' + file.name;

	return (
		<Page title={title}>
			<PageHeader
				extra={
					<Button onClick={() => file.download(file.name)} type='primary'>Télécharger</Button>
				}
				title={title}
			>
				<Descriptions bordered column={1} labelStyle={{ width: '150px' }}>
					<Descriptions.Item label="Fichier">
						<Button onClick={() => file.download(file.name)} style={{ padding: 0 }} type='link'>{file.name}</Button>
					</Descriptions.Item>
					<Descriptions.Item label="Type">
						<span style={{ fontWeight: 'normal' }}><FileType model={file} /></span>
					</Descriptions.Item>
				</Descriptions>
			</PageHeader>
		</Page>
	);
}
