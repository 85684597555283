import { computed }         from 'mobx';
import { getIdFromUrn }     from 'tools/UrnTools';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export default class SalesContactModel extends SalesPrivateApiModel {
	static get path(): string {
		return '/contacts/{?id}';
	}

	public filters: ModelFiltersExtended<{
		'contactType.reference': string;
		'contactUrn': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'owner': id;
		'owner.clientUrn': string;
		'owner.companyUrn': string;
		'owner.enterprisePartitionUrn': string;
		'owner.enterpriseUrn': string;
		'owner.ownerSubPartition.partitionUrn': string;
		'owner.ownerSubPartition.subPartitionUrn': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	@computed
	public get position(): number {
		return this.get('number');
	}

	@computed
	public get contactId(): number {
		return parseInt(getIdFromUrn(this.get('contactUrn')));
	}

	@computed
	public get contactTypeId(): number {
		return this.get('contactType.id');
	}

	@computed
	public get contactTypeIri(): string {
		return this.get('contactType.@id');
	}
}
