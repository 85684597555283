import axios                   from 'axios';
import { authenticationStore } from 'stores/index';
import { notificationError }   from 'tools/notification';

const downloadFile = (blob, fileName) => {
	const link = document.createElement('a');

	// create a blobURI pointing to our Blob
	link.href = URL.createObjectURL(blob);
	link.download = fileName;

	// some browser needs the anchor to be in the doc
	document.body.append(link);
	link.click();
	link.remove();

	// in case the Blob uses a lot of memory
	window.addEventListener('focus', e => URL.revokeObjectURL(link.href), { once: true });
};

const downloadAsync = async (url: string, name?: string) => {
	try {
		const token = authenticationStore.session.token;
		const response = await axios.get(
			url,
			{ headers: { 'X-Platform-Authorization': `Bearer ${token}` }, responseType: 'blob' },
		);

		const fileName = name || 'file';

		downloadFile(response.data, fileName);
	} catch (error) {
		notificationError({ message: `Une erreur s'est produite` });
	}
};

export default downloadFile;

export {
	downloadAsync,
};
