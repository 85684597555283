import { ApiCollection }                               from 'Collections/ApiCollection';
import FrameworkAgreementIterationQuotationOptionModel from 'Models/sales/FrameworkAgreementIterationQuotationOptionModel';
import FrameworkAgreementIterationStatusModel          from 'Models/sales/FrameworkAgreementIterationStatusModel';
import FrameworkAgreementModel                         from 'Models/sales/FrameworkAgreementModel';
import QuotationOptionModel                            from 'Models/sales/QuotationOptionModel';
import { computed }                                    from 'mobx';
import FrameworkAgreementIterationModel                from '../modelx/models/private/sales/FrameworkAgreementIterationModel';

export default class FrameworkAgreementAgreementIterationsStore implements Store {
	public frameworkAgreementCollection = new ApiCollection(FrameworkAgreementModel);
	public frameworkAgreementIterationCollection = new ApiCollection(FrameworkAgreementIterationModel);
	public frameworkAgreementIterationQuotationOptionCollection = new ApiCollection(FrameworkAgreementIterationQuotationOptionModel);
	public frameworkAgreementIterationStatusCollection = new ApiCollection(FrameworkAgreementIterationStatusModel);
	public quotationOptionCollection = new ApiCollection(QuotationOptionModel);

	public clear() {
		this.frameworkAgreementIterationCollection.clear();
		this.frameworkAgreementIterationQuotationOptionCollection.clear();
		this.quotationOptionCollection.clear();
	}

	public async fetch(): Promise<void> {
		await Promise.all([
			this.frameworkAgreementIterationCollection
				.setFilter(
					'frameworkAgreementIterationStatus.reference',
					[
						'VALIDATED',
						'ACTIVATED',
						'SUSPENDED',
					]
				)
				.list()
				.then(async () => {
					await Promise.all([
						this.frameworkAgreementCollection.listByFromCollection(
							this.frameworkAgreementIterationCollection,
							'frameworkAgreementId',
							'id'
						),

						this.frameworkAgreementIterationQuotationOptionCollection.listByFromCollection(
							this.frameworkAgreementIterationCollection,
							'id',
							'frameworkAgreementIteration'
						).then(async () => {
							await this.quotationOptionCollection.listByFromCollection(
								this.frameworkAgreementIterationQuotationOptionCollection,
								'quotationOptionId',
								'id',
							);
						}),
					]);
				}),

			this.frameworkAgreementIterationStatusCollection.list({ cache: 3600, }),
		]);
	}

	@computed
	public get isLoading(): boolean {
		return (
			this.frameworkAgreementIterationCollection.isLoading ||
			this.frameworkAgreementIterationQuotationOptionCollection.isLoading
		);
	}
}
