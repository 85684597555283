import { ApiCollection }                        from 'Collections/ApiCollection';
import { PagedCollection }                      from 'Collections/PagedCollection';
import ActivityModel                            from 'Models/sales/ActivityModel';
import ActivityTypeModel                        from 'Models/sales/ActivityTypeModel';
import FrameworkAgreementIterationActivityModel from 'Models/sales/FrameworkAgreementIterationActivityModel';
import FrameworkAgreementIterationModel         from 'Models/sales/FrameworkAgreementIterationModel';
import Card                                     from 'antd/lib/card';
import Descriptions                             from 'antd/lib/descriptions';
import Empty                                    from 'antd/lib/empty';
import List                                     from 'antd/lib/list';
import { observer }                             from 'mobx-react';
import React                                    from 'react';
import { formatPrice }                          from 'tools/PriceHelper';

class FrameworkAgreementIterationActivityListBox extends React.Component<{
	frameworkAgreementIteration: FrameworkAgreementIterationModel;
}> {
	activityCollection = new ApiCollection(ActivityModel);
	activityTypeCollection = new ApiCollection(ActivityTypeModel);
	frameworkAgreementIterationActivityPagedCollection = new PagedCollection(FrameworkAgreementIterationActivityModel);

	public componentDidMount() {
		this.fetchData({});
	}

	public render() {
		const isLoading = (
			this.frameworkAgreementIterationActivityPagedCollection.isLoading
			|| this.activityCollection.isLoading
			|| this.activityTypeCollection.isLoading
		);

		return (
			<Card
				className="framework-agreement-iteration-activity-list-box"
				loading={isLoading}
				title="Bordereau de prix"
			>
				<List
					dataSource={this.frameworkAgreementIterationActivityPagedCollection.models}
					locale={{ emptyText: <Empty description="Aucune prestation" /> }}
					pagination={{
						current: this.frameworkAgreementIterationActivityPagedCollection.page || 1,
						defaultCurrent: this.frameworkAgreementIterationActivityPagedCollection.page || 1,
						onChange: pagination => {
							this.fetchData(pagination);
						},
						pageSize: this.frameworkAgreementIterationActivityPagedCollection.itemsPerPage,
						total: this.frameworkAgreementIterationActivityPagedCollection.total,
					}}
					renderItem={frameworkAgreementIterationActivity => {
						const activity = this.activityCollection.findBy('id', frameworkAgreementIterationActivity.activityId);
						const activityType = this.activityTypeCollection.findBy('id', activity?.activityTypeId || 0);

						// {model.activity.activityType.renderName()}
						// {model.vat.renderName()}
						// <span>{formatPrice(model.priceExclTax)} HT</span>

						return (
							<List.Item key={frameworkAgreementIterationActivity.id}>
								<Descriptions column={1} size="small">
									<Descriptions.Item label="Type de prestation">
										{activityType?.label}
									</Descriptions.Item>

									{frameworkAgreementIterationActivity.label && (
										<Descriptions.Item label="Libellé">
											{frameworkAgreementIterationActivity.label}
										</Descriptions.Item>
									)}

									<Descriptions.Item label="Tarif HT">
										{frameworkAgreementIterationActivity.priceExclTax ? formatPrice(frameworkAgreementIterationActivity.priceExclTax) : 'Sur devis'}
									</Descriptions.Item>
								</Descriptions>
							</List.Item>
						);
					}}
				/>
			</Card>
		);
	}

	private fetchData = pagination => {
		const { frameworkAgreementIteration } = this.props;

		this.frameworkAgreementIterationActivityPagedCollection.setPage(pagination.current || 1);
		this.frameworkAgreementIterationActivityPagedCollection.setItemsPerPage(pagination.pageSize || 10);

		this.frameworkAgreementIterationActivityPagedCollection.setFilter('frameworkAgreementIteration', frameworkAgreementIteration.id);
		this.frameworkAgreementIterationActivityPagedCollection.list().then(async () => {
			await this.activityCollection.listByFromCollection(
				this.frameworkAgreementIterationActivityPagedCollection,
				'activityId',
				'id'
			);

			await this.activityTypeCollection.listByFromCollection(
				this.activityCollection,
				'activityTypeId',
				'id'
			);
		});
	};
}

export default observer(FrameworkAgreementIterationActivityListBox);
