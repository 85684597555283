import { computed }                from 'mobx';
import { getIdFromUrn }            from 'tools/UrnTools';
import { getEntityFromUrn }        from 'tools/UrnTools';
import AbstractApiModel            from '../../abstracts/AbstractApiModel';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export interface IEntityModel extends AbstractApiModel {
	fullName: string,
	urn: string
}

export default class OwnerResourceModel extends InterventionPrivateApiModel {
	static get path(): string {
		return '/owner_resources/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'partitionUrn': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	@computed
	public get isVehicle() {
		return getEntityFromUrn(this.entityUrn) === 'vehicle';
	}

	@computed
	public get isTechnician() {
		return getEntityFromUrn(this.entityUrn) === 'staff_member';
	}

	public get entityUrn(): string {
		return this.get('entityUrn.@urn', '') || this.get('entityUrn', '');
	}

	@computed
	public get entityId(): id {
		return parseInt(getIdFromUrn(this.entityUrn));
	}
}
