import { ApiCollection }      from 'Collections/ApiCollection';
import ActivityCategoryModel  from 'Models/sales/ActivityCategoryModel';
import { observer }           from 'mobx-react';
import React                  from 'react';
import ContractIterationModel from '../../modelx/models/private/sales/ContractIterationModel';
import ContractModel          from '../../modelx/models/private/sales/ContractModel';

const ContractIterationContractActivityCategory = ({
	activityCategoryCollection,
	contractCollection,
	contractIteration,
}: {
	activityCategoryCollection: ApiCollection<ActivityCategoryModel>;
	contractCollection: ApiCollection<ContractModel>;
	contractIteration: ContractIterationModel;
}) => {
	const contract = contractCollection.find(contract => contract.id === contractIteration.contractId);

	if (!contract) {
		return null;
	}

	const activityCategory = activityCategoryCollection.find(
		activityCategory => activityCategory.id === contract.activityCategoryId
	);

	if (!activityCategory) {
		return null;
	}

	return (
		<>
			{activityCategory.label}
		</>
	);
};

export default observer(ContractIterationContractActivityCategory);
