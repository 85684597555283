import { computed }           from 'mobx';
import SalesPrivateApiModel   from '../../abstracts/SalesPrivateApiModel';

export default class SalesContactTypeModel extends SalesPrivateApiModel {
	static get path(): string {
		return '/contact_types/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	@computed
	public get description(): string {
		return this.get('description', '');
	}
}
