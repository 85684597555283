import { jwtDecode }               from 'jwt-decode';
import { computed }                from 'mobx';
import { getIdFromUrn }            from 'tools/UrnTools';
import DirectoryPrivateApiModel    from '../../abstracts/DirectoryPrivateApiModel';

export default class ImpersonateUserModel extends DirectoryPrivateApiModel {
	public filters: ModelFiltersExtended<{ 'partitionUrn': string; }> = {};

	static get path(): string {
		return '/impersonate_users';
	}

	@computed
	public get tokenData(): TokenData {
		return jwtDecode(this.get('token')) || {};
	}

	@computed
	public get token(): string {
		return this.get('token', '');
	}

	@computed
	public get refreshToken(): string {
		return this.get('refreshToken', '');
	}

	@computed
	public get exp(): number {
		return this.tokenData.exp;
	}

	@computed
	public get ownerId(): number {
		return parseInt(getIdFromUrn(this.tokenData['owner.urn'] as string));
	}

	@computed
	public get ownerUrn(): string {
		return this.tokenData['owner.urn'] as string;
	}

	@computed
	public get impersonateId(): number {
		return parseInt(getIdFromUrn(this.tokenData['impersonate.urn'] as string));
	}

	@computed
	public get impersonateUrn(): string {
		return this.tokenData['impersonate.urn'] as string;
	}
}
