import { computed }         from 'mobx';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export type SalesFrameworkAgreementIterationStatusReference =
	'PENDING'
	| 'TO_VALIDATE'
	| 'VALIDATED'
	| 'ACTIVATED'
	| 'SUSPENDED'
	| 'CLOSED';

export default class FrameworkAgreementIterationStatusModel extends SalesPrivateApiModel {
	static get path(): string {
		return '/framework_agreement_iteration_statuses/{?id}';
	}

	public filters: ModelFiltersExtended<{
		'frameworkAgreementIterationStatusHistories': id;
		'frameworkAgreementIterations': id;
	}> = {};

	// public _sorts: ModelSortsExtended<{
	// 	'id': string;
	// }> = {};

	@computed
	public get label(): string {
		return this.get('label');
	}

	@computed
	public get reference(): SalesFrameworkAgreementIterationStatusReference {
		return this.get('reference');
	}

	public get color() {
		switch (this.reference) {
			case 'PENDING':
				return 'blue';
			case 'TO_VALIDATE':
				return 'orange';
			case 'VALIDATED':
				return 'lightgreen';
			case 'ACTIVATED':
				return 'green';
			case 'SUSPENDED':
				return 'red';
			case 'CLOSED':
				return 'black';
		}
	}
}
