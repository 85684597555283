import { IResolvableDecoratorOptions } from '@mathquis/modelx-resolvables/lib/types/AbstractResolvableModel';
import { ResolvableModelClass }        from '@mathquis/modelx-resolvables/lib/types/AbstractResolvableModel';
import { resolvable }                  from '@mathquis/modelx-resolvables';
import { AbstractResolvableModel }     from '@mathquis/modelx-resolvables';

export default function (
	modelClass: ResolvableModelClass<AbstractResolvableModel>,
	options: WithRequiredProperty<IResolvableDecoratorOptions, 'attributeName'>,
): (
	target: AbstractResolvableModel,
	key: string,
) => void {
	return (target: AbstractResolvableModel, key: string) => {
		return resolvable(modelClass, { unresolved: modelClass, ...options })(target, key);
	};
}

