import { computed }                from 'mobx';
import { getIdFromUrn }            from 'tools/UrnTools';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class ZoneContactModel extends InterventionPrivateApiModel {
	public filters: ModelFiltersExtended<{
		'contactType.reference': string;
		contactUrn: string;
		operationCategory: id;
		taskZone: id;
	}> = {};

	static get path(): string {
		return '/zone_contacts/{?id}';
	}

	@computed
	public get contactId(): number {
		return parseInt(getIdFromUrn(this.get('contactUrn', '')));
	}

	@computed
	public get contactUrn(): string {
		return this.get('contactUrn', '');
	}

	@computed
	public get contactTypeId(): number {
		return this.get('contactType.id', 0);
	}

	@computed
	public get contactTypeIri(): string {
		return this.get('contactType.@id', '');
	}

	@computed
	public get operationCategoryId(): number {
		return this.get('operationCategory.id', 0);
	}

	@computed
	public get operationCategoryIri(): string {
		return this.get('operationCategory.@id', '');
	}

	@computed
	public get position(): number {
		return this.get('position', 0);
	}

	@computed
	public get taskZoneId(): number {
		return this.get('taskZone.id', 0);
	}
}
