import { ApiCollection }          from 'Collections/ApiCollection';
import InterventionOperationModel from 'Models/intervention/InterventionOperationModel';
import OperationCategoryModel     from 'Models/intervention/OperationCategoryModel';
import OperationModel             from 'Models/intervention/OperationModel';
import TaskOperationModel         from 'Models/intervention/TaskOperationModel';
import Skeleton                   from 'antd/lib/skeleton';
import { observer }               from 'mobx-react';
import React                      from 'react';
import InterventionModel          from '../../modelx/models/private/intervention/InterventionModel';

const AbstractInterventionListServiceCell = ({
	intervention,
	interventionOperationCollection,
	operationCategoryCollection,
	operationCollection,
	taskOperationCollection,
}: {
	intervention: InterventionModel;
	interventionOperationCollection: ApiCollection<InterventionOperationModel>;
	operationCategoryCollection: ApiCollection<OperationCategoryModel>;
	operationCollection: ApiCollection<OperationModel>;
	taskOperationCollection: ApiCollection<TaskOperationModel>;
}) => {
	if (
		interventionOperationCollection.isLoading
		|| operationCategoryCollection.isLoading
		|| operationCollection.isLoading
		|| taskOperationCollection.isLoading
	) {
		return <Skeleton active paragraph={false} />;
	}

	const firstInterventionOperation = interventionOperationCollection.find(interventionOperation => interventionOperation.interventionId === intervention.id);

	if (!firstInterventionOperation) {
		return null;
	}

	const taskOperation = taskOperationCollection.find(taskOperation => taskOperation.id === firstInterventionOperation.taskOperationId);

	if (!taskOperation) {
		return null;
	}

	const operation = operationCollection.find(operation => operation.id === taskOperation.operationId);

	if (!operation) {
		return null;
	}

	const operationCategory = operationCategoryCollection.find(operationCategory => operationCategory.id === operation.operationCategoryId);

	if (!operationCategory) {
		return null;
	}

	return (
		<>
			{operationCategory.label}
		</>
	);
};

export default observer(AbstractInterventionListServiceCell);
