import { ResourceTypeReference }   from 'Models/intervention/ResourceTypeModel';
import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class ResourceModel extends InterventionPrivateApiModel {
	static get path(): string {
		return '/resources/{?id}';
	}

	public filters: ModelFiltersExtended<{
		'ownerResource': id;
		'ownerResource.entityUrn': string;
		'ownerResource.partitionUrn': string;
		'ownerSubPartition': id;
		'ownerSubPartition.partitionUrn': string;
		'ownerSubPartition.subPartitionUrn': string;
		'resourceOperationCategories.operationCategory': id;
		'resourceType': id;
		'resourceType.reference': ResourceTypeReference;
	}> = {};

	@computed
	get ownerResourceId() {
		return this.get('ownerResource.id');
	}

	@computed
	get resourceTypeId() {
		return this.get('resourceType.id');
	}
}
