import {
	DownloadOutlined,
	EllipsisOutlined,
	EyeOutlined,
}                        from '@ant-design/icons';
import { ApiCollection } from 'Collections/ApiCollection';
import FileModel         from 'Models/file/FileModel';
import Button            from 'antd/lib/button';
import Dropdown          from 'antd/lib/dropdown';
import useTo             from 'hooks/useTo';
import { observer }      from 'mobx-react';
import {
	useNavigate,
	useParams,
}                        from 'react-router-dom';
import React             from 'react';
import { getUrnFromId }  from 'tools/UrnTools';

const goToPage = async (contractId: number, path: string, navigate) => {
	navigate(path + '?filterUrn=' + getUrnFromId('contract_iteration', contractId));
};

const downloadContractIterationPdf = (contractIterationFileCollection: ApiCollection<FileModel>) => {
	const firstContractIterationDocument = contractIterationFileCollection.first();
	firstContractIterationDocument?.download();
};

const DropdownMenu = ({
	contractIterationFileCollection,
}: {
	contractIterationFileCollection: ApiCollection<FileModel>;
}) => {
	const { id } = useParams<{ id: string; }>();

	const navigate = useNavigate();
	const to = useTo();

	const contractId = parseInt(id as string);

	return (
		<Dropdown
			menu={{
				items: [
					{
						key: 'invoice',
						label: (
							<>
								<EyeOutlined /> Voir les factures
							</>
						),
						onClick: () => goToPage(contractId, to('/my-invoices'), navigate),
					},
					{
						key: 'intervention',
						label: (
							<>
								<EyeOutlined /> Voir les prochaines interventions
							</>
						),
						onClick: () => goToPage(contractId, to('/future-interventions'), navigate),
					},
					{
						key: 'completed',
						label: (
							<>
								<EyeOutlined /> Voir les interventions effectuées
							</>
						),
						onClick: () => goToPage(contractId, to('/completed-interventions'), navigate),
					},
					{
						disabled: !contractIterationFileCollection.length,
						key: 'download',
						label: (
							<>
								<DownloadOutlined /> Télécharger le contrat
							</>
						),
						onClick: () => downloadContractIterationPdf(contractIterationFileCollection),
					},
				]
			}}
			trigger={['click']}
		>
			<Button
				style={{
					padding: '0 8px',
				}}
				type="primary"
			>
				<EllipsisOutlined
					style={{
						fontSize: 20,
						verticalAlign: 'top',
					}}
				/>
			</Button>
		</Dropdown>
	);
};

export default observer(DropdownMenu);
