import { computed }         from 'mobx';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export default class ActivityModel extends SalesPrivateApiModel {
	static get path(): string {
		return '/activities/{?id}';
	}

	public filters: ModelFiltersExtended<{
		'activityType': id;
		'activityType.accountingAnalyticCodeUrn': string;
		'activityType.accountingCodeUrn': string;
		'activityType.archived': '0' | '1';
		'partitionUrn': string;
	}> = {};

	// public _sorts: ModelSortsExtended<{
	// 	'activityType.label': string;
	// }> = {};

	@computed
	public get activityTypeId(): number {
		return this.get('activityType.id', 0);
	}

	@computed
	public get activityGroupId(): number {
		return this.get('activityGroup.id');
	}

	@computed
	public get activityGroupIri(): string {
		return this.get('activityGroup.@id');
	}

	@computed
	public get operationUrn(): string {
		return this.get('operationUrn');
	}

	@computed
	public get partitionUrn(): string {
		return this.get('partitionUrn');
	}

	@computed
	public get priceExclTax(): number {
		return this.get('priceExclTax');
	}

	@computed
	public get vatUrn(): string {
		return this.get('vatUrn');
	}

	@computed
	public get vatRate(): number {
		return this.get('vatRate');
	}

	@computed
	public get label(): string {
		return this.get('label');
	}

	@computed
	public get internalLabel(): string {
		return this.get('internalLabel');
	}

	@computed
	public get description(): string {
		return this.get('description', '');
	}
}
