import resolvable               from 'decorators/resolvable';
import { computed }             from 'mobx';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';
import ClientPartitionModel     from './ClientPartitionModel';

export default class ClientModel extends DirectoryPrivateApiModel {

	@resolvable(ClientPartitionModel, { attributeName: 'clientPartition' })
	declare clientPartition: ClientPartitionModel;
	
	public filters: ModelFiltersExtended<{
		'clientPartition': id;
		'clientPartition.company': id;
		'clientPartition.company.name': string;
		'clientPartition.enterprisePartition': id;
		'clientPartition.enterprisePartition.contacts': id;
		'clientPartition.enterprisePartition.partitionUrn': string;
		'clientPartition.number': string;
		'clientPartition.reference': string;
		'subPartitionUrn': string;
	}> = {};

	static get path(): string {
		return '/clients/{?id}';
	}

	@computed
	public get clientPartitionId(): id {
		return this.get('clientPartition.id', '');
	}

	@computed
	public get clientPartitionUrn(): string {
		return this.get('clientPartition.@urn', '');
	}

	@computed
	public get subPartitionUrn(): string {
		return this.get('subPartitionUrn', '');
	}
}
