import { DownloadOutlined }    from '@ant-design/icons';
import ReportExportZipModel    from 'Models/intervention/ReportExportZipModel';
import React                   from 'react';
import { interventionsStore }  from 'stores/index';
import notificationApiError    from 'tools/notificationApiError';
import { notificationSuccess } from 'tools/notification';

const InterventionListCompletedPageBoxMenu = () => {
	return {
		items: [
			{
				disabled: !interventionsStore.completedInterventionCollection.length,
				key: 'fiches',
				label: <><DownloadOutlined /> {`Télécharger toutes les fiches d'interventions`}</>,
				onClick: () => {
					const model = new ReportExportZipModel();

					const interventionIds = interventionsStore.completedInterventionCollection.map(intervention => intervention.id);

					if (interventionIds.length) {
						model
							.fetch({ params: { 'reportInterventions.intervention': interventionIds } })
							.then(() => {
								notificationSuccess({
									message: `L'export est en cours de génération. 
													Vous allez recevoir un email avec le lien de téléchargement`,
								});
							})
							.catch(err => notificationApiError(err));
					}
				},
			},
			{
				disabled: !interventionsStore.completedInterventionCollection.length,
				key: 'reports',
				label: <><DownloadOutlined /> {`Télécharger tous les rapports d'interventions`}</>,
				onClick: () => {
					const model = new ReportExportZipModel();

					const interventionIds = interventionsStore.completedInterventionCollection.map(intervention => intervention.id);

					if (interventionIds.length) {
						model
							.fetch({ params: { 'reportInterventions.intervention': interventionIds } })
							.then(() => {
								notificationSuccess({
									message: `L'export est en cours de génération. 
													Vous allez recevoir un email avec le lien de téléchargement`,
								});
							})
							.catch(err => notificationApiError(err));
					}
				},
			},
		]
	};
};

export default InterventionListCompletedPageBoxMenu;