import Col          from 'antd/lib/col';

import Row          from 'antd/lib/row';
import { Page }     from 'components/Page';
import React        from 'react';
import DetailsBox   from './boxes/DetailsBox';
import ManagerBox   from './boxes/ManagerBox';
import StatisticBox from './boxes/StatisticBox';
import '../css/HomePage.scss';

class HomePage extends React.Component {
	public render() {
		return (
			<Page title="Accueil">
				<div className="home-page">
					<Row gutter={20}>
						<Col md={12} xs={24}>
							<DetailsBox />

							<ManagerBox />
						</Col>

						<Col md={12} xs={24}>
							<StatisticBox />
						</Col>
					</Row>
				</div>
			</Page>
		);
	}
}

export default HomePage;
