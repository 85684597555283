import { ApiCollection }        from 'Collections/ApiCollection';
import FileModel           from 'Models/file/FileModel';
import FileSourceModel     from 'Models/file/FileSourceModel';
import FileTypeSourceModel from 'Models/file/FileTypeSourceModel';
import {
	BILLING_GROUP_META_CONTRACT_ITERATION_URN,
	BILLING_GROUP_META_QUOTATION_URN,
}                          from 'constants/BillingGroupMetaReferences';
import { INVOICE_TYPE_INVOICE } from 'constants/InvoiceTypes';
import { computed }             from 'mobx';
import InvoiceModel             from '../modelx/models/private/invoice/InvoiceModel';

export default class InvoicesStore implements Store {

	public invoiceCollection = new ApiCollection(InvoiceModel);
	public invoicePdfFileCollection = new ApiCollection(FileModel);
	public invoicePdfSourceCollection = new ApiCollection(FileSourceModel);
	public invoicePdfTypeSourceCollection = new ApiCollection(FileTypeSourceModel);

	private _urnFilter: null | string = null;

	public clear() {
		this.invoiceCollection.clear();
		this.invoicePdfFileCollection.clear();
	}

	public fetch = async () => {
		await this.invoiceCollection
			.list()
			.then(async () => {
				const invoicesUrns = this.invoiceCollection.map(i => i.urn);

				await Promise.all([
					this.invoicePdfFileCollection
						.setFilters({
							'typeSource.source.entityUrn': invoicesUrns,
							'typeSource.type.reference': 'invoice_pdf',
						})
						.list(),

					this.invoicePdfTypeSourceCollection
						.setFilter('source.urn', invoicesUrns)
						.list(),

					this.invoicePdfSourceCollection
						.setFilter('entityUrn', invoicesUrns)
						.list(),
				]);
			})
			.catch(err => {
				if (err?.request?.status === 403) {
					console.warn('Silent 403 for InvoiceCollection');
				}
			});
	};

	public getInvoicePdf = (invoice: InvoiceModel) => {
		const source = this.invoicePdfSourceCollection.findBy('entityUrn', invoice.urn);
		const typeSource = this.invoicePdfTypeSourceCollection.findBy('sourceId', source?.id);
		return this.invoicePdfFileCollection.findBy('typeSourceId', typeSource?.id);
	};

	public setUrnFilterAndFetch(urn: string) {
		this._urnFilter = urn;

		this.invoiceCollection.setFilters({
			'billingGroup.billingGroupMetas.reference': [
				BILLING_GROUP_META_CONTRACT_ITERATION_URN,
				BILLING_GROUP_META_QUOTATION_URN,
			],
			'billingGroup.billingGroupMetas.value': urn,
			'invoiceType.reference': INVOICE_TYPE_INVOICE.reference,
		});

		return this.fetch();
	}

	@computed
	public get urnFilter() {
		return this._urnFilter;
	}
}
