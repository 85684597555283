import { AbstractResolvableModel, ResolvableCollection } from '@mathquis/modelx-resolvables';
import { IModelOptions }                                 from '@mathquis/modelx/lib/types/model';
import { ApiCollection }                                 from 'Collections/ApiCollection';
import {
	action,
	computed,
	makeObservable,
	observable,
}                                                        from 'mobx';
import moment                                            from 'moment';
import { getIdFromUrn }                                  from 'tools/UrnTools';

export default abstract class AbstractApiModel extends AbstractResolvableModel {
	public filters: ModelFiltersExtended<DefaultFilters> = {};

	// public sorts: ModelSortsExtended<DefaultSorts> = {};

	@observable
	private _fetchError: Error | null;

	constructor(attributes?: Record<string, unknown>, options?: IModelOptions) {
		super(attributes, options);

		this._fetchError = null;
		makeObservable(this);
	}

	public get serviceUrn(): ServiceUrn {
		return `$:registry:service:${this.constructor['serviceName'] as ServiceName}`;
	}

	public get id(): number {
		return this.get('id', 0);
	}

	@computed
	public get iri(): string {
		return this.get('@id', '');
	}

	@computed
	public get urn(): string {
		return this.get('@urn', '');
	}

	@computed
	public get createdByUrn(): string | null {
		return this.get('createdBy', '');
	}

	@computed
	public get createdById(): number {
		return parseInt(getIdFromUrn(this.createdByUrn || '')) || 0;
	}

	@computed
	public get updatedByUrn(): string {
		return this.get('updatedBy', '');
	}

	@computed
	public get updatedById(): number {
		return parseInt(getIdFromUrn(this.updatedByUrn));
	}

	@computed
	public get createdAt(): string {
		return this.get('createdAt', '');
	}

	@computed
	public get updatedAt(): string {
		return this.get('createdAt', '');
	}

	@computed
	public get createdAtMoment(): Moment {
		return moment(this.createdAt);
	}

	public fetch(options?: ApiConnectorOptions<this>): Promise<this> {
		return super.fetch(options) as Promise<this>;
	}

	@computed
	public get updatedAtMoment(): Moment {
		return moment(this.updatedAt);
	}

	@computed
	public get createdAtFormatted(): string {
		return `${this.createdAtMoment.format('L')} à ${this.createdAtMoment.format('LT')}`;
	}

	@computed
	public get updatedAtFormatted(): string {
		return `${this.updatedAtMoment.format('L')} à ${this.updatedAtMoment.format('LT')}`;
	}

	public patch(attributes: Record<string, unknown> = {}, options: ApiConnectorOptions<this> = {}) {
		return this.save({ ...options, patchAttributes: attributes });
	}

	public patchFormData(attributes: Record<string, unknown> = {}, options: ApiConnectorOptions<this> = {}) {
		const formData = new FormData();

		Object.keys(attributes).forEach((key) => {
			const value = attributes[key];

			if (Array.isArray(value)) {
				(value as []).forEach((subValue, index) => {
					formData.append(`${key}[${index}]`, subValue);
				});
			} else {
				formData.append(key, value as (string | Blob));
			}
		});

		return this.save({
			...options,
			headers: {
				// 'Content-Type': 'multipart/form-data',
				...options.headers,
			},
			patchAttributes: formData,
		});
	}

	public setId(id: id): this {
		super.setId(id);

		return this;
	}

	public get fetchError(): Error | null {
		return this._fetchError;
	}

	public get isFirstLoading(): boolean {
		return !this.isLoaded && this.isLoading;
	}

	public get isLoadedOrLoading(): boolean {
		return this.isLoaded || this.isLoading;
	}

	public get isRefreshing(): boolean {
		return this.isLoaded && this.isLoading;
	}

	// TO IMPLEMENT
	static getResolvableCollection(): ResolvableCollection<AbstractResolvableModel> {
		return ApiCollection;
	}

	@action
	protected _setFetchError(err: Error | null) {
		this._fetchError = err;
	}

	protected onFetchSuccess(result, options: ApiConnectorOptions<this>): void {
		super.onFetchSuccess(result, options);

		this._setFetchError(null);
	}

	public get isEmpty() {
		// Lorsque tous les attributs d'un models sont undefined, on considère que c'est un model "vide".
		// Sauf si les trois propriétés (id + urn + iri) sont renseignées.

		return (
			this.isLoaded
			&& !this.isLoading
			&& (!this.id || !this.urn || !this.iri)
			&& Object.values(value => typeof value === undefined)
		);
	}
}
