import { ModelClass }                           from '@mathquis/modelx/lib/types/collection';
import { ConnectorResults, ICollectionOptions } from '@mathquis/modelx/';
import { ApiCollection }                        from 'Collections/ApiCollection';
import {
	action,
	computed,
	makeObservable,
	observable,
	override,
}                           from 'mobx';
import AbstractApiModel                         from '../models/abstracts/AbstractApiModel';

export class PagedCollection<T extends AbstractApiModel> extends ApiCollection<T> {
	@observable public total = 0;

	@observable protected _page = 1;

	@observable protected _itemsPerPage = 5;

	constructor(model: ModelClass<T>, models?: T[] | undefined, options?: ICollectionOptions | undefined) {
		super(model, models, options);
		makeObservable(this);
	}

	public clear(): this {
		this._page = 1;
		return super.clear();
	}

	public reload(options?): Promise<this> {
		this._page = 1;
		return this.list(options);
	}

	@action
	public setItemsPerPage(itemsPerPage: number): this {
		this._itemsPerPage = itemsPerPage;
		return this;
	}

	@computed
	public get itemsPerPage(): number {
		return this._itemsPerPage;
	}

	@action
	public setPage(page: number): this {
		this._page = page;
		return this;
	}

	@computed
	public get page(): number {
		return this._page;
	}

	@computed
	public get hasPreviousPage(): boolean {
		return this._page > 1;
	}

	@computed
	public get hasNextPage(): boolean {
		return this.total > this._page * this._itemsPerPage;
	}

	@computed
	public get hasSinglePage(): boolean {
		return this.total <= this._itemsPerPage;
	}

	@override
	protected onListSuccess(results: ConnectorResults, options): void {
		this.total = results.res.data['hydra:totalItems'];

		super.onListSuccess(results, options);
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	protected prepareListOptions(options): object {
		return super.prepareListOptions({
			...options,
			params: {
				itemsPerPage: this._itemsPerPage,
				page: this._page,
				pagination: true,
				...options.params,
			},
		});
	}
}
