import { computed } from 'mobx';
import {
	getEntityFromUrn,
}                   from 'tools/UrnTools';

import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class InterventionResourceModel extends InterventionPrivateApiModel {
	public filters: ModelFiltersExtended<{
		intervention: id,
		'resource.resourceType.reference': string,
	}> = {};

	static get path(): string {
		return '/intervention_resources/{?id}';
	}

	@computed
	public get interventionId(): number {
		return this.get('intervention.id', 0);
	}

	@computed
	public get resourceTypeId(): number {
		return this.get('resourceType.id', 0);
	}

	@computed
	public get resourceId(): number {
		return this.get('resource.id');
	}

	@computed
	public get resourceUrn(): string {
		return this.get('resource.@urn', '');
	}

	@computed
	public get isVehicle(): boolean {
		return getEntityFromUrn(this.resourceUrn) === 'vehicle';
	}

	@computed
	public get isTechnician(): boolean {
		return getEntityFromUrn(this.resourceUrn) === 'staff_member';
	}
}
