import { computed }                from 'mobx';
import { getIdFromUrn }            from 'tools/UrnTools';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class TaskZoneModel extends InterventionPrivateApiModel {
	public filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'owner': id;
		'owner.clientUrn': string;
		'owner.companyUrn': string;
		'owner.enterprisePartitionUrn': string;
		'owner.enterpriseUrn': string;
		'owner.ownerSubPartition.partitionUrn': string;
		'owner.ownerSubPartition.subPartitionUrn': string;
		'taskOperations.task': id;
		'taskOperations.task.taskTaskTags': id;
		'taskOperations.task.taskTaskTags.taskTag': id;
		'taskOperations.task.taskTaskTags.taskTag.reference': string;
		'taskZoneGroup': id;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	static get path(): string {
		return '/task_zones/{?id}';
	}

	@computed
	public get sourceUrn(): string {
		return this.get('sourceUrn', '');
	}

	@computed
	public get sourceId(): number {
		return parseInt(getIdFromUrn(this.sourceUrn));
	}

	@computed
	public get ownerId(): id {
		return this.get('owner.id', 0);
	}

	@computed
	public get address(): string {
		return this.get('address', '');
	}

	@computed
	public get addressBis(): string {
		return this.get('addressBis', '');
	}

	@computed
	public get city(): string {
		return this.get('city', '');
	}

	@computed
	public get country(): string {
		return this.get('country', '');
	}

	@computed
	public get zipCode(): string {
		return this.get('zipCode', '');
	}

	@computed
	public get fullAddress(): string {
		return `${this.address} ${this.zipCode} ${this.city.toUpperCase()}`;
	}

	@computed
	public get taskZoneName(): string {
		return this.get('name', '');
	}

	@computed
	public get taskZoneGroupId(): number {
		return this.get('taskZoneGroup.id');
	}

	@computed
	public get taskZoneGroupIri(): string {
		return this.get('taskZoneGroup.@id', '');
	}

	@computed
	public get phoneNumber(): string {
		return this.get('phone', '');
	}

	@computed
	public get email(): string {
		return this.get('email', '');
	}

	@computed
	public get faxNumber(): string {
		return this.get('fax', '');
	}

	@computed
	public get name(): string {
		return this.get('name');
	}
}
