import MailModel               from 'Models/directory/MailModel';
import Button                  from 'antd/lib/button';
import Card                    from 'antd/lib/card';
import Col                     from 'antd/lib/col';
import Form                    from 'antd/lib/form';
import Input                   from 'antd/lib/input';
import Row                     from 'antd/lib/row';
import { Page }                from 'components/Page';
import { observer }            from 'mobx-react';
import React                   from 'react';
import ConfigProxy             from 'tools/ConfigProxy';
import { notificationSuccess } from 'tools/notification';
import notificationApiError    from '../../utils/notificationApiError';

function ContactPage() {
	const [form] = Form.useForm();

	// const goBack = () => {
	// 	console.log('@todo');
	// };

	const onFinish = (values: { content: string; subject: string; }) => {
		const mail = new MailModel({
			content: values.content,
			partitionUrn: ConfigProxy.get('PARTITION_URN'),
			subject: values.subject,
		});
		mail
			.save()
			.then(() => {
				notificationSuccess({ message: 'Message envoyé !', });
				form.resetFields();
			})
			.catch(notificationApiError);
	};

	return (
		<Page title="Contactez-nous">
			<div>
				<Row gutter={20}>
					<Col
						md={{
							offset: 6,
							span: 12,
						}}
						xs={24}
					>
						<Card
							title="Contactez-nous"
						>
							<Form
								form={form}
								initialValues={{

								}}
								layout="vertical"
								name="default_form_name"
								onFinish={onFinish}
							>
								<Form.Item
									label="Sujet"
									name="subject"
									rules={[
										{
											message: 'Veuillez renseigner le sujet',
											required: true,
										},
									]}
								>
									<Input placeholder="Sujet" />
								</Form.Item>

								<Form.Item
									label="Message"
									name="content"
									rules={[
										{
											message: 'Veuillez renseigner le message',
											required: true,
										},
									]}
								>
									<Input.TextArea
										placeholder="Message"
										rows={5}
									/>
								</Form.Item>

								<Form.Item
									style={{ textAlign: 'right', }}
								>
									{/*<Button*/}
									{/*	htmlType="button"*/}
									{/*	onClick={goBack}*/}
									{/*>*/}
									{/*	Annuler*/}
									{/*</Button>*/}
									{/*{` `}*/}
									<Button
										htmlType="submit"
										type="primary"
									>
										Envoyer
									</Button>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</div>
		</Page>
	);
}

export default observer(ContactPage);
