import { ApiCollection }                               from 'Collections/ApiCollection';
import { PagedCollection }                             from 'Collections/PagedCollection';
import FrameworkAgreementIterationModel                from 'Models/sales/FrameworkAgreementIterationModel';
import FrameworkAgreementIterationQuotationOptionModel from 'Models/sales/FrameworkAgreementIterationQuotationOptionModel';
import QuotationOptionModel                            from 'Models/sales/QuotationOptionModel';
import Card                                            from 'antd/lib/card';
import Descriptions                                    from 'antd/lib/descriptions';
import Empty                                           from 'antd/lib/empty';
import List                                            from 'antd/lib/list';
import { observer }                                    from 'mobx-react';
import React                                           from 'react';

class FrameworkAgreementIterationQuotationOptionListBox extends React.Component<{
	frameworkAgreementIteration: FrameworkAgreementIterationModel;
}> {
	frameworkAgreementQuotationOptionPagedCollection = new PagedCollection(FrameworkAgreementIterationQuotationOptionModel);
	quotationOptionCollection = new ApiCollection(QuotationOptionModel);

	public componentDidMount() {
		this.fetchData({});
	}

	public render() {
		const isLoading = (
			this.frameworkAgreementQuotationOptionPagedCollection.isLoading
			|| this.quotationOptionCollection.isLoading
		);

		return (
			<Card
				className="framework-agreement-iteration-quotation-option-list-box"
				loading={isLoading}
				title="Options"
			>
				<List
					dataSource={this.frameworkAgreementQuotationOptionPagedCollection.models}
					locale={{ emptyText: <Empty description="Aucune option" /> }}
					pagination={{
						current: this.frameworkAgreementQuotationOptionPagedCollection.page || 1,
						defaultCurrent: this.frameworkAgreementQuotationOptionPagedCollection.page || 1,
						onChange: pagination => {
							this.fetchData(pagination);
						},
						pageSize: this.frameworkAgreementQuotationOptionPagedCollection.itemsPerPage,
						total: this.frameworkAgreementQuotationOptionPagedCollection.total,
					}}
					renderItem={(frameworkAgreementIterationQuotationOption: FrameworkAgreementIterationQuotationOptionModel) => {
						const quotationOption = this.quotationOptionCollection.findBy('id', frameworkAgreementIterationQuotationOption.quotationOptionId);

						return (
							<List.Item key={frameworkAgreementIterationQuotationOption.id}>
								<Descriptions column={1} size="small">
									<Descriptions.Item label="Titre">
										{quotationOption?.label}
									</Descriptions.Item>

									<Descriptions.Item label="Description">
										{quotationOption?.description}
									</Descriptions.Item>
								</Descriptions>
							</List.Item>
						);
					}}
				/>
			</Card>
		);
	}

	private fetchData = pagination => {
		const { frameworkAgreementIteration } = this.props;

		this.frameworkAgreementQuotationOptionPagedCollection.setPage(pagination.current || 1);
		this.frameworkAgreementQuotationOptionPagedCollection.setItemsPerPage(pagination.pageSize || 10);

		this.frameworkAgreementQuotationOptionPagedCollection.setFilter('frameworkAgreementIteration', frameworkAgreementIteration.id);
		this.frameworkAgreementQuotationOptionPagedCollection.list().then(() => {
			this.quotationOptionCollection.listByFromCollection(
				this.frameworkAgreementQuotationOptionPagedCollection,
				'quotationOptionId',
				'id'
			);
		});
	};
}

export default observer(FrameworkAgreementIterationQuotationOptionListBox);
