import { ApiCollection }    from 'Collections/ApiCollection';
import ClientPartitionModel from 'Models/directory/ClientPartitionModel';
import ContactModel         from '../modelx/models/private/directory/ContactModel';

export default class ContactsStore implements Store {

	public contactCollection = new ApiCollection(ContactModel);

	public clear(): void {
		this.contactCollection.clear();
	}

	public fetch = async () => {
		await this.contactCollection.list();
	};

	public setClientPartitionFilterAndFetch = async (clientPartition: ClientPartitionModel) => {
		this.contactCollection
			.setFilter('enterprisePartition.clientPartitions', clientPartition.id);

		await this.fetch();
	};

	public setContactSearchFilterAndFetch = async (contactSearch: string) => {
		this.contactCollection
			.setFilter('search', contactSearch);

		await this.fetch();
	};
}
