import { EllipsisOutlined } from '@ant-design/icons/lib';
import InvoiceModel         from 'Models/invoice/InvoiceModel';
import Button               from 'antd/lib/button';
import Dropdown             from 'antd/lib/dropdown';
import { observer }         from 'mobx-react';
import React                from 'react';
import { invoicesStore }    from 'stores/index';
import InvoiceMenu          from './InvoiceMenu';

interface IInvoiceDropdownProps {
	invoice: InvoiceModel;
}

@observer
class InvoiceDropdown extends React.Component<IInvoiceDropdownProps> {
	public render() {
		const {
			invoice,
		} = this.props;

		const invoicePdfFile = invoicesStore.getInvoicePdf(invoice);

		return (
			<Dropdown
				menu={InvoiceMenu({ invoicePdfFile: invoicePdfFile })}
				trigger={['click']}
			>
				<Button style={{ border: 'none', padding: 0 }}>
					<EllipsisOutlined style={{ fontSize: 20 }} />
				</Button>
			</Dropdown>
		);
	}
}

export default InvoiceDropdown;
