import { computed }         from 'mobx';
import { getIdFromUrn }     from 'tools/UrnTools';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export default class QuotationActorModel extends SalesPrivateApiModel {
	public filters: ModelFiltersExtended<{
		quotation: id,
		'quotationActorGroup.reference': string
	}> = {};

	static get path(): string {
		return '/quotation_actors/{?id}';
	}

	@computed
	public get actorId(): number {
		return parseInt(getIdFromUrn(this.get('actorUrn')));
	}

	@computed
	public get actorUrn(): string {
		return this.get('actorUrn');
	}

	@computed
	public get position(): number {
		return this.get('position');
	}

	@computed
	public get quotationActorGroupId(): number {
		return this.get('quotationActorGroup.id');
	}
}
