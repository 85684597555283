export const QUOTATION_ACTOR_GROUP_COMMERCIAL = {
	label: 'Commercial',
	reference: 'QUOTATION_ACTOR_GROUP_COMMERCIAL',
};

export const QUOTATION_ACTOR_GROUP_BUYING = {
	label: 'Contact Achat',
	reference: 'QUOTATION_ACTOR_GROUP_BUYING',
};

export default {
	QUOTATION_ACTOR_GROUP_BUYING,
	QUOTATION_ACTOR_GROUP_COMMERCIAL,
};
