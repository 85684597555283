import { computed }         from 'mobx';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export default class QuotationStatusModel extends SalesPrivateApiModel {
	public filters: ModelFiltersExtended<{ reference: string; }> = {};

	static get path(): string {
		return '/quotation_statuses/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label');
	}

	@computed
	public get reference(): string {
		return this.get('reference');
	}
}
