import { computed }                from 'mobx';
import InvoicePrivateApiModel      from '../../abstracts/InvoicePrivateApiModel';

export default class ContactTypeModel extends InvoicePrivateApiModel {
	static get path(): string {
		return '/contact_types/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	@computed
	public get description(): string {
		return this.get('description', '');
	}
}
