import { ApiCollection }              from 'Collections/ApiCollection';
import InvoicingContactModel          from 'Models/invoice/InvoicingContactModel';
import SalesContactModel              from 'Models/sales/SalesContactModel';
import Button                         from 'antd/lib/button';
import Col                            from 'antd/lib/col';
import { FormInstance }               from 'antd/lib/form/Form';
import Form                           from 'antd/lib/form';
import notification                   from 'antd/lib/notification';
import Radio                          from 'antd/lib/radio';
import Row                            from 'antd/lib/row';
import Select                         from 'antd/lib/select';
import React, { useEffect, useState } from 'react';
import ModalForm, { ModalFormProps }  from '../components/ModalForm';
import ContactModel                   from '../modelx/models/private/directory/ContactModel';
import InvoiceContactTypeModel        from '../modelx/models/private/invoice/ContactTypeModel';
import SalesContactTypeModel          from '../modelx/models/private/sales/SalesContactTypeModel';
import { appStore }                   from '../stores';
import suggestCreateUser              from '../utils/suggestCreateUser';
import ContactAccessModalForm         from './ContactAccessModalForm';
import ContactModalForm               from './ContactModalForm';

interface ClientContactModalForm extends ModalFormProps {
	invoiceContactTypeCollection: ApiCollection<InvoiceContactTypeModel>;
	invoicingContactCollection: ApiCollection<InvoicingContactModel>;
	salesContactCollection: ApiCollection<SalesContactModel>;
	salesContactTypeCollection: ApiCollection<SalesContactTypeModel>;
	type?: string;
}

const modalFormRef = React.createRef<FormInstance>();

const ClientContactModalForm: React.FC<ClientContactModalForm> = props => {
	useEffect(() => {
		if (props.type) {
			setContactType(props.type);
		}
	}, [props.type]);

	const {
		invoiceContactTypeCollection,
		invoicingContactCollection,
		salesContactCollection,
		salesContactTypeCollection,
	} = props;

	const [addContactAccessVisible, setAddContactAccessVisible] = useState(false);
	const [contactType, setContactType] = useState<string>();
	const [currentContact, setCurrentContact] = useState<ContactModel>();
	const [modalVisible, setModalVisible] = useState(false);

	return (
		<div>
			<ModalForm ref={modalFormRef} title="Ajouter un contact" {...props}>
				<Form.Item
					name="type"
					rules={[{
						message: 'Veuillez renseigner le type du point de contact',
						required: true,
					}]}
				>
					<Radio.Group
						onChange={v => setContactType(v.target.value)}
						size="large"
					>
						<Row gutter={20}>
							{invoiceContactTypeCollection.map(contactType => (
								<Col key={contactType.iri} lg={12} md={12} xs={24}>
									<Radio.Button
										style={{ height: 'auto', padding: 20 }}
										value={contactType.iri}
									>
										<b>{contactType.label}</b>
										<div style={{ fontSize: 14, lineHeight: '18px' }}>
											{contactType.description}
										</div>
									</Radio.Button>
								</Col>
							))}
							{salesContactTypeCollection.map(contactType => (
								<Col key={contactType.iri} lg={12} md={12} xs={24}>
									<Radio.Button
										style={{ height: 'auto', padding: 20 }}
										value={contactType.iri}
									>
										<b>{contactType.label}</b>
										<div style={{ fontSize: 14, lineHeight: '18px' }}>
											{contactType.description}
										</div>
									</Radio.Button>
								</Col>
							))}
						</Row>
					</Radio.Group>
				</Form.Item>

				{contactType && (
					<>
						<Form.Item
							label="Contact pré-enregistré"
							name="contact"
							rules={[{
								message: 'Veuillez renseigner un contact',
								required: true,
							}]}
						>
							<Select placeholder="Contact pré-enregistré">
								{appStore.contactCollection.map(contact => {
									const isDisabled = (
										!!invoicingContactCollection.find(invoicingContact => invoicingContact.contactTypeIri === contactType && invoicingContact.contactId === contact.id)
										|| !!salesContactCollection.find(salesContact => salesContact.contactTypeIri === contactType && salesContact.contactId === contact.id)
									);

									return (
										<Select.Option
											disabled={isDisabled}
											key={contact.id}
											value={contact.urn}
										>
											{contact.fullNameReversed}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>

						<Button onClick={() => setModalVisible(true)} type="default">
							Nouveau contact
						</Button>

						{/*Ajouter un contact*/}
						<ContactModalForm
							onCancel={() => setModalVisible(false)}
							onSubmit={async (values) => {
								const contact = new ContactModel();

								await contact.set({
									...values,
									clientUrn: appStore.selectedClient?.urn,
								}).save();

								appStore.contactCollection.push(contact);

								modalFormRef?.current?.setFieldsValue({ contact: contact.iri });

								notification.success({
									description: 'Le contact a bien été ajouté à l\'entreprise',
									message: 'Contact ajouté',
								});

								suggestCreateUser(
									() => {
										// Ouverture du formulaire utilisateur
										setCurrentContact(contact);
										setAddContactAccessVisible(true);
									},
								);

								setModalVisible(false);
							}}
							open={modalVisible}
						/>

						{/*Ajouter un utilisateur*/}
						<ContactAccessModalForm
							formProps={{
								initialValues: {
									email: currentContact?.email,
								},
							}}
							onCancel={() => setAddContactAccessVisible(false)}
							onSubmit={async (values) => {
								if (currentContact) {
									await currentContact.patch({
										...values,
										enabled: true,
									});

									notification.success({ message: `Accès à l'interface enregistrés` });
								}

								setAddContactAccessVisible(false);
							}}
							open={addContactAccessVisible}
						/>
					</>
				)}
			</ModalForm>
		</div>
	);
};

export default ClientContactModalForm;