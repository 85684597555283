import { ApiCollection }                    from 'Collections/ApiCollection';
import FileModel                            from 'Models/file/FileModel';
import { QUOTATION_ACTOR_GROUP_COMMERCIAL } from 'constants/QuotationActorGroups';
import StaffMemberModel                     from '../modelx/models/private/rh/StaffMemberModel';
import StaffModel                           from '../modelx/models/private/rh/StaffModel';
import ActivityModel                        from '../modelx/models/private/sales/ActivityModel';
import QuotationActorModel                  from '../modelx/models/private/sales/QuotationActorModel';
import QuotationItemActivityModel           from '../modelx/models/private/sales/QuotationItemActivityModel';
import QuotationItemModel                   from '../modelx/models/private/sales/QuotationItemModel';
import QuotationModel                       from '../modelx/models/private/sales/QuotationModel';

export default class QuotationDashboardStore implements Store {

	public activityCollection = new ApiCollection(ActivityModel);
	public quotation = new QuotationModel();
	public quotationActorCollection = new ApiCollection(QuotationActorModel);
	public quotationFileCollection = new ApiCollection(FileModel);
	public quotationItemActivityCollection = new ApiCollection(QuotationItemActivityModel);
	public quotationItemCollection = new ApiCollection(QuotationItemModel);
	public staffCollection = new ApiCollection(StaffModel);
	public staffMemberCollection = new ApiCollection(StaffMemberModel);

	public clear(): void {
		this.activityCollection.clear();
		this.quotation.clear();
		this.quotationActorCollection.clear();
		this.quotationFileCollection.clear();
		this.quotationItemActivityCollection.clear();
		this.quotationItemCollection.clear();
	}

	public async fetch(id: id): Promise<void> {
		this.quotation.setId(id);
		this.quotationActorCollection.setFilters({
			quotation: id,
			'quotationActorGroup.reference': QUOTATION_ACTOR_GROUP_COMMERCIAL.reference,
		});
		this.quotationItemCollection.setFilter('quotation', id);
		this.quotationItemActivityCollection.setFilter('quotationItem.quotation', id);

		await Promise.all([
			this.quotation.fetch().then(() => {
				this.quotationFileCollection.setFilters({
					'typeSource.source.entityUrn': this.quotation.urn,
					'typeSource.type.reference': 'quotation_quotation',
				});
				return this.quotationFileCollection.list();
			}),
			this.quotationActorCollection.list().then(async () => {
				await this.staffMemberCollection.listByFromCollection(this.quotationActorCollection, 'actorId', 'id');
				await this.staffCollection.listByFromCollection(this.staffMemberCollection, 'staffId', 'id');
			}),
			this.quotationItemCollection.list(),
			this.quotationItemActivityCollection.list().then(async () => {
				await this.activityCollection.listByFromCollection(this.quotationItemActivityCollection, 'activityId', 'id');
			}),
		]);
	}
}
