import { computed }           from 'mobx';
import { getIdFromUrn }       from 'tools/UrnTools';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export default class InvoicingContactModel extends InvoicePrivateApiModel {
	public filters: ModelFiltersExtended<{
		enterprisePartitionUrn: string;
	}> = {};

	static get path(): string {
		return '/invoicing_contacts/{?id}';
	}

	@computed
	public get contactTypeId(): number {
		return this.get('contactType.id');
	}

	@computed
	public get contactTypeIri(): string {
		return this.get('contactType.@id');
	}

	@computed
	public get position(): number {
		return this.get('position');
	}

	@computed
	public get contactId(): number {
		return parseInt(getIdFromUrn(this.get('contactUrn')));
	}

	@computed
	public get contactUrn(): string {
		return this.get('contactUrn');
	}
}
