import { ApiCollection }                   from 'Collections/ApiCollection';
import FileModel                           from 'Models/file/FileModel';
import { CONTRACT_ACTOR_GROUP_COMMERCIAL } from 'constants/ContractActorGroups';
import StaffMemberModel                    from '../modelx/models/private/rh/StaffMemberModel';
import StaffModel                          from '../modelx/models/private/rh/StaffModel';
import ActivityModel                       from '../modelx/models/private/sales/ActivityModel';
import ContractActorModel                  from '../modelx/models/private/sales/ContractActorModel';
import ContractIterationItemActivityModel  from '../modelx/models/private/sales/ContractIterationItemActivityModel';
import ContractIterationItemModel          from '../modelx/models/private/sales/ContractIterationItemModel';
import ContractIterationModel              from '../modelx/models/private/sales/ContractIterationModel';
import ContractModel                       from '../modelx/models/private/sales/ContractModel';

export default class ContractDashboardStore implements Store {

	public activityCollection = new ApiCollection(ActivityModel);
	public contract = new ContractModel();
	public contractActorCollection = new ApiCollection(ContractActorModel);
	public contractIteration = new ContractIterationModel();
	public contractIterationFileCollection = new ApiCollection(FileModel);
	public contractIterationItemActivityCollection = new ApiCollection(ContractIterationItemActivityModel);
	public contractIterationItemCollection = new ApiCollection(ContractIterationItemModel);
	public staffCollection = new ApiCollection(StaffModel);
	public staffMemberCollection = new ApiCollection(StaffMemberModel);

	public clear(): void {
		this.activityCollection.clear();
		this.contract.clear();
		this.contractActorCollection.clear();
		this.contractIteration.clear();
		this.contractIterationFileCollection.clear();
		this.contractIterationItemActivityCollection.clear();
		this.contractIterationItemCollection.clear();
	}

	public async fetch(id: id): Promise<void> {
		this.contractIteration.setId(id);
		this.contractIterationItemCollection.setFilter('contractIteration', id);
		this.contractIterationItemActivityCollection.setFilter('contractIterationItem.contractIteration', id);

		await Promise.all([
			this.contractIteration.fetch().then(() => {
				this.contract.setId(this.contractIteration.contractId);
				this.contract.fetch();

				this.contractActorCollection.setFilters({
					contract: this.contractIteration.contractId,
					'contractActorGroup.reference': CONTRACT_ACTOR_GROUP_COMMERCIAL.reference,
				});
				this.contractActorCollection.list().then(async () => {
					await this.staffMemberCollection.listByFromCollection(this.contractActorCollection, 'actorId', 'id');
					await this.staffCollection.listByFromCollection(this.staffMemberCollection, 'staffId', 'id');
				});


				this.contractIterationFileCollection.setFilters({
					'typeSource.source.entityUrn': this.contractIteration.urn,
					'typeSource.type.reference': 'contract_iteration_contract',
				});
				this.contractIterationFileCollection.list();
			}),
			this.contractIterationItemCollection.list(),
			this.contractIterationItemActivityCollection.list().then(async () => {
				await this.activityCollection.listByFromCollection(this.contractIterationItemActivityCollection, 'activityId', 'id');
			}),
		]);
	}
}
