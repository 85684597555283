import { computed }           from 'mobx';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export default class InvoiceModel extends InvoicePrivateApiModel {
	public filters: ModelFiltersExtended<{
		'billingGroup.billingGroupMetas.reference': string;
		'billingGroup.billingGroupMetas.value': string;
		'billingGroup.invoicingGroup.owner.partitionUrn': string;
		'billingPeriod': id;
		'invoiceMetas.reference': string;
		'invoiceStatus': id;
		'invoiceType': string;
		'invoiceType.reference': string;
		'owner.partitionUrn': string;
		'refundedByBillables.billable': id;
		'refundedByInvoices.refund': id;
		'refundedInvoices.invoiceRefunded': id;
		'refunds.refundedInvoice': id;
	}> = {};

	static get path(): string {
		return '/invoices/{?id}';
	}

	@computed
	public get number(): number {
		return this.get('number');
	}

	@computed
	public get label(): string {
		return this.get('string');
	}

	@computed
	public get reference(): string {
		return this.get('reference');
	}

	@computed
	public get billingGroupId(): number {
		return this.get('billingGroup.id');
	}

	@computed
	public get billingPeriodId(): number {
		return this.get('billingPeriod.id');
	}

	@computed
	public get invoiceTypeId(): number {
		return this.get('invoiceType.id');
	}

	@computed
	public get invoiceStatusId(): number {
		return this.get('invoiceStatus.id');
	}

	@computed
	public get amountExclTax(): number {
		return this.get('amountExclTax');
	}

	@computed
	public get amountVat(): number {
		return this.get('amountVat');
	}

	@computed
	public get amountInclTax(): number {
		return this.get('amountInclTax');
	}

	@computed
	public get date(): string {
		return this.get('date');
	}

	@computed
	public get dueDate(): string {
		return this.get('dueDate');
	}

	@computed
	public get address(): string {
		return this.get('address');
	}

	@computed
	public get addressBis(): string {
		return this.get('addressBis');
	}

	@computed
	public get city(): string {
		return this.get('city');
	}

	@computed
	public get country(): string {
		return this.get('country');
	}

	@computed
	public get zipCode(): string {
		return this.get('zipCode');
	}

	@computed
	public get freeReference(): string {
		return this.get('freeReference');
	}

	@computed
	public get ownerId(): number {
		return this.get('owner.id');
	}
}
