import { observer }      from 'mobx-react';
import React             from 'react';
import UserEnabledCircle from '../../../components/UserEnabledCircle';
import ContactModel      from '../../../modelx/models/private/directory/ContactModel';

const EnabledComp = ({
	contact,
}: {
	contact: ContactModel;
}) => {
	return (
		<div
			style={{
				alignItems: 'center',
				display: 'flex',
			}}
		>
			<UserEnabledCircle enabled={contact?.enabled} />
			<span style={{ marginLeft: 6 }}>{contact?.enabled ? 'Oui' : 'Non'}</span>
		</div>
	);
};

export default observer(EnabledComp);
