import { computed, override }         from 'mobx';
import moment                         from 'moment';
import SalesPrivateApiModel           from '../../abstracts/SalesPrivateApiModel';

export default class ContractModel extends SalesPrivateApiModel {
	public filters: ModelFiltersExtended<{
		'owner.clientUrn': string;
		'owner.partitionUrn': string;
	}> = {};

	static get path(): string {
		return '/contracts/{?id}';
	}

	@computed
	public get activityCategoryId(): number {
		return this.get('activityCategory.id');
	}

	@computed
	public get activityCategoryIri(): string {
		return this.get('activityCategory.@id');
	}

	@computed
	public get activityCategoryUrn(): string {
		return this.get('activityCategory.@urn');
	}

	@computed
	public get contractStatusIri(): string {
		return this.get('contractStatus.@id');
	}

	@computed
	public get contractStatusUrn(): string {
		return this.get('contractStatus.@urn');
	}

	@override
	public get createdAt(): string {
		return this.get('createdAt');
	}

	@computed
	public get fullNumber(): string {
		let fullNumber = this.number;

		if (this.oldNumber) {
			fullNumber = fullNumber + ' (' + this.oldNumber + ')';
		}

		return fullNumber;
	}

	@computed
	public get number(): string {
		return this.get('number');
	}

	@computed
	public get oldNumber(): string {
		return this.get('oldNumber');
	}

	@computed
	public get ownerId(): number {
		return this.get('owner.id');
	}

	@computed
	public get ownerIri(): string {
		return this.get('owner.@id');
	}

	@computed
	public get ownerUrn(): string {
		return this.get('owner.@urn');
	}

	@computed
	public get reccuringModeId(): string {
		return this.get('reccuringMode.id');
	}

	@computed
	public get reccuringModeIri(): string {
		return this.get('reccuringMode.@id');
	}

	@computed
	public get reccuringModeUrn(): string {
		return this.get('reccuringMode.@urn');
	}

	@override
	public get updatedAt(): string {
		return this.get('updatedAt');
	}

	@computed
	public get isRecent(): boolean {
		return moment(this.createdAt).diff(moment(), 'days') <= 30;
	}
}
