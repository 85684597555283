import { PlusOutlined }             from '@ant-design/icons';
import Button                       from 'antd/lib/button';
import Col                          from 'antd/lib/col';
import notification                 from 'antd/lib/notification';
import Row                          from 'antd/lib/row';
import { observer }                 from 'mobx-react';
import React, { useState }          from 'react';
import { appStore, functionsStore } from 'stores/index';
import TaskZoneContactModalForm     from '../../../forms/TaskZoneContactModalForm';
import ZoneContactModel             from '../../../modelx/models/private/intervention/ZoneContactModel';
import notificationApiError         from '../../../utils/notificationApiError';
import TaskZoneServiceContactsBox   from './TaskZoneServiceContactsBox';

const TaskZoneSection = () => {
	const [modalVisible, setModalVisible] = useState(false);

	const {
		selectedTaskZone,
	} = appStore;

	const {
		operationCategoryCollection,
		taskZoneContactCollection,
		taskZoneContactTypeCollection,
		zoneContactCollection,
	} = functionsStore;

	return (
		<div>
			<div
				style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}
			>
				<b style={{ fontSize: 20 }}>
					Contacts du site {selectedTaskZone?.taskZoneName}
				</b>

				<Button onClick={() => setModalVisible(true)} type="primary">
					<PlusOutlined /> Ajouter un point de contact
				</Button>
			</div>

			<TaskZoneContactModalForm
				onCancel={() => setModalVisible(false)}
				onSubmit={values => {
					const zoneContact = new ZoneContactModel({
						contactType: values.type,
						contactUrn: values.contact,
						operationCategory: values.operationCategory,
						taskZone: appStore.selectedTaskZone?.iri,
					});

					zoneContact
						.save()
						.then(() => {
							setModalVisible(false);

							functionsStore.fetchTaskZoneContact();

							notification.success({
								description: 'Le point de contact a bien été ajouté',
								message: 'Point de contact ajouté',
							});
						})
						.catch(notificationApiError);
				}}
				open={modalVisible}
				taskZoneContactTypeCollection={taskZoneContactTypeCollection}
				width={650}
				zoneContactCollection={zoneContactCollection}
			/>

			<Row gutter={20}>
				{taskZoneContactTypeCollection.map(contactType => {
					const serviceContacts = zoneContactCollection.filter(zoneContact => zoneContact.contactTypeId === contactType.id);

					return (
						<Col
							key={contactType.iri}
							span={6}
						>
							<TaskZoneServiceContactsBox
								contactCollection={taskZoneContactCollection}
								label={contactType.label}
								operationCategoryCollection={operationCategoryCollection}
								serviceContacts={serviceContacts}
								type={contactType.iri}
								zoneContactCollection={zoneContactCollection}
							/>
						</Col>
					);
				})}
			</Row>
		</div>
	);
};

export default observer(TaskZoneSection);
