import { resolvable }           from '@mathquis/modelx-resolvables';
import { computed }             from 'mobx';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';
import CompanyModel             from './CompanyModel';

export default class ClientPartitionModel extends DirectoryPrivateApiModel {

	@resolvable(CompanyModel, { attributeName: 'company' })
	declare company: CompanyModel;
	
	public filters: ModelFiltersExtended<{
		'company': id;
		'company.name': string;
		'enterprisePartition': id;
		'enterprisePartition.contacts': id;
		'enterprisePartition.partitionUrn': string;
		'number': string;
		'reference': string;
	}> = {};

	static get path(): string {
		return '/client_partitions/{?id}';
	}

	@computed
	public get number(): number {
		return this.get('number', 0);
	}

	@computed
	public get reference(): string {
		return this.get('reference', '');
	}

	@computed
	public get companyId(): id {
		return this.get('company.id', '');
	}

	@computed
	public get enterprisePartitionId(): id {
		return this.get('enterprisePartition.id', '');
	}

	@computed
	public get enterprisePartitionUrn(): string {
		return this.get('enterprisePartition.@urn', '');
	}
}
