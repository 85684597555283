import {
	DeleteOutlined,
	EllipsisOutlined,
	ExclamationCircleOutlined,
	PlusOutlined,
}                                   from '@ant-design/icons';
import { ApiCollection }            from 'Collections/ApiCollection';
import OperationCategoryModel       from 'Models/intervention/OperationCategoryModel';
import Card                         from 'antd/lib/card';
import Dropdown                     from 'antd/lib/dropdown';
import Empty                        from 'antd/lib/empty';
import List                         from 'antd/lib/list';
import Modal                        from 'antd/lib/modal';
import notification                 from 'antd/lib/notification';
import Tag                          from 'antd/lib/tag';
import { observer }                 from 'mobx-react';
import React, { useState }          from 'react';
import { appStore, functionsStore } from 'stores/index';
import TaskZoneContactModalForm     from '../../../forms/TaskZoneContactModalForm';
import ContactModel                 from '../../../modelx/models/private/directory/ContactModel';
import ZoneContactModel             from '../../../modelx/models/private/intervention/ZoneContactModel';
import InvoicingContactModel        from '../../../modelx/models/private/invoice/InvoicingContactModel';
import SalesContactModel            from '../../../modelx/models/private/sales/SalesContactModel';
import notificationApiError         from '../../../utils/notificationApiError';

function removeServiceContact(serviceContact: InvoicingContactModel|SalesContactModel) {
	Modal.confirm({
		cancelText: 'Annuler',
		icon: <ExclamationCircleOutlined />,
		okText: 'Supprimer',
		onOk: async () => {
			try {
				await serviceContact.destroy();

				functionsStore.fetchTaskZoneContact();

				notification.success({
					message: 'Point de contact supprimé'
				});
			} catch (err) {
				notificationApiError(err);
			}
		},
		title: 'Voulez-vous vraiment supprimer cet élément ?',
		width: 450,
	});
}

// function removeServiceContacts(serviceContact: InvoicingContactModel|SalesContactModel) {
// 	Modal.confirm({
// 		title: 'Voulez-vous vraiment supprimer ce point de contact ?',
// 		icon: <ExclamationCircleOutlined/>,
// 		cancelText: 'Annuler',
// 		okText: 'Supprimer',
// 		onOk: async () => {
// 			try {
// 				await serviceContact.destroy();
//
// 				notification.success({
// 					message: 'Point de contact supprimé',
// 					description: 'Le point de contact a bien été supprimé',
// 				});
// 			} catch (err) {
// 				notificationApiError(err);
// 			}
// 		}
// 	});
// }

const TaskZoneServiceContactsBox = ({
	contactCollection,
	label,
	operationCategoryCollection,
	serviceContacts,
	type,
	zoneContactCollection,
}: {
	contactCollection: ApiCollection<ContactModel>;
	label: string;
	operationCategoryCollection: ApiCollection<OperationCategoryModel>;
	serviceContacts: (ZoneContactModel)[];
	type: string;
	zoneContactCollection: ApiCollection<ZoneContactModel>;
}) => {
	const [modalVisible, setModalVisible] = useState(false);

	// const services = appStore.serviceCollection.filter(s => dataPoint.serviceIds.includes(s.id));
	// const services = [];

	return (
		<Card
			extra={(
				<Dropdown
					menu={{
						items: [
							{
								key: 'add',
								label: <><PlusOutlined /> Ajouter</>,
								onClick: () => setModalVisible(true),
							}
						]
					}}
					trigger={['click']}
				>
					<EllipsisOutlined
						style={{ fontSize: 20, verticalAlign: 'top' }}
					/>
				</Dropdown>
			)}
			style={{ height: 'calc(100% - 20px)' }}
			// loading={!functionsStore.isLoaded}
			title={label}
		>
			{/*{!!services.length && <div className="services">*/}
			{/*	Service&nbsp;*/}
			{/*	/!*{services.map((s, i) => (*!/*/}
			{/*	/!*    <span key={s.id}>*!/*/}
			{/*	/!*        {s.label.toLowerCase()}{services.length !== i + 1 ? ', ' : ''}*!/*/}
			{/*	/!*    </span>*!/*/}
			{/*	/!*))}*!/*/}
			{/*</div>}*/}

			{serviceContacts.length ? (
				<List>
					{serviceContacts.map(serviceContact => {
						const contact = contactCollection.find(contact => contact.id === serviceContact.contactId);

						const operationCategory = operationCategoryCollection.find(operationCategory => operationCategory.id === serviceContact.operationCategoryId);

						return (
							<List.Item extra={(
								<Dropdown
									menu={{
										items: [
											{
												key: 'delete',
												label: <><DeleteOutlined /> Supprimer</>,
												onClick: () => {
													removeServiceContact(serviceContact);
												}, 
											}
										]
									}}
									trigger={['click']}
								>
									<EllipsisOutlined
										style={{ fontSize: 20, verticalAlign: 'top' }}
									/>
								</Dropdown>
							)} key={serviceContact?.id}>
								<List.Item.Meta
									description={
										<>
											<div>{contact?.email}</div>
											{operationCategory && <Tag>{operationCategory.label}</Tag>}
										</>

									}
									title={contact?.fullName}
								/>
							</List.Item>
						);
					})}
				</List>
			) : (
				<Empty
					description="Aucun contact"
					image={Empty.PRESENTED_IMAGE_SIMPLE}
				/>
			)}

			<TaskZoneContactModalForm
				formProps={{
					initialValues: {
						type,
					}
				}}
				onCancel={() => setModalVisible(false)}
				onSubmit={values => {
					const zoneContact = new ZoneContactModel({
						contactType: values.type,
						contactUrn: values.contact,
						operationCategory: values.operationCategory,
						taskZone: appStore.selectedTaskZone?.iri,
					});

					zoneContact
						.save()
						.then(() => {
							setModalVisible(false);

							functionsStore.fetchTaskZoneContact();

							notification.success({
								description: 'Le point de contact a bien été ajouté',
								message: 'Point de contact ajouté',
							});
						})
						.catch(notificationApiError);
				}}
				open={modalVisible}
				taskZoneContactTypeCollection={functionsStore.taskZoneContactTypeCollection}
				type={type}
				width={650}
				zoneContactCollection={zoneContactCollection}
			/>
		</Card>
	);
};

export default observer(TaskZoneServiceContactsBox);
