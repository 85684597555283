import { LockOutlined, UserOutlined } from '@ant-design/icons';
import Button                         from 'antd/lib/button';
import Form                           from 'antd/lib/form';
import Input                          from 'antd/lib/input';
import LoginCheckModel                from 'modelx/models/public/directory/LoginCheckModel';
import { useLocation, useNavigate }   from 'react-router-dom';
import React, { useState }            from 'react';
import { authenticationStore }        from '../stores';
import notificationApiError           from '../utils/notificationApiError';

const LoginForm = () => {
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();

	const onFinish = async values => {
		setLoading(true);

		const { from }: {
			from: {
				pathname: string;
				search?: string;
			};
		} = { from: { pathname: location.pathname || '/', search: location.search } };

		try {
			const loginCheck = new LoginCheckModel();

			await authenticationStore.login(
				loginCheck,
				values.email,
				values.password,
				// 'julie.blanc_535@wcentric.com',
				// 'sophie.poulain_181@wcentric.com',
				// 'Test1234',
			);

			await authenticationStore.onLoginSuccess(loginCheck);

			authenticationStore.setIsAuthenticated(true);

			navigate(from, { replace: true });
		} catch (err) {
			setLoading(false);

			notificationApiError(err, {
				message: 'Erreur lors de la connexion',
			});
		}
	};

	return (
		<Form
			name="login_form"
			onFinish={onFinish}
		>
			<Form.Item
				name="email"
				rules={[
					{
						message: 'Veuillez renseigner votre adresse email!',
						required: true,
					},
				]}
			>
				<Input
					placeholder="Adresse email"
					prefix={<UserOutlined />}
				/>
			</Form.Item>

			<Form.Item
				name="password"
				rules={[
					{
						message: 'Veuillez renseigner votre mot de passe!',
						required: true,
					},
				]}
			>
				<Input.Password
					placeholder="Mot de passe"
					prefix={<LockOutlined />}
				/>
			</Form.Item>

			<Button
				block={true}
				className="login-form-button"
				disabled={loading}
				htmlType="submit"
				loading={loading}
				type="primary"
			>
				Connexion
			</Button>
		</Form>
	);
};

export default LoginForm;
