import { useLocation, useNavigate } from 'react-router-dom';

export default () => {
	const navigate = useNavigate();
	const location = useLocation();
	

	return (params: Record<string, string>) => {
		const urlParams = new URLSearchParams(location.search);
		for (const param in params) {
			if (typeof params[param] !== 'undefined') {
				urlParams.set(param, params[param]);
			}
			else {
				urlParams.set(param, '');
			}
			
		}
		location.search = urlParams.toString();
		navigate(location, { replace: true });
	};
};
