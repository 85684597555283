import { ApiCollection }           from 'Collections/ApiCollection';
import Tooltip                     from 'antd/lib/tooltip';
import { getQuotationStatusColor } from 'constants/QuotationStatuses';
import { observer }                from 'mobx-react';
import React                       from 'react';
import QuotationModel              from '../../modelx/models/private/sales/QuotationModel';
import QuotationStatusModel        from '../../modelx/models/private/sales/QuotationStatusModel';

interface IQuotationStatusCircleProps {
	quotation: QuotationModel;
	quotationStatusCollection: ApiCollection<QuotationStatusModel>;
}

const QuotationStatusCircle = ({
	quotation,
	quotationStatusCollection,
}: IQuotationStatusCircleProps) => {
	const quotationStatus = quotationStatusCollection.find(quotationStatus => quotationStatus.id === quotation.quotationStatusId);

	if (!quotationStatus) {
		return null;
	}

	return (
		<Tooltip
			placement="right"
			title={quotationStatus.label}
		>
			<div
				style={{
					backgroundColor: getQuotationStatusColor(quotationStatus),
					borderRadius: 10,
					height: 20,
					width: 20,
				}}
			/>
		</Tooltip>
	);
};

export default observer(QuotationStatusCircle);
